<template>
  <div id="contact">
    <!-- <SectionNamer :currentSection=hook /> -->
    <p class="contact-text">{{contactDescription}}</p>
    <router-link tag="a" to="/contact" class="quadram-button-container">
      <button type="button" class="quadram-button"><span>{{buttonContentText}}</span></button>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "contact",
  data() {
    return {
      hook: 6,
      title: 'HOME_CONTACT_TITLE',
      text: 'HOME_CONTACT_TEXT',
      buttonText: 'HOME_CONTACT_BUTTON',
    }
  },
  computed: {
    buttonContentText() {
      return constants.getCopy(this.buttonText);
    },
    contactDescription() {
      return constants.getCopy(this.text);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
