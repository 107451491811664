<template>
    <div>
      <title-component/>
      <works :works-page="true" />
      <clients/>
      <contact/>
      <quadram-footer/>
    </div>
</template>
<script>
  import TitleComponent from "../components/Title";
  import Works from "./Home/Works"
  import Contact from "./Home/Contact"
  import Clients from "./Home/Clients";
  import QuadramFooter from "./Home/Footer";

  export default {
    components: {TitleComponent, Clients, Works, Contact, QuadramFooter},
    name: "mainWorks",
    metaInfo() {
      return {
        link: [
          { rel: 'canonical', href: 'https://quadram.mobi/portfolio' }
        ]
      }
    },
    data() {
      return {}
    }
  }
</script>
