<template>
  <div id="services-offers">
    <SectionNamer :custom-text="sectionName" />
    <div class="services-offer-content">
      <h2>{{ translateCopy("SERVICES_OFFER_TITLE") }}</h2>
      <p>{{ translateCopy("SERVICES_OFFER_DESCRIPTION_1") }}</p>
      <p>{{ translateCopy("SERVICES_OFFER_DESCRIPTION_2") }}</p>
      <p>{{ translateCopy("SERVICES_OFFER_DESCRIPTION_3") }}</p>
    </div>
    <div class="services-image">
      <img :src="require(`@/assets/images/services/devices.webp`)" alt="Multiples dispositivos - Quadram"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "@/constants";
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: { SectionNamer },
  name: "Offers",
  data() {
    return {};
  },
  computed: {
    sectionName() {
      return constants.getCopy("SERVICES_OFFERS_SECTION_TITLE");
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
    translateCopy(key) {
      return constants.getCopy(key);
    },
  },
};
</script>
