<template>
	<div id="home">
		<div class="wrapper">
			<!-- <SectionNamer :currentSection=hook /> -->
      <div class="home-title-container">
        <span class="home-title">
          <h1 v-html="CONSTANTS.getCopy(title)" />
        </span>  
      </div>
      <span class="home-subtitle">
        <h2>{{ CONSTANTS.getCopy(subtitle) }}</h2>
      </span>
      <span class="home-text">
        <p>{{ CONSTANTS.getCopy(homeText) }}</p>
      </span>
       <span class="home-text">
        <p>Quadram - <a href="/kit-digital">Kit Digital</a></p>
      </span>
		</div>
		<ScrollDown destiny="introduction"/>
	</div>
</template>

<script>
  import SectionNamer from "../../components/navigation/SectionNamer";
  import ScrollDown from "../../components/navigation/ScrollDown";

  export default {
    components: {SectionNamer, ScrollDown},
    name: "home",
    data() {
      return {
        hook: 1,
        title: 'HOME_HOME_TITLE',
        subtitle: 'HOME_HOME_SUBTITLE',
        homeText: 'HOME_HOME_TEXT',
        buttonText: 'HOME_HOME_BUTTON',
      }
    },
    methods: {
      goToPage(page) {
        this.$router.push(page)
      },
    }
  };
</script>
