<template>
  <div id="services">
    <div class="main-services">
      <!-- <SectionNamer :currentSection=hook /> -->
      <h2 class="services-title">{{CONSTANTS.getCopy('HOME_SERVICES_MAIN_TITLE')}}</h2>
      <div class="main-description-container">
        <div class="description-container">
          <h3 class="title">{{ CONSTANTS.getCopy('HOME_SERVICES_TITLE_1') }}</h3>
          <div class="content">
            <span v-html="CONSTANTS.getCopy('HOME_SERVICES_DESCRIPTION_1')"></span>
          </div>
        </div>
        <div class="description-container">
          <h3 class="title">{{ CONSTANTS.getCopy('HOME_SERVICES_TITLE_2') }}</h3>
          <div class="content">
            <span v-html="CONSTANTS.getCopy('HOME_SERVICES_DESCRIPTION_2')"></span>
          </div>
        </div>
        <div class="description-container">
          <h3 class="title">{{ CONSTANTS.getCopy('HOME_SERVICES_TITLE_3') }}</h3>
          <div class="content">
            <span v-html="CONSTANTS.getCopy('HOME_SERVICES_DESCRIPTION_3')"></span>      
          </div>
        </div>
      </div>
      <div class="description-images">
        <img src="@/assets/images/serviceFigures/figure1.webp" alt="Quadram - Desarrollo de apps"/>
        <img src="@/assets/images/serviceFigures/figure2.webp" alt="Quadram - Diseño Ux y Ui"/>
        <img src="@/assets/images/serviceFigures/figure3.webp" alt="Quadram - Consultoría digital"/>
      </div>
    </div>
    <div class="black-box">
      <!-- <SectionNamer :currentSection=hook :isWhite="true"/> -->
      <h3>{{ CONSTANTS.getCopy('HOME_SERVICES_TEAM_AUGMENTATION_TITLE') }}</h3>
      <p class="content">{{ CONSTANTS.getCopy('HOME_SERVICES_TEAM_AUGMENTATION_DESCRIPTION') }}</p>
      <router-link tag="a" to="/equipo-expertos-team-augmentation" class="quadram-button-container">
        <button type="button" class="quadram-button"><span>{{ CONSTANTS.getCopy(teamAugmentationButtonText) }}</span></button>
      </router-link>
    </div>
    <div class="consultancy">
      <!-- <SectionNamer :currentSection=hook /> -->
      <h3 class="title">{{ CONSTANTS.getCopy('HOME_SERVICES_CONSULTANCY_TITLE') }}</h3>
      <p class="content" v-html="CONSTANTS.getCopy('HOME_SERVICES_CONSULTANCY_1_TEXT')"></p>
      <p class="content" v-html="CONSTANTS.getCopy('HOME_SERVICES_CONSULTANCY_2_TEXT')"></p>
    </div>
    <div class="black-box">
      <!-- <SectionNamer :currentSection=hook :isWhite="true"/> -->
      <h3>{{ CONSTANTS.getCopy('HOME_SERVICES_MAINTENANCE_TITLE') }}</h3>
      <p class="content">{{ CONSTANTS.getCopy('HOME_SERVICES_MAINTENANCE_1_TEXT') }}</p>
      <p class="content">{{ CONSTANTS.getCopy('HOME_SERVICES_MAINTENANCE_2_TEXT') }}</p>
      <router-link tag="a" to="/services" class="quadram-button-container">
        <button type="button" class="quadram-button"><span>{{ CONSTANTS.getCopy(servicesButtonText) }}</span></button>
      </router-link>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "services",
  data() {
    return {
      hook: 2,
      slides: 4,
      title: 'HOME_SERVICES',
      teamAugmentationButtonText: 'HOME_SERVICES_TEAM_AUGMENTATION_BUTTON',
      servicesButtonText: 'HOME_SERVICES_BUTTON',
      period: 2500,
      loopNum: 0,
      txt: '',
      isDeleting: false,
    }
  },
  mounted() {
    console.log(this.$route.path)
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
