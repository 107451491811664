<template>
  <div id="introduction">
    <!-- <SectionNamer :currentSection=hook :isWhite="true"/> -->
    <div class="introduction-description">
      <h2>
        {{introductionDescription}}
      </h2>
      <p>
        {{introductionDescription1}}
      </p>
      <img :src="require(`@/assets/images/home/introduction.webp`)" class="mobile-image" alt="Diseño y desarrollo de aplicaciones móviles | Quadram"/>
      <p>
        {{introductionDescription2}}
      </p>
      <p>
        {{introductionDescription3}}
      </p>
      <br>
      <!-- <router-link tag="a" to="/services" class="quadram-button-container">
        <button type="button" class="quadram-button"><span>{{buttonContentText}}</span></button>
      </router-link> -->
    </div>
    <div class="introduction-image">
      <img :src="require(`@/assets/images/home/introduction.webp`)" />
    </div>
    <!-- <p class="typewrite">
      <span class="wrap">{{txt}}</span>
    </p> -->
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "services",
  data() {
    return {
      hook: 8,
      slides: 4,
      title: 'HOME_INTRODUCTION',
      buttonText: 'HOME_SERVICES_BUTTON',
      period: 2500,
      loopNum: 0,
      txt: '',
      isDeleting: false,
    }
  },
  mounted() {
    this.init();
    console.log(this.$route.path)
  },
  computed: {
    buttonContentText() {
      return constants.getCopy(this.buttonText);
    },
    introductionDescription() {
      return constants.getCopy(`HOME_INTRODUCTION_DESCRIPTION`);
    },
    introductionDescription1() {
      return constants.getCopy('HOME_INTRODUCTION_DESCRIPTION_1');
    },
    introductionDescription2() {
      return constants.getCopy('HOME_INTRODUCTION_DESCRIPTION_2');
    },
    introductionDescription3() {
      return constants.getCopy('HOME_INTRODUCTION_DESCRIPTION_3');
    },
    slidesText() {
      let texts = [];
      for (let i = 1; i <= this.slides; i++) {
        texts.push(this.serviceName(i))
      }
      return texts;
    }
  },
  methods: {
    init() {
      this.tick();
    },
    tick() {
      const fullTxt = this.slidesText[this.loopNum];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      let delta = 350 - Math.random() * 100;

      if (this.isDeleting) { delta /= 2; }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum = this.loopNum + 1 < this.slides ? this.loopNum + 1 : 0;
        delta = 500;
      }

      setTimeout(() => this.tick(), delta);
    },
    goToPage(page) {
      this.$router.push(page)
    },
    serviceName(index) {
      return constants.getCopy(`HOME_INTRODUCTION_${index}_NAME`);
    },
  }
};
</script>
