<template>
  <div id="title-component">
    <h1 class="service-name active-name" v-html="serviceName" />
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "@/constants";

export default {
  components: {},
  name: "TitleComponent",
  data() {
    return {

    }
  },
  computed: {
    serviceName() {
        return constants.getCopy(this.$router.currentRoute.meta.title);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
