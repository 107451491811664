<template>
  <div>
    <div id="cookies">
      <div class="wrapper">
        <div
          class="cookies-title"
          v-html="CONSTANTS.getCopy(cookiesTitle)"
        ></div>
        <div class="cookies-text" v-html="CONSTANTS.getCopy(cookiesText)"></div>
      </div>
    </div>
    <quadram-footer />
  </div>
</template>
<script>
import QuadramFooter from "./Home/Footer";

export default {
  components: { QuadramFooter },
  name: "Cookies",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: "https://quadram.mobi/cookies" }],
    };
  },

  data() {
    return {
      cookiesTitle: "COOKIES_TITLE",
      cookiesText: "COOKIES_CONTENT",
    };
  },
};
</script>