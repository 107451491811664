<template>
    <div id="nav"
        :class="navClass"
    >
        <a class="main-logo" href="/">
            <img :src="require(`@/assets/images/logos/${fileNameLogo}`)" class="quadram-logo" href="#" alt="Logo Quadram"/>
            <img :src="require(`@/assets/images/logos/${fileNameLogoMobile}`)" class="quadram-logo-mobile" href="#" alt="Logo Quadram"/>
        </a>
        <div class="nav-right">
            <router-link tag="a" to="/contact" class="quadram-button-container contact">
                <button type="button" class="quadram-button"><span>{{buttonContentText}}</span></button>
            </router-link>
            <desktop-menu/>
            <div class="language-selector">
                <a @click="changeLanguage('es')" class="is-uppercase" :class="language === 'es' && 'language-active'">ES</a>
                <p>|</p>
                <a @click="changeLanguage('en')" class="is-uppercase" :class="language === 'en' && 'language-active'">EN</a>
            </div>
            <span class="icon quadram-hamburger" @click="openMenu">
                <img :src="require(`@/assets/images/menu/${fileNameMenuImage}`)" alt="Menú"/>
            </span>
        </div>

    </div>
</template>
<script>
  // @ is an alias to /src
  import constants from '../../constants'
  import DesktopMenu from './DesktopMenu'

  export default {
    components: {DesktopMenu},
    name: "NavBar",
    data() {
      return {
        isTouchDisplay: false,
        showNavBarTransparent: true,
        languages: [],
        language: '',
        buttonText: 'NAV_BUTTON',
        scrollEvent: 0,
        scrollingUp: true,
        currentRoute: null,
        fileNameLogo: 'quadram_light.webp',
        fileNameLogoMobile: 'quadram_light_mobile.webp',
        fileNameMenuImage: 'menu.png',
      }
    },
    created() {
      if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)){
              window.addEventListener('scroll', this.handleScroll);
              this.isTouchDisplay = true;
              return;
      }
      window.addEventListener('wheel', this.handleScroll);
    },

    beforeDestroy() {
      // I switched the example from `destroyed` to `beforeDestroy`
      // to exercise your mind a bit. This lifecycle method works too.
      window.removeEventListener('wheel', this.handleScroll);
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.getLanguages();
      this.showKitDigital = this.currentRouter.currentRoute.fullPath === "/";
    },
    watch: {
      routePath: function () {       
        this.scrollingUp = false;
        this.scrollingUp = true;
        this.showNavBarTransparent = true;
      }
    },
    computed: {
      navClass() {
        this.scrollingUp;
        let classString = 'nav-opened';
        this.currentRouter.currentRoute.meta.navColor 
          ? ((classString += ' black-nav') && (this.fileNameLogo = 'quadram_dark.webp') && (this.fileNameLogoMobile = 'quadram_dark_mobile.webp') 
            && (this.fileNameMenuImage = 'menu_white.png'))
          : ((this.fileNameLogo = 'quadram_light.webp') && (this.fileNameLogoMobile = 'quadram_light_mobile.webp') && (this.fileNameMenuImage = 'menu.png'))
        this.currentRouter.currentRoute.meta.navTransparent && this.showNavBarTransparent && (classString += ' transparent-nav');
        return classString;
      },
      currentRouter() {
          return this.$router;
      },
      routePath() {
        return constants.getCopy(this.$route.path);
      },
      buttonContentText() {
        return constants.getCopy(this.buttonText);
      },
    },
    methods: {
      handleScroll(event) {
        if (this.isTouchDisplay){
          this.showNavBarTransparent = window.scrollY < 10;
          return;
        }
        this.scrollingUp = (event.wheelDeltaY >= 0);
        this.showNavBarTransparent = window.scrollY < 200;
      },
      getLanguages() {
        this.languages = constants.languages;
        this.language = localStorage.getObject('language') || this.languages[0] || 'es';
        localStorage.setObject('language', this.language);
      },
      changeLanguage(chosen) {
        let current = this.language;
        if (chosen === current) {
          return;
        }
        this.languages.forEach(function(item) {
          if (item !== current) {
            chosen = item;
          }
        });
        this.language = chosen;
        localStorage.setObject('language', this.language);
        constants.setCopies(this.language);
        this.$router.go();
      },
      openMenu() {
        this.$emit('menuClicked');
      },
      goToPage(page) {
        this.$router.push(page)
      },
    }
  };
</script>

