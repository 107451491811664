<template>
  <div id="services-cycle">
    <SectionNamer :custom-text="sectionName"/>
    <div class="cycle-container">
      <img src="../../assets/images/our_day_a_day.svg" alt="Nuestro día a día"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "Cycle",
  props: {
      offersCount: Number,
  },
  data() {
    return {

    }
  },
  computed: {
      sectionName() {
          return this.translateCopy('SERVICES_DAYWORK_SECTION_TITLE')
      }
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
    translateCopy(key) {
      return constants.getCopy(key);
    }
  }
};
</script>
