<template>
  <div id="works" :class="`${!worksPage && !worksDetailPage && 'works-bg-black'}`">
    <div class="wrapper" :class="`${worksPage ? 'wrapper-detailed-works' : worksDetailPage && 'wrapper-work-details'}`">
      <!-- <SectionNamer :currentSection=hook :workPage="worksPage"/> -->
      <div class="works-title-container">
          <p v-if="!worksDetailPage && !worksPage" class="works-title">{{titleText}}</p>
          <h2 v-if="worksPage" class="works-title">{{titleWorksPageText}}</h2>
          <p v-if="worksDetailPage" class="works-title">{{titleDetailPageText}}</p>
      </div>
      <div v-if="work !== worksId['dhis2'] " class="works-image image-right image-1" @click="goToWorkDetail(worksId['dhis2'])">
          <p class="image-text">{{worksTextContent(worksId['dhis2'])}}</p>
      </div>
      <div v-if="!worksDetailPage || worksPage || work === worksId['trive']" class="works-image image-left image-3" :class="worksDetailPage && 'no-margin'" 
        @click="goToWorkDetail(worksId['direct-seguros'])">
          <p class="image-text">{{worksTextContent(worksId['direct-seguros'])}}</p>
      </div>
      <div  v-if="!worksDetailPage || worksPage || work === worksId['direct-seguros']" class="works-image image-4" :class="`${work === worksId['direct-seguros'] ? 'image-left' : 'image-right'}`" 
        @click="goToWorkDetail(worksId['trustyou'])">
          <p class="image-text">{{worksTextContent(worksId['trustyou'])}}</p>
      </div>
      <div v-if="!worksDetailPage || work === worksId['trustyou'] || work === worksId['dhis2']" class="works-image image-2" 
        :class="`${work === worksId['dhis2'] ? 'image-right' : 'image-left'}`" @click="goToWorkDetail(worksId['safy'])">
        <p class="image-text">{{worksTextContent(worksId['safy'])}}</p>
      </div>
      <div v-if="!worksDetailPage || work === worksId['dhis2'] || work === worksId['safy']" 
        class="works-image image-5" :class="[`${work === worksId['dhis2'] || work === worksId['safy'] ? 'image-left' : 'image-right'}`, !worksDetailPage && !worksPage && 'image-absolute']" 
        @click="goToWorkDetail(worksId['trive'])">
        <p class="image-text">{{worksTextContent(worksId['trive'])}}</p>
      </div>
    </div>
    <!-- <div v-if="!worksPage && !worksDetailPage" class="button-container">
      <router-link tag="a" to="/works" class="quadram-button-container">
        <button type="button" class="quadram-button"><span>{{buttonText}}</span></button>
      </router-link>
    </div> -->
    <!-- <div v-if="!worksPage && !worksDetailPage" class="black-box">
      <SectionNamer :currentSection=hook :isWhite="true"/>
      <p class="image-text">{{worksTextContent('DESCRIPTION')}}</p>
      <router-link tag="a" to="/works" class="quadram-button-container">
        <button type="button" class="quadram-button"><span>{{buttonText}}</span></button>
      </router-link>
    </div>
    <div v-if="!worksPage && !worksDetailPage" class="white-box">
      <SectionNamer :currentSection=hook />
      <p class="works-text" v-html="worksTextContent('DESCRIPTION_1')" />
      <p class="works-text" v-html="worksTextContent('DESCRIPTION_2')" />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "works",
    props: {
        worksPage: Boolean,
        worksDetailPage: Boolean,
        work: Number
    },
  data() {
    return {
      hook: this.worksPage ? 7 : 3,
      title: 'HOME_WORKS_TITLE',
      titleWorksPage: 'WORKS_PAGE_TITLE',
      titleDetailPage: 'HOME_WORKS_TITLE_1',
      button: 'HOME_WORKS_BUTTON',
      worksId: {
        'dhis2': 1,
        'safy': 2,
        'direct-seguros': 3,
        'trustyou': 4,
        'trive': 5
      }
    }
  },
  computed: {
    titleText() {
      return constants.getCopy(this.title);
    },
    titleWorksPageText(){
      return constants.getCopy(this.titleWorksPage);
    },
    titleDetailPageText(){
      return constants.getCopy(this.titleDetailPage);
    },
    buttonText() {
      return constants.getCopy(this.button);
    },
  },
  methods: {
    worksTextContent(index) {
      return constants.getCopy(`HOME_WORKS_${index}_TEXT`);
    },
    goToWorkDetail(work) {
      localStorage.setObject('qWork', work);
      //Call the Main component updateContent funcion
      this.$parent.$parent.updateContent();
      let path = Object.keys(this.worksId)[work-1];
      this.goToPage(`/portfolio/${path}`);
    },
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
