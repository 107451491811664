<template>
  <div id="join-us">
    <SectionNamer :custom-text="sectionName"/>
    <p class="join-us-title" v-html="translateCopy(joinUsTitleCopy)" />
    <div class="careers-container">
      <div v-for="career in careers" class="career" @click="openCareer(career)">
        <div class="career-title">
          <p>
            {{translateCopy(`${careerPrefix}_${career}_TITLE`)}}
          </p>
          <div class="career-title-icon">
            <span class="icon">
              <i class="fas fa-chevron-down"/>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="career-details">
      <div v-if="careerOpened" class="opened-career-details">
        <div class="career-closer" @click="openCareer(0)">
          <span class="icon">
            <i class="fas fa-minus"/>
          </span>
        </div>
        <div class="opened-career-content">
          <div class="career-description-title" v-html="translateCopy(`${careerPrefix}_${careerOpened}_TITLE`)"/>
          <div class="career-description" style="text-indent: 0;" v-html="translateCopy(careerCommonDescription)"/>
          <div class="career-description bold" v-html="translateCopy(`${whatWeOfferPrefix}_TITLE`)"/>
          <div class="career-description" v-html="translateCopy(`${whatWeOfferPrefix}_DESCRIPTION`)"/>
          <div class="career-description bold" v-html="translateCopy(`${lookingForPrefix}_TITLE`)"/>
          <div class="career-description" v-html="translateCopy(`${lookingForPrefix}_DESCRIPTION_${careerOpened}`)"/>
          <div class="career-description bold" v-html="translateCopy(`${extraPrefix}_TITLE`)"/>
          <div class="career-description" v-html="translateCopy(`${extraPrefix}_DESCRIPTION_${careerOpened}`)"/>
        </div>
        <p class="join-us-banner-title">{{translateCopy(bannerTitle)}}</p>
        <a :href="`mailto:${translateCopy(contactMail)}`" class="join-us-banner-mail">{{translateCopy(contactMail)}}</a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "JoinUs",
  data() {
    return {
      joinUsTitleCopy: 'TEAM_JOIN_US_TITLE',
      careerPrefix: 'TEAM_JOIN_US_CAREER',
      careers: [1, 2, 3, 4],
      careerOpened: 0,
      careerCommonDescription: 'TEAM_JOIN_US_CAREER_COMMON_DESCRIPTION',
      whatWeOfferPrefix: 'TEAM_JOIN_US_WHAT_WE_OFFER',
      lookingForPrefix: 'TEAM_JOIN_US_LOOKING_FOR',
      extraPrefix: 'TEAM_JOIN_US_EXTRA',
      bannerTitle: 'TEAM_JOIN_US_BANNER_TITLE',
      contactMail: 'QUADRAM_CONTACT_MAIL',
      destiny: null
    }
  },
  computed: {
      sectionName() {
          return this.translateCopy('TEAM_JOIN_US_SECTION_NAME')
      }
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
    translateCopy(key) {
      return constants.getCopy(key);
    },
    openCareer(career) {
      this.careerOpened = this.careerOpened === career ? 0 : career;
      if (this.careerOpened){
        this.goToNextHook('#career-details');
        return;
      }
      this.goToNextHook('#join-us');     
    },
    goToNextHook(destiny) {
      document.querySelector(destiny).scrollIntoView({ behavior: 'smooth', block: 'start'});
    },
  }
};
</script>
