import allCopies from './copies';

let languages = [
  'es',
  'en'
];

let hooks = [
    {
        id: 0,
        name: '',
        hook: '',
    },
    {
        id: 1,
        name: 'HOME_HOME',
        hook: 'home',
    },
    {
        id: 2,
        name: 'HOME_SERVICES',
        hook: 'services',
    },
    {
        id: 3,
        name: 'HOME_WORKS',
        hook: 'works',
    },
    {
        id: 4,
        name: 'HOME_CLIENTS',
        hook: 'clients',
    },
    {
        id: 5,
        name: 'HOME_VENTURES',
        hook: 'careers',
    },
    {
        id: 6,
        name: 'HOME_CONTACT',
        hook: 'contact',
    },
    {
        id: 7,
        name: 'WORKS_PAGE',
        hook: 'works_page'
    },
    {
        id: 8,
        name: 'HOME_INTRODUCTION',
        hook: 'introduction'
    }
];

let copies = {};

function setCopies (language) {
    Object.keys(allCopies.copies).forEach(function(key) {
        copies[key] = allCopies.copies[key][language];
    });
}

function getCopy (key) {
    return copies[key] || key;
}

export default {
    languages,
    hooks,
    copies,
    setCopies,
    getCopy
};
