<template>
  <div id="faq"
       :class="isWhite && 'white-list'">
    <SectionNamer :custom-text="customSectionName" :is-white="!isWhite"/>
    <div class="listed-object" v-for="i in listCount">
      <p class="listed-object-title" v-html="translateCopy(`${listPrefix}_${i}_TITLE`)"/>
      <p v-if="openDescriptionNumber === i" class="listed-object-description" v-html="translateCopy(`${listPrefix}_${i}_DESCRIPTION`)"/>
        <div class="details-button" @click="openDescription(i)">
            <span class="icon">
              <i class="fas" :class="openDescriptionNumber === i ? 'fa-chevron-up' : 'fa-chevron-down'"/>
            </span>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';
import SectionNamer from "./navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "Faq",
  props: {
      listCount: Number,
      listPrefix: String,
      isWhite: Boolean,
      sectionName: String,
  },
  data() {
    return {
      openDescriptionNumber: null,
    }
  },
  computed: {
    customSectionName() {
      return this.translateCopy(this.sectionName);
    }
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
    translateCopy(key) {
      return constants.getCopy(key);
    },
    openDescription(index) {
      this.openDescriptionNumber = this.openDescriptionNumber === index ? null : index;
    }
  }
};
</script>
