<template>
    <div id="section-counter">
        <div class="number-page-list">
            <div v-for="i in (sections.length - 1)"
                 :key="i"
                 class="number-page"
                 :class="i === 1 && 'is-first'"
            >
                <div class="marker" :class="currentSection === i && 'is-active'"></div>
                <a @click="goToHook(sections[i])">0{{sections[i].id}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    import constants from '../../constants';

    export default {
        name: "SectionCounter",
        props: {
            currentSection: Number,
        },

        data() {
            return {
                sections: [],
            }
        },

        created() {
            this.sections = constants.hooks;
        },

        methods: {
            goToHook(hook) {
                if (!hook) {
                    hook = this.sections[1];
                }
                console.log('Section Counter call');
                this.$emit('clicked', hook);
            },
        }
    };
</script>
