let copies = {
  "LANGUAGE_HTML": {
    "es": "es-ES",
    "en": "en-GB"
  },
  "META_LANGUAGE": {
    "es": "Spanish",
    "en": "English"
  },
  "META_DESCRIPTION_HOME": {
    "es": "Quadram, empresa líder en desarrollo de aplicaciones móviles. Creamos Apps para iOS y Android, React Native y Webapps. Solicita tu presupuesto.",
    "en": "Quadram, empresa líder en desarrollo de aplicaciones móviles. Creamos Apps para iOS y Android, React Native y Webapps. Solicita tu presupuesto."
  },
  "META_DESCRIPTION_SERVICES": {
    "es": "Estamos especializados en servicios de Consultoría Digital, Diseño y Desarrollo de apps para las empresas.",
    "en": "Estamos especializados en servicios de Consultoría Digital, Diseño y Desarrollo de apps para las empresas."
  },
  "META_DESCRIPTION_PORTFOLIO": {
    "es": "Nuestra experiencia en productos digitales. Algunos de nuestros diseños y desarrollos de apps, soluciones innovadoras y atractivas.",
    "en": "Nuestra experiencia en productos digitales. Algunas de nuestros diseños y desarrollos de apps, soluciones innovadoras y atractivas."
  },
  "META_DESCRIPTION_CLIENTS": {
    "es": "Ayudamos a nuestros clientes en sus necesidades tecnológicas. Colaboraciones con grandes empresas en diseño y desarrollo de apps y productos digitales.",
    "en": "Ayudamos a nuestros clientes en sus necesidades tecnológicas. Colaboraciones con grandes empresas en diseño y desarrollo de apps y productos digitales."
  },
  "META_DESCRIPTION_TEAM": {
    "es": "Quadram es la suma de las personas que la forman. Diseñadores y desarrolladores de apps con gran talento en nuestro equipo.",
    "en": "Quadram es la suma de las personas que la forman. Diseñadores y desarrolladores de apps con gran talento en nuestro equipo."
  },
  "META_DESCRIPTION_CONTACT": {
    "es": "¿Tienes una idea de producto digital, app o webapp? Contacta con nosotros y te asesoramos sobre tecnología, diseño y desarrollo para tu proyecto.",
    "en": "¿Tienes una idea de producto digital, app o webapp? Contacta con nosotros y te asesoramos sobre tecnología, diseño y desarrollo para tu proyecto."
  },
  "META_DESCRIPTION_REACT_NATIVE": {
    "es": "Desarrollamos apps con React Native. Aplicaciones híbridas para App Store y Google Play.",
    "en": "Desarrollamos apps con React Native. Aplicaciones híbridas para App Store y Google Play."
  },
  "META_DESCRIPTION_USABILIDAD": {
    "es": "Diseñamos tu app pensando siempre en la experiencia de usuario y la usabilidad. Que tu producto sea intuitivo, innovador y atractivo es nuestro objetivo.",
    "en": "Diseñamos tu app pensando siempre en la experiencia de usuario y la usabilidad. Que tu producto sea intuitivo, innovador y atractivo es nuestro objetivo."
  },
  "META_DESCRIPTION_IOS": {
    "es": "Desarrollo de apps nativas para iOS - iPhone. Nos encargamos de gestionar tu aplicación en App Store.",
    "en": "Desarrollo de apps nativas para iOS - iPhone. Nos encargamos de gestionar tu aplicación en App Store."
  },
  "META_DESCRIPTION_ANDROID": {
    "es": "Desarrollo de apps nativas Android. Nos encargamos de gestionar tu aplicación en Google Play.",
    "en": "Desarrollo de apps nativas Android. Nos encargamos de gestionar tu aplicación en Google Play."
  },
  "META_DESCRIPTION_TEAM_AUGMENTATION": {
    "es": "Unimos talento y experiencia para crear los mejores equipos especializados en desarrollo iOS, Android, React Native y desarrollo de Backend para tu proyecto.",
    "en": "Unimos talento y experiencia para crear los mejores equipos especializados en desarrollo iOS, Android, React Native y desarrollo de Backend para tu proyecto."
  },
  "META_DESCRIPTION_WEB_RESPONSIVE": {
    "es": "Desarrollamos el Frontend de webs responsive, PWA (Progressive Web Apps) y backend a medida para que puedas gestionar el contenido de tus aplicaciones.",
    "en": "Desarrollamos el Frontend de webs responsive, PWA (Progressive Web Apps) y backend a medida para que puedas gestionar el contenido de tus aplicaciones."
  },
  "META_DESCRIPTION_SISTEMA_DISENO": {
    "es": "Construimos sistemas de diseño para las empresas. Cuenta con nuestros expertos en diseño digital para dar consistencia a los productos de tu compañía.",
    "en": "Construimos sistemas de diseño para las empresas. Cuenta con nuestros expertos en diseño digital para dar consistencia a los productos de tu compañía."
  },
  "META_DESCRIPTION_PRIVACY": {
    "es": "Aquí puedes conocer nuestra Política de Privacidad y Condiciones de acceso y utilización de la web de Quadram.",
    "en": "Aquí puedes conocer nuestra Política de Privacidad y Condiciones de acceso y utilización de la web de Quadram."
  },
  "META_DESCRIPTION_COOKIES": {
    "es": "Aquí puedes conocer información sobre cookies y nuestra Política de Cookies analíticas de la web de Quadram.",
    "en": "Aquí puedes conocer información sobre cookies y nuestra Política de Cookies analíticas de la web de Quadram."
  },
  "OG_TITLE_HOME": {
    "es": "Quadram, empresa líder en desarrollo de aplicaciones móviles. Creamos Apps para iOS y Android, React Native y Webapps. Solicita tu presupuesto.",
    "en": "Quadram, empresa líder en desarrollo de aplicaciones móviles. Creamos Apps para iOS y Android, React Native y Webapps. Solicita tu presupuesto."
  },
  "OG_TITLE_SERVICES": {
    "es": "Estamos especializados en servicios de Consultoría Digital, Diseño y Desarrollo de apps para las empresas.",
    "en": "Estamos especializados en servicios de Consultoría Digital, Diseño y Desarrollo de apps para las empresas."
  },
  "OG_TITLE_PORTFOLIO": {
    "es": "Nuestra experiencia en productos digitales. Algunos de nuestros diseños y desarrollos de apps, soluciones innovadoras y atractivas.",
    "en": "Nuestra experiencia en productos digitales. Algunas de nuestros diseños y desarrollos de apps, soluciones innovadoras y atractivas."
  },
  "OG_TITLE_CLIENTS": {
    "es": "Ayudamos a nuestros clientes en sus necesidades tecnológicas. Colaboraciones con grandes empresas en diseño y desarrollo de apps y productos digitales.",
    "en": "Ayudamos a nuestros clientes en sus necesidades tecnológicas. Colaboraciones con grandes empresas en diseño y desarrollo de apps y productos digitales."
  },
  "OG_TITLE_TEAM": {
    "es": "Quadram es la suma de las personas que la forman. Diseñadores y desarrolladores de apps con gran talento en nuestro equipo.",
    "en": "Quadram es la suma de las personas que la forman. Diseñadores y desarrolladores de apps con gran talento en nuestro equipo."
  },
  "OG_TITLE_CONTACT": {
    "es": "¿Tienes una idea de producto digital, app o webapp? Contacta con nosotros y te asesoramos sobre tecnología, diseño y desarrollo para tu proyecto.",
    "en": "¿Tienes una idea de producto digital, app o webapp? Contacta con nosotros y te asesoramos sobre tecnología, diseño y desarrollo para tu proyecto."
  },
  "OG_TITLE_REACT_NATIVE": {
    "es": "Desarrollamos apps con React Native. Aplicaciones híbridas para App Store y Google Play.",
    "en": "Desarrollamos apps con React Native. Aplicaciones híbridas para App Store y Google Play."
  },
  "OG_TITLE_USABILIDAD": {
    "es": "Diseñamos tu app pensando siempre en la experiencia de usuario y la usabilidad. Que tu producto sea intuitivo, innovador y atractivo es nuestro objetivo.",
    "en": "Diseñamos tu app pensando siempre en la experiencia de usuario y la usabilidad. Que tu producto sea intuitivo, innovador y atractivo es nuestro objetivo."
  },
  "OG_TITLE_IOS": {
    "es": "Desarrollo de apps nativas para iOS - iPhone. Nos encargamos de gestionar tu aplicación en App Store.",
    "en": "Desarrollo de apps nativas para iOS - iPhone. Nos encargamos de gestionar tu aplicación en App Store."
  },
  "OG_TITLE_ANDROID": {
    "es": "Desarrollo de apps nativas Android. Nos encargamos de gestionar tu aplicación en Google Play.",
    "en": "Desarrollo de apps nativas Android. Nos encargamos de gestionar tu aplicación en Google Play."
  },
  "OG_TITLE_TEAM_AUGMENTATION": {
    "es": "Unimos talento y experiencia para crear los mejores equipos especializados en desarrollo iOS, Android, React Native y desarrollo de Backend para tu proyecto.",
    "en": "Unimos talento y experiencia para crear los mejores equipos especializados en desarrollo iOS, Android, React Native y desarrollo de Backend para tu proyecto."
  },
  "OG_TITLE_WEB_RESPONSIVE": {
    "es": "Desarrollamos el Frontend de webs responsive, PWA (Progressive Web Apps) y backend a medida para que puedas gestionar el contenido de tus aplicaciones.",
    "en": "Desarrollamos el Frontend de webs responsive, PWA (Progressive Web Apps) y backend a medida para que puedas gestionar el contenido de tus aplicaciones."
  },
  "OG_TITLE_SISTEMAS_DISENO": {
    "es": "Construimos sistemas de diseño para las empresas. Cuenta con nuestros expertos en diseño digital para dar consistencia a los productos de tu compañía.",
    "en": "Construimos sistemas de diseño para las empresas. Cuenta con nuestros expertos en diseño digital para dar consistencia a los productos de tu compañía."
  },
  "OG_TITLE_PRIVACY": {
    "es": "Aquí puedes conocer nuestra Política de Privacidad y Condiciones de acceso y utilización de la web de Quadram.",
    "en": "Aquí puedes conocer nuestra Política de Privacidad y Condiciones de acceso y utilización de la web de Quadram."
  },
  "OG_TITLE_COOKIES": {
    "es": "Aquí puedes conocer información sobre cookies y nuestra Política de Cookies analíticas de la web de Quadram.",
    "en": "Aquí puedes conocer información sobre cookies y nuestra Política de Cookies analíticas de la web de Quadram."
  },
  "OG_TITLE_SISTEMAS_DISENO": {
    "es": "Construimos sistemas de diseño para las empresas. Contar con una guía de estilos y componentes reutilizables reducen costes de desarrollo y dan consistencia a los productos digitales de la compañía.",
    "en": "Construimos sistemas de diseño para las empresas. Contar con una guía de estilos y componentes reutilizables reducen costes de desarrollo y dan consistencia a los productos digitales de la compañía."
  },
  "QUADRAM_CONTACT_MAIL": {
    "es": "info@quadram.mobi",
    "en": "info@quadram.mobi"
  },
  "MENU_OPTION_HOME": {
    "es": "Home",
    "en": "Home"
  },
  "MENU_OPTION_INTRODUCTION": {
    "es": "Introducción",
    "en": "Introduction"
  },
  "MENU_OPTION_SERVICES": {
    "es": "Servicios",
    "en": "Services"
  },
  "MENU_OPTION_WORKS": {
    "es": "Trabajos",
    "en": "Works"
  },
  "MENU_OPTION_TEAM": {
    "es": "Equipo",
    "en": "Team"
  },
  "MENU_OPTION_CONTACT": {
    "es": "¿Hablamos?",
    "en": "Want to talk?"
  },
  "MENU_OPTION_VENTURES": {
    "es": "Ventures",
    "en": "Ventures"
  },
  "MENU_OPTION_CLIENTS": {
    "es": "Clientes",
    "en": "Clients"
  },
  "MENU_OPTION_BUDGET": {
    "es": "Pide presupuesto",
    "en": "Ask for a budget"
  },
  "SCROLL_DOWN": {
    "es": "Scroll down",
    "en": "Scroll down"
  },
  "NAV_BUTTON": {
    "es": "¿Hablamos?",
    "en": "Want to talk?"
  },
  "HOME_HOME": {
    "es": "Hola",
    "en": "Hello"
  },
  "HOME_SERVICES": {
    "es": "Servicios ",
    "en": "Services"
  },
  'HOME_INTRODUCTION': {
    "es": "Introducción",
    "en": "Introduction"
  },
  "HOME_WORKS": {
    "es": "Trabajos",
    "en": "Works"
  },
  "HOME_TEAM": {
    "es": "Equipo",
    "en": "Team"
  },
  "HOME_CLIENTS": {
    "es": "Clientes",
    "en": "Clients"
  },
  "HOME_VENTURES": {
    "es": "¿Eres una startup?",
    "en": "Are you a startup?"
  },
  "HOME_CONTACT": {
    "es": "Somos todo oídos ",
    "en": "We are all ears"
  },
  "WORKS_PAGE": {
    "es": "Nuestros trabajos",
    "en": "Our works"
  },
  "HOME_HOME_TITLE": {
    "es": "Desarrollo de<br> aplicaciones<br> móviles",
    "en": "We create <br> mobile <br> apps"
  },
  "HOME_HOME_SUBTITLE": {
    "es": "Apps - Webapps - Ux Ui - Agencia Digital",
    "en": "Apps - Webapps - Ux Ui - Digital Agency"
  },
  "HOME_HOME_TEXT": {
    "es": "+ 12 años creando productos digitales elegantes, sostenibles y rentables",
    "en": "+ 12 years creating smart digital products, sustainable and profitable"
  },
  "HOME_HOME_BUTTON": {
    "es": "Comenzar Proyecto",
    "en": "Start your project"
  },
  "HOME_SERVICES_TITLE": {
    "es": "Creando experiencias digitales que resuenen con la cultura e inspiren el compromiso.",
    "en": "Discover how we cover all aspects of your digital transformation."
  },
  "HOME_INTRODUCTION_DESCRIPTION": {
    "es": "Diseño, desarrollo de aplicaciones móviles y consultoría digital.",
    "en": "Mobiles apps design and development and digital consultancy."
  },
  "HOME_INTRODUCTION_DESCRIPTION_1": {
    "es": "Quadram nació en 2008 como una agencia de Transformación Digital. Con los años y la experiencia se ha convertido en un referente en su sector. Durante todo este tiempo hemos realizado " +
      "el diseño y desarrollo de apps para multitud de compañías, para diversos sectores y tamaño de empresas. Hemos convertido grandes ideas en excelentes soluciones digitales.",
    "en": "Quadram was born in 2008 as a Digital Transformation agency. Over the years and experience it has become a benchmark in its sector. During all this time we have designed and developed " +
      "applications for many companies, for various sectors and company sizes. We have turned great ideas into great digital solutions."
  },
  "HOME_INTRODUCTION_DESCRIPTION_2": {
    "es": "Como cada proyecto es único, nos adaptamos siempre a las necesidades específicas de cada cliente para buscar juntos la mejor solución y plataforma tecnológica.",
    "en": "Due to each project is unique, we adapt always to the specific clients needs to find together the best solution and tech platform."
  },
  "HOME_INTRODUCTION_DESCRIPTION_3": {
    "es": "Combinamos estrategia, creatividad y tecnología para ayudar a las empresas a definir, diseñar y desarrollar sus aplicaciones móviles, webs y proyectos digitales.",
    "en": "We combine strategy, creativity and technology to help business to define, design and develop their mobile apps, webs and digital projects."
  },
  "HOME_INTRODUCTION_1_NAME": {
    "es": "Apps",
    "en": "Apps"
  },
  "HOME_SERVICES_1_DESCRIPTION": {
    "es": "Apps, webs responsive, diseño digital o chatbots; combinamos estrategia, creatividad y tecnología para ayudar a las empresas a definir, diseñar y desarrollar productos digitales.",
    "en": "Apps, webs responsive, diseño digital o chatbots; combinamos estrategia, creatividad y tecnología para ayudar a las empresas a definir, diseñar y desarrollar productos digitales."
  },
  "HOME_INTRODUCTION_2_NAME": {
    "es": "Webs",
    "en": "Webs"
  },
  "HOME_SERVICES_2_DESCRIPTION": {
    "es": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360.",
    "en": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360."
  },
  "HOME_INTRODUCTION_3_NAME": {
    "es": "Chatbots",
    "en": "Chatbots"
  },
  "HOME_SERVICES_3_DESCRIPTION": {
    "es": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360.",
    "en": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360."
  },
  "HOME_INTRODUCTION_4_NAME": {
    "es": "Diseño",
    "en": "Diseño"
  },
  "HOME_SERVICES_4_DESCRIPTION": {
    "es": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360.",
    "en": "Apps, webs responsive, diseño digital o chatbots. Combinamos estrategia, creatividad y tecnología para crear experiencias digitales 360."
  },
  "HOME_SERVICES_TEAM_AUGMENTATION_BUTTON": {
    "es": "Más sobre Team Augmentation",
    "en": "More about Team Augmentation"
  },
  "HOME_SERVICES_BUTTON": {
    "es": "Nuestros servicios",
    "en": "Our services"
  },
  "HOME_SERVICES_MAIN_TITLE": {
    "es": "Nuestros servicios",
    "en": "Our services"
  },
  "HOME_SERVICES_TITLE_1": {
    "es": "Desarrollo de apps",
    "en": "Apps development",
  },
  "HOME_SERVICES_DESCRIPTION_1": {
    "es": "<p>Trabajamos con múltiples entornos y lenguajes de programación. Líderes en desarrollo de software mobile, aplicaciones móviles nativas (iOS y Android) e híbridas, y web apps. " +
      "Siempre buscando agilidad, calidad y escalabilidad en todas las soluciones digitales que creamos.</p>",
    "en": "We work with multiple environments and programming languages. Leaders in mobile software development, native and hybrid mobile applications, and web apps." +
      "Always looking for agility, quality and scalability in all the digital solutions we create."
  },
  "HOME_SERVICES_TITLE_2": {
    "es": "Diseño UX y UI",
    "en": "UX / UI design",
  },
  "HOME_SERVICES_DESCRIPTION_2": {
    "es": "Entender al usuario final de una aplicación móvil, una web o un chatbot es clave, analizar su perfil y cuáles son sus motivaciones. Trabajamos con nuestros clientes " +
      "para diseñar aplicaciones móviles y soluciones atractivas teniendo en cuenta la usabilidad y por supuesto, adaptadas a cada dispositivo.",
    "en": "Understand the final user of a mobile app, web or chatbot is the key, analize his profile and which is his motivations. We work with our clients " +
      "to design mobile apps and attractive solutions keeping mind the usability and, of course, adapted each device"
  },
  "HOME_SERVICES_TITLE_3": {
    "es": "Consultoría digital",
    "en": "Digital consultancy"
  },
  "HOME_SERVICES_DESCRIPTION_3": {
    "es": "<p>La estrategia es la piedra angular de todo producto. Trabajamos con nuestros clientes para entender sus objetivos y necesidades para el desarrollo de las apps, crecimiento y " +
      "monetización de su proyecto. Como partners, definimos en conjunto un roadmap de producto que cumpla sus necesidades.</p>",
    "en": "<p>The strategy is the cornerstone of every projects.We work with our clients to understand their goals and needs for the apps development, growing and monetization of his project. " +
      "As partners, we define together a product roadmap that accomplish its needs."
  },
  "HOME_SERVICES_TEAM_AUGMENTATION_TITLE": {
    "es": "Team Augmentation: equipos de desarrollo dedicados.",
    "en": "Team Augmentation: dedicated development teams."
  },
  "HOME_SERVICES_TEAM_AUGMENTATION_DESCRIPTION": {
    "es": "Son muchas las compañías que recurren a nuestro modelo de team augmentation o staff augmentation como la solución óptima a sus necesidades, ya que les permite integrar diseñadores y desarrolladores " +
      "clave a su equipo rápidamente, sumando más expertise y aumentando la velocidad de entrega de sus proyectos, reduciendo riesgos y costes referentes a la contratación de nuevo personal." +
      "Si necesitas disponer de un equipo flexible, puedes elegir nuestra modalidad Team Augmentation para tus desarrollos de aplicaciones móviles, backend, diseño, etc.",
    "en": "There are many business that draw on to our team augmentation model or augmentation staff as the best solution to their needs, because it allows their integrate designers and developers to their teams " +
      "quickly, adding up expertise and increasing the speed of the projects delivery, reducing risks and costs regarding the hiring of new staff. If you need dispose of a flexible team, you can " +
      "choose our modality of Team Augmentation for your mobile apps developments, backend, design, etc."
  },
  "HOME_WORKS_TITLE": {
    "es": "Soluciones digitales, innovadoras y seguras para las empresas del futuro.",
    "en": "Digital solutions, innovative and secure for the business of the future."
  },
  "WORKS_PAGE_TITLE": {
    "es": "Algunas de nuestras colaboraciones",
    "en": "Some of our colaborations"
  },
  "HOME_WORKS_TITLE_1": {
    "es": "Más proyectos que te pueden interesar :",
    "en": "More projects that can interest you :"
  },
  "HOME_WORKS_1_TEXT": {
    "es": "Sistema de información de salud",
    "en": "Health information system"
  },
  "HOME_WORKS_2_TEXT": {
    "es": "I+D para la seguridad personal",
    "en": "I+D for the security personal"
  },
  "HOME_WORKS_3_TEXT": {
    "es": "El seguro de tu coche siempre a mano",
    "en": "Revolutionizing personal security"
  },
  "HOME_WORKS_4_TEXT": {
    "es": "Ayudando a entender al cliente",
    "en": "Helping to understand the client"
  },
  "HOME_WORKS_5_TEXT": {
    "es": "Primer e-commerce de coches de España",
    "en": "First cars e-commerce in Spain"
  },
  "HOME_WORKS_DESCRIPTION_TEXT": {
    "es": "Expertos en aplicaciones Corporativas. Analizamos y definimos estrategias digitales para incluir el desarrollo de apps como estrategia de marketing y gamificación en tu negocio.",
    "en": "Corporative applications experts. We analyze and define digital strategies to include apps develops ass strategie of marketing and gamification in your business."
  },
  "HOME_SERVICES_CONSULTANCY_TITLE": {
    "es": "Consultoría de producto",
    "en": "Product consultancy"
  },
  "HOME_SERVICES_CONSULTANCY_1_TEXT": {
    "es": "Puedes contar con todo nuestro potencial para <strong>consultoría y asesoramiento técnico</strong>, analizar la experiencia de usuario (<strong>UX</strong>), diseñar tus proyectos (<strong>UI</strong>) " +
      "y desarrollar tu idea con la tecnología más apropiada (<strong>iOS, Android, web, chatbot</strong>).",
    "en": "You can count on with all our potential to <strong>consultancy and technical advice</strong>, analize the user experience (<strong>UX</strong>), design your project (<strong>UI</strong>) " +
      "and develop your idea with the most appropriate technology (<strong>iOS, Android, web, chatbot</strong>).",
  },
  "HOME_SERVICES_CONSULTANCY_2_TEXT": {
    "es": "Estamos especializados tanto en negocios 100% mobile (B2C) como en aplicaciones corporativas para mejorar y digitalizar procesos (B2B) o que sirvan de canal para tus campañas de marketing.",
    "en": "We are specialized in 100% business mobile (B2C) and in corporatives applications to improve and digitalize process (B2B) or serve as a channel for your marketing campaigns. "
  },
  "HOME_SERVICES_MAINTENANCE_TITLE": {
    "es": "Mantenimiento y evolución",
    "en": "Maintenance and evolution"
  },
  "HOME_SERVICES_MAINTENANCE_1_TEXT": {
    "es": "Si ya tienes una app o web desarrollada y no sabes cómo evolucionarla o actualizarla, ¡cuéntanos!",
    "en": "If you have a developed app or web and you don't know how to evolve or update it, tell us!"
  },
  "HOME_SERVICES_MAINTENANCE_2_TEXT": {
    "es": "No dudes en contactar con nosotros a través de nuestro formulario o llámanos para comentarnos tu idea de proyecto y te haremos llegar un presupuesto lo antes posible.",
    "en": "Don't doubt to contact with us through our contact form or calling to comment us yout project idea and we send to you the budget as soon as possible."
  },
  "HOME_CLIENTS_TITLE": {
    "es": "Clientes que confían en nosotros.",
    "en": "Clients that trust in us."
  },
  "HOME_WORKS_BUTTON": {
    "es": "Más trabajos",
    "en": "More works"
  },
  "HOME_CLIENTS_BUTTON": {
    "es": "Nuestros clientes",
    "en": "Our clients"
  },
  "HOME_VENTURES_TITLE": {
    "es": "Future",
    "en": "Future"
  },
  "HOME_VENTURES_SUBTITLE": {
    "es": "Here",
    "en": "Here"
  },
  "HOME_VENTURES_TEXT": {
    "es": "Creemos en los comienzos. Te ayudamos a definir, gestionar y crear tus proyectos digitales.",
    "en": "We believe in beginnings. We help you to define, manage and build your digital project."
  },
  "HOME_VENTURES_BUTTON": {
    "es": "Cuéntanos más",
    "en": "Tell us more"
  },
  'HOME_TELL_US_CLIENTS_BUTTON': {
    'es': "Cuéntanos",
    "en": "Tell us"
  },
  "HOME_CONTACT_TEXT": {
    "es": "Queremos escuchar tus ideas.",
    "en": "We want to listen your ideas."
  },
  "HOME_CONTACT_BUTTON": {
    "es": "¿Hablamos?",
    "en": "Shall we talk?"
  },
  "HOME_FOOTER_TITLE_1": {
    "es": "Sobre Nosotros",
    "en": "About Us"
  },
  "HOME_FOOTER_TITLE_2": {
    "es": "Lo que hacemos",
    "en": "What we do"
  },
  "HOME_FOOTER_TITLE_3": {
    "es": "Quadram Contacto",
    "en": "Quadram Contact"
  },
  "HOME_FOOTER_TITLE": {
    "es": "¡Hágamos juntos un gran proyecto!",
    "en": "Let's make something great together! "
  },
  "HOME_FOOTER_TEXT": {
    "es": "Estamos aquí para ayudarlo con sus productos digitales o su proceso de transformación digital. Si necesita desarrollar un proyecto completo o colaboraciones específicas de nuestros expertos móviles, lo cumpliremos.",
    "en": "We are here to help you with your digital products or your digital transformation process. If you need to develop a complete project or specific collaborations of our mobile experts, we will fulfill it."
  },
  "HOME_FOOTER_BUTTON": {
    "es": "Comenzar Proyecto",
    "en": "Start Project"
  },
  "HOME_FOOTER_MENA": {
    "es": "Quadram MENA",
    "en": "Quadram MENA"
  },
  "HOME_FOOTER_SERVICES": {
    "es": "Servicios",
    "en": "Services"
  },
  "HOME_FOOTER_WORKS": {
    "es": "Portfolio",
    "en": "Portfolio"
  },
  "HOME_FOOTER_VENTURES": {
    "es": "Ventures",
    "en": "Ventures"
  },
  "HOME_FOOTER_TEAM": {
    "es": "Equipo",
    "en": "Team"
  },
  "HOME_FOOTER_CAREERS": {
    "es": "Trabaja con nosotros",
    "en": "Work with us"
  },
  "HOME_FOOTER_BLOG": {
    "es": "Blog",
    "en": "Blog"
  },
  "HOME_FOOTER_MAIL": {
    "es": "info@quadram.mobi",
    "en": "info@quadram.mobi"
  },
  "HOME_FOOTER_PHONE": {
    "es": "(+34) 911 280 011",
    "en": "(+34) 911 280 011"
  },
  "HOME_FOOTER_CITIES_1_NAME": {
    "es": "Madrid",
    "en": "Madrid"
  },
  "HOME_FOOTER_CITIES_1_ADDRESS": {
    "es": "C/ Claudio Coello, 77 Planta 4ª<br>28001 Madrid",
    "en": "C/ Claudio Coello, 77 Planta 4ª<br>28001 Madrid"
  },
  "HOME_FOOTER_COPYRIGHT": {
    "es": "Quadram©",
    "en": "Quadram©"
  },
  "HOME_FOOTER_TERMS": {
    "es": "Política de privacidad",
    "en": "Privacy"
  },
  "HOME_FOOTER_COOKIES": {
    "es": "Política de cookies",
    "en": "Cookies"
  },
  "HOME_FOOTER_TERMS_LINK": {
    "es": "#",
    "en": "#"
  },
  "HOME_FOOTER_UXUI": {
    "es": "Diseño Ux/Ui",
    "en": "Ux/Ui Design"
  },
  "HOME_FOOTER_IOS": {
    "es": "Desarrollo Ios",
    "en": "Ios Develop"
  },
  "HOME_FOOTER_ANDROID": {
    "es": "Desarrollo Android",
    "en": "Android Develop"
  },
  "HOME_FOOTER_REACT_NATIVE": {
    "es": "Desarrollo React Native",
    "en": "React Native Develop"
  },
  "HOME_FOOTER_TEAM_AUGMENTATION": {
    "es": "Team Augmentation",
    "en": "Team Augmentation"
  },
  "HOME_FOOTER_BACKEND": {
    "es": "Desarrollo Backend",
    "en": "Backend develop"
  },
  "HOME_FOOTER_DESIGN_SYSTEMS": {
    "es": "Sistemas de diseño",
    "en": "Design systems"
  },
  "FOOTER_SERVICE_UXUI_TITLE": {
    "es": "Ux & Ui",
    "en": "Ux & Ui"
  },
  "FOOTER_SERVICE_IOS_TITLE": {
    "es": "Desarrollo <br>Ios",
    "en": "Ios <br>Develop"
  },
  "FOOTER_SERVICE_ANDROID_TITLE": {
    "es": "Desarrollo <br>Android",
    "en": "Android <br>Develop"
  },
  "FOOTER_SERVICE_REACT_NATIVE_TITLE": {
    "es": "Desarrollo <br>React Native",
    "en": "React Native <br>Develop"
  },
  "FOOTER_SERVICE_TEAM_AUGMENTATION_TITLE": {
    "es": "Team <br>Augmentation",
    "en": "Team <br>Augmentation"
  },
  "FOOTER_SERVICE_BACKEND_TITLE": {
    "es": "Desarrollo <br>Backend",
    "en": "Backend <br>Develop"
  },
  "FOOTER_SERVICE_DESIGN_SYSTEM_TITLE": {
    "es": "Sistemas <br>de diseño",
    "en": "Design <br>systems"
  },
  "FOOTER_SERVICE_1_HEADING": {
    "es": "Usabilidad y diseño en tus aplicaciones móviles",
    "en": "Usability and design in your mobile apps"
  },
  "FOOTER_SERVICE_2_HEADING": {
    "es": "Creamos apps nativas para ios",
    "en": "We make ios native apps"
  },
  "FOOTER_SERVICE_3_HEADING": {
    "es": "Creamos apps nativas para dispositivos Android",
    "en": "We make native apps for Android devices"
  },
  "FOOTER_SERVICE_4_HEADING": {
    "es": "Creamos apps en React Native",
    "en": "Usability and design in your mobile apps"
  },
  "FOOTER_SERVICE_5_HEADING": {
    "es": "Equipo de expertos para tu proyecto",
    "en": "Experts teams for your project"
  },
  "FOOTER_SERVICE_6_HEADING": {
    "es": "Desarrollo Frontend y backend",
    "en": "Frontend and backend develop"
  },
  "FOOTER_SERVICE_7_HEADING": {
    "es": "Creamos sistemas de diseño",
    "en": "We make design systems"
  },
  "FOOTER_SERVICE_1_CONTENT": {
    "es": "<p>En QUADRAM prestamos especial atención a la usabilidad y diseño de aplicaciones móviles. Para ello es importante seleccionar correctamente las funcionalidades de cada app. Nos encargaremos de conceptualizar el proyecto, generar y validar hipótesis de uso.</p>" +
      "<p>Dibujaremos los primeros wireframes para posteriormente diseñar cada pantalla e interacción con el usuario. Al final del proceso podrás contar con un prototipo 100% funcional listo para entrar en producción y desarrollo.</p>",
    "en": "<p>In QUADRAM, we put special attention to mobiles applications usability and design. For this, is important select correctly the apps funcionalities. We will be in charge of conceptualizing the project, generating and validating hypotheses for use.</p>" +
      "<p>We will draw the first wireframes to, later, design each app screen and user interaction. To the end of the proccess, yo can dispose of a prototype 100% functional, ready to start the production and develop.</p>"
  },
  "FOOTER_SERVICE_2_CONTENT": {
    "es": "<p>En QUADRAM desarrollamos aplicaciones 100% nativas para iOS. Hemos evolucionado desarrollando apps para dispositivos iPhone y iPad desde la primera generación hasta la actualidad.</p>" +
      "<p>Contamos con experiencia en desarrollo de aplicaciones orientadas al deporte y hábitos saludables para Apple Watch.</p>" +
      "<p>Somos expertos en publicación en App Store y en el proceso de revisión de las apps. Los desarrollos siguen las normas recogidas en las guidelines e incorporan las últimas tendencias marcadas por el fabricante.</p>" +
      "<p>Nuestros lenguajes de programación más comunes son Swift y Swift UI.</p>",
    "en": "<p>In QUADRAM, we develop 100% native iOS apps. We've evolver developin apps for iPhone and iPad devices since the first generation to the present</p>" +
      "<p>We have experience in the develop of sports and healthy habits apps for Apple Watch.</p>" +
      "<p>We are experts in the publication in App Store and in the process of the apps review. The developers follow the guidelines rules and they incorporate the last tendencies indicates by the producer.</p>" +
      "<p>Our programming languages more commons are Swift and Swift UI.</p>"
  },
  "FOOTER_SERVICE_3_CONTENT": {
    "es": "<p>El sistema operativo Android es el que mayor cuota de mercado alcanza en la actualidad. En Quadram desarrollamos aplicaciones 100% nativas para Android.</p>" +
      "<p>Utilizamos distintas tecnologías dependiendo de las necesidades de tu proyecto para poder publicar con éxito tu app en Google Play Store. Seguimos las recomendaciones y tendencias de diseño para que las aplicaciones se adapten a la gran diverisdad de modelos y fabricantes existentes.</p>" +
      "<p>Contamos con experiencia en desarrollo de aplicaciones orientadas al deporte y estilo de vida para wereables Android.</p>" +
      "<p>Utilizamos Kotlin y Jetpack Compose.</p>",
    "en": "<p>Android is the Operative System with more market share in the present. In Quadram, we develop 100% native Android apps.</p>" +
      "<p>We use different technologies depending of the requirements of your project to publish your app in Google Play Store with success. We follow the design recommendations and trends for the applications adapt to the great diversity of existing models and manufactures.</p>" +
      "<p>We have experience in the develop of sports and healthy habits for Android wereables</p>" +
      "<p>We use Kotlin y Jetpack Compose.</p>"
  },
  "FOOTER_SERVICE_4_CONTENT": {
    "es": "<p>En Quadram desarrollamos proyectos y aplicaciones en React Native. Esta tecnología nos permite desplegar un mismo código en ambas plataformas y publicar la misma aplicación en los distintos markets de Google Play Store y App Store.</p>" +
      "<p>Valoraremos en conjunto las características y requisitos del proyecto, para decidir y aplicar el paradigma de desarrollo más adecuado entre React Native o 100% nativo.</p>",
    "en": "<p>In Quadram, we develop React Native projects and apps. This technology we allows deploy the same code in both platforms and publish the same app in differents markets of Google Play Store and App Store.</p>" +
      "<p>We value in team the project features and requirements, yo decide and apply the paradigm of the develop most appropriate between React native or 100% native.</p>"
  },
  "FOOTER_SERVICE_5_TITLE_CONTENT": {
    "es": "Tu equipo a medida",
    "en": "Your custom team"
  },
  "FOOTER_SERVICE_5_CONTENT": {
    "es": "<p>Contrata equipos de desarrollo dedicados para que se unan y colaboren con tu equipo interno de desarrollo.</p><br>" +
      "<p>Unimos talento y experiencia para crear los mejores equipos especializados en desarrollo iOS, Android, React Native y desarrollo de Backend. UX/UI y Q&A.</p>",
    "en": "<p>Hire dedicated development teams to join and collaborate with your internal development team.</p><br>" +
      "<p>We join talent and experience to create the best teams specialized in iOS, Android, React Native and Backend development. UX/UI and Q&A"
  },
  "FOOTER_SERVICE_6_CONTENT": {
    "es": "<p>Lideramos el core técnico de cada proyecto. Desarrollamos el Frontend de webs responsive y PWA (Progressive Web Apps) para que puedas gestionar el contenido y consultar analíticas de tu negocio de manera sencilla y rápida.</p>" +
      "<p>Desarrollo Backend. Diseñamos la estructura de tu BBDD y desarrollamos las APIs de comunicación para que el proyecto esté preparado para crecer de manera escalable y sólida. Creemos en proyectos sostenibles que se mantengan y mejoren en el tiempo.</p>",
    "en": "<p>We are leaders in the technical core in each project. We develop the Frontend of the responsive webs and PWA (Progressive Web Apps) for you can gestionate the content and consult your business analytics easily and quickly.</p> " +
      "<p>backend develop. We design the infraestructure of the DDBB and develop the communication APIs for what the project will be ready to grow in a scalable and robust way. We believe in sustainable projects that are maintained and improve over time.</p>"
  },
  "FOOTER_SERVICE_7_CONTENT": {
    "es": "<p>Un sistema de diseño es un conjunto de elementos que se pueden reutilizar y que permiten crear apps más escalables y robustas.</p>" +
      "<p>Un sistema de diseño debe estar presente en todas las fases del proyecto, debemos seguir siempre los mismos principios, limitaciones y reglas a través de un lenguaje común entre los distintos departamentos: diseño, producto y negocio.</p>" +
      "<p>Contar con un sistema de diseño contribuirá a reducir costes de desarrollo, y mantener la coherencia y consistencia de los diferentes productos digitales de la compañía.</p>",
    "en": "<p>A design system is a set of items that can be reused and that allow make apps more scalable and robust.</p>" +
      "<p>A design system must be present in all project phases, we should follow allways the same principles, limitations and rules through a common language between the different departments: design, product and business.</p>" +
      "<p>Having a design system will help reduce development costs, and maintain the coherence and consistency of the company's different digital products.</p>"
  },
  "FOOTER_SERVICE_5_INFO_SECTION": {
    "es": "¿Qué es?",
    "en": "What is this?"
  },
  "FOOTER_SERVICE_5_CLIENTS_SECTION": {
    "es": "Dicen los clientes",
    "en": "Clients say"
  },
  "FOOTER_SERVICE_5_ADVANTAGES_SECTION": {
    "es": "Ventajas",
    "en": "Advantages"
  },
  "FOOTER_SERVICE_5_FAQS_SECTION": {
    "es": "Preguntas frecuentes",
    "en": "Faqs"
  },
  "FOOTER_SERVICE_5_WORK_SECTION": {
    "es": "Cómo funciona",
    "en": "How it's work"
  },
  "FOOTER_SERVICE_5_OPINION_1": {
    "es": "We worked with Quadram on our mobile application and both the experience and results were great. The collaboration was smooth, setting up our processes was quick, " +
      "implementation was on time, and professional. I recommend working with Quadram to everyone who wants to improve their mobile presence!",
    "en": "We worked with Quadram on our mobile application and both the experience and results were great. The collaboration was smooth, setting up our processes was quick, " +
      "implementation was on time, and professional. I recommend working with Quadram to everyone who wants to improve their mobile presence!"
  },
  "FOOTER_SERVICE_5_OPINION_2": {
    "es": "Muy cómoda y flexible la modalidad de Staff Augmentation para desarrollar y evolucionar mi producto. Los desarrolladores tienen sólidos conocimientos técnicos, " +
      "así como amplia experiencia en arquitectura y buenas prácticas de programación.",
    "en": "Muy cómoda y flexible la modalidad de Staff Augmentation para desarrollar y evolucionar mi producto. Los desarrolladores tienen sólidos conocimientos técnicos, " +
      "así como amplia experiencia en arquitectura y buenas prácticas de programación."
  },
  "FOOTER_SERVICE_5_CLIENT_NAME_1": {
    "es": "Jacek Mech",
    "en": "Jacek Mech"
  },
  "FOOTER_SERVICE_5_CLIENT_COMPANY_1": {
    "es": "SVP Engineering, TrustYou",
    "en": "SVP Engineering, TrustYou"
  },
  "FOOTER_SERVICE_5_CLIENT_NAME_2": {
    "es": "Javier Arenzana",
    "en": "Javier Arenzana"
  },
  "FOOTER_SERVICE_5_CLIENT_COMPANY_2": {
    "es": "Founder & CEO",
    "en": "Founder & CEO"
  },
  "FOOTER_SERVICE_5_ADVANTAGES_TITLE": {
    "es": "Nuestro modelo Team Augmentation incentiva relaciones colaborativas y de confianza que perduran en el tiempo.",
    "en": "Our Team Augmentation model encourages collaborative and trusting relationships that last over time"
  },
  "FOOTER_SERVICE_5_ADVANTAGE_TITLE_1": {
    "es": "Focus",
    "en": "Focus"
  },
  "FOOTER_SERVICE_5_ADVANTAGE_CONTENT_1": {
    "es": "Elimina por completo todos los costes administrativos y de contratación. No inviertas tiempo en estas tareas. Podrás centrarte 100% en tu negocio.",
    "en": "It completely deletes all administrative and contracting costs. Don't spend time on these tasks. You can focus 100% on your business."
  },
  "FOOTER_SERVICE_5_ADVANTAGE_TITLE_2": {
    "es": "Control",
    "en": "Control"
  },
  "FOOTER_SERVICE_5_ADVANTAGE_CONTENT_2": {
    "es": "Mantén un control técnico sobre el proyecto, tendrás una visión completa de deadlines, roadmap y subidas a producción siempre que la necesites.",
    "en": "Keep a technical control over your project, you will have a compplete view of deadlines, roadmap and uploads to production whenever you need it."
  },
  "FOOTER_SERVICE_5_ADVANTAGE_TITLE_3": {
    "es": "Costes",
    "en": "Costs"
  },
  "FOOTER_SERVICE_5_ADVANTAGE_CONTENT_3": {
    "es": "Tendrás un control absoluto de los costes que supone la colaboración, sin sobresaltos ni sorpresas ocasionados por cambios de alcance o desviaciones en el proyecto. Somos 100%      transparentes.",
    "en": "You will have a absolute control of the costs involved in the collaboration, without shocks or surprises caused by changes in scope or deviations in the project. We're 100% transparent."
  },
  "FOOTER_SERVICE_5_ADVANTAGE_TITLE_4": {
    "es": "Flexibidad",
    "en": "Flexibity"
  },
  "FOOTER_SERVICE_5_ADVANTAGE_CONTENT_4": {
    "es": "Aumenta tu flexibilidad siempre que lo necesites para aumentar o disminuir tu capacidad de desarrollo en función de tus objetivos y fases de crecimiento o estabilización.",
    "en": "Increase your flexibility whenever you need to increase or decrease your development capacity depending on your goals and phases of growth or stabilization."
  },
  "FOOTER_SERVICE_5_FAQS_TITLE": {
    "es": "¿Cómo funciona el modelo Team Augmentation?",
    "en": "How Team Augmentation model works?"
  },
  "TEAM_AUGMENTATION_FAQ_1_TITLE": {
    "es": "¿Qué es eso de Team Augmentation?",
    "en": "What is Team Augmentation?"
  },
  "TEAM_AUGMENTATION_FAQ_1_DESCRIPTION": {
    "es": "Es un modelo en el que liberamos a nuestros clientes de todas las responsabilidades y burocracias de contratar y arrancar un equipo de alto rendimiento desde 0." +
      "En cuestión de semanas podrás contar con un equipo listo para liderar tu proyecto.",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_2_TITLE": {
    "es": "¿Cuáles son los beneficios de contratar un equipo dedicado?",
    "en": "What are the benefits of hiring a dedicated team?"
  },
  "TEAM_AUGMENTATION_FAQ_2_DESCRIPTION": {
    "es": "<p>Contrata equipos de desarrollo únicamente cuando sea necesario para tu proyecto.<br>Disminuye tus costes operacionales y tiempos de arranque de proyecto.</p>" +
      "<p>Facilidades para escalar o disminuir tu equipo en función de los requerimientos del proyecto.</p><p>Comunicación directa con tu equipo técnico.</p>",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_3_TITLE": {
    "es": "¿Para qué tipo de proyectos tiene sentido este modelo?",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_3_DESCRIPTION": {
    "es": "<p>Proyectos pensados en el largo plazo con variaciones en alcance técnico y roadmap dependiendo siempre de métricas y feedback de usuarios.</p>" +
      "<p>Startups en fase Growth que necesitan escalar rápido e internacionalizarse en distintos países controlando sus recursos y financiación.</p>" +
      "<p>Compañías que cuentan con un equipo IT técnico pero no están especializados en aplicaciones móviles y necesitan soporte de personas especializadas para mejorar su experiencia mobile.</p>" +
      "<p>Empresas que han tenido experiencias con desarrollo a medida y entienden que están en un momento en el que necesitan más recursos y dedicación pero no se ven con la necesidad de crecer su equipo interno de manera exponencial.</p>",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_4_TITLE": {
    "es": "¿Cuánto cuesta contratar un equipo Team Augmentation?",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_4_DESCRIPTION": {
    "es": "<p>Para contratar un equipo de desarrollo dedicado puedes contactar <a href='/contact'>aquí</a> para que te demos todo tipo de detalles. El coste depende de distintos factores:</p>" +
      "<ul><li>Número de personas que quieres para tu equipo.</li><li>Naturaleza del desarrollo o servicio (web front-end, mobile, backend, UX / UI</li>" +
      "<li>Jornada completa vs media jornada</li><li>Proyecto largo plazo vs proyecto corto plazo.</li></ul>",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_5_TITLE": {
    "es": "¿Qué tipo de profesionales puedo contratar en este modelo?",
    "en": ""
  },
  "TEAM_AUGMENTATION_FAQ_5_DESCRIPTION": {
    "es": "<ul><li>Desarrollo Android (Java & Kotlin)</li><li>Desarrollo iOS (Swift & Objective C)</li><li>Desarrollo Backend (PHP & .NET)</li><li>Desarrollo Frontend (HTML 5, Bootstrap)</li>" +
      "<li>Desarrollo Full Stack & Desarrollo React Native</li><li>Diseño UX / UI</li><li>Gestión de proyecto y especialista IT</li></ul>",
    "en": ""
  },
  'FOOTER_SERVICE_5_WORK_TITLE_1': {
    "es": "Asignamos al equipo",
    "en": "We assign the team"
  },
  "FOOTER_SERVICE_5_WORK_CONTENT_1": {
    "es": "Analizamos detenidamente tus necesidades técnicas para seleccionar bien qué personas te acompañarán en el proyecto. Conocerás al equipo de desarrollo antes de iniciar los desarrollos.",
    "en": "We analyze your technical needs to carefully select the people who will accompany you on the project. You will meet the development team before starting the developments."
  },
  "FOOTER_SERVICE_5_WORK_TITLE_2": {
    "es": "Onboarding",
    "en": "Onboarding"
  },
  "FOOTER_SERVICE_5_WORK_CONTENT_2": {
    "es": "Nuestro equipo se comprometerá con tu visión y objetivos. Se adaptarán a la metodología de trabajo que esté implementada en tu organización y a las herramientas de comunicación que utilicéis en la compañía. Serán el equipo A de desarrollo en tu departamento IT.<br>Somos 100% digitales. Apostamos por el trabajo en remoto.",
    "en": "Our team will commit to your vision and goals. They will be adapted to the work methodology that is implemented in your organization and to the communication tools that you use in your company. They will be The A Team in your IT department.<br>We are 100% digital. We are commited to remote work."
  },
  "FOOTER_SERVICE_5_WORK_TITLE_3": {
    "es": "Comunicación y feedback",
    "en": "Communication and feedback"
  },
  "FOOTER_SERVICE_5_WORK_CONTENT_3": {
    "es": "Al trabajar con los mismos miembros del equipo se adquiere una productividad consistente y sostenible en el tiempo. Podrás aumentar o disminuir el tamaño del equipo en función de las necesidades de negocio.",
    "en": "By working with the same team members, consistent and sustainable productivity is achieved over time. You can increase or decrease the members of the team depending on the business needs"
  },
  "FOOTER_SERVICE_1_QUESTION": {
    "es": "¿Te interesa diseñar una app con nosotros?",
    "en": "Do you want design an app with us?"
  },
  "FOOTER_SERVICE_2_QUESTION": {
    "es": "¿Te interesa diseñar una app y publicarla en App Store?",
    "en": "Do you want design an app and publish it in App Store?"
  },
  "FOOTER_SERVICE_3_QUESTION": {
    "es": "¿Te interesa desarrollar una app y publicarla en Google Play?",
    "en": "Do you want develop an app and publish it in Google Play?"
  },
  "FOOTER_SERVICE_4_QUESTION": {
    "es": "¿Te interesa desarrollar una app con React Native?",
    "en": "Do you want develop an React Native app?"
  },
  "FOOTER_SERVICE_5_QUESTION": {
    "es": "¿Necesitas expertos en apps en tu equipo?",
    "en": "Do you need apps experts in your teams?"
  },
  "FOOTER_SERVICE_6_QUESTION": {
    "es": "¿Te interesa desarrollar un proyecto con nosotros?",
    "en": "Do you want develop a project with us?"
  },
  "FOOTER_SERVICE_7_QUESTION": {
    "es": "¿Te interesa diseñar una app con nosotros?",
    "en": "Do you want design an app with us?"
  },
  "SERVICES_OFFERS_SECTION_TITLE": {
    "es": "Qué ofrecemos",
    "en": "What we offer"
  },
  "SERVICES_OFFER_TITLE": {
    "es": "Diseño y desarrollo a medida",
    "en": "Custom design and development"
  },
  "SERVICES_OFFER_DESCRIPTION_1": {
    "es": "Más de 12 años ayudando a las empresas a crear productos digitales rentables, innovadores y de valor.",
    "en": "More than 12 years helping business to create arrendables digital products, innovatives and with value."
  },
  "SERVICES_OFFER_DESCRIPTION_2": {
    "es": "Nuestros servicios se adaptan a las necesidades tecnológicas de cada proyecto: Consultoría Digital, Diseño de Producto, Desarrollo tecnológico a medida (backend, web, apps nativas iOS y Android o híbridas, web apps…).",
    "en": "Our services are adapted to the technologies needs of each project. Digital consultancy, Product Design, custom technological develop (backend, web, native apps iOS and Android or hibrid apps, web apps...)."
  },
  "SERVICES_OFFER_DESCRIPTION_3": {
    "es": "Estrategia y creatividad en nuestras soluciones, transparencia y agilidad en nuestra comunicación.",
    "en": "Strategy and creativity in our solutions, transparency and agility in our communication."
  },
  "SERVICES_SERVICES_SECTION_TITLE": {
    "es": "Qué nos gusta hacer",
    "en": "What we like to do"
  },
  "SERVICES_SERVICE_1_TITLE": {
    "es": "Desarrollo de apps",
    "en": "Apps development"
  },
  "SERVICES_SERVICE_1_DESCRIPTION": {
    "es": "<h3>Apps Nativas y Apps Híbridas</h3><p>Desarrollamos aplicaciones móviles en iOS y Android para cualquiera de sus dispositivos: móvil, iPad, tablet, watch. Siempre cumpliendo las mejores prácticas y las guías oficiales de desarrollo.</p>" + 
      "<p>Dependiendo de los requerimientos del proyecto, elegiremos contigo si es conveniente que la aplicación móvil sea nativa o híbrida. Nuestros lenguajes de programación del día a día son Swift y Swift UI, Objective-C, Kotlin, Jetpack Compose, " +
      "Java, React-Native, etc.</p><p>Gestionamos tus apps en App Store y Google Play.</p>",
    "en": "<h3>Native Apps and Hybrid Apps</h3><p>We develop mobile applications on iOS and Android for any of your devices: mobile, iPad, tablet, watch. Always complying with the best practices and official development guides.</p>" +
      "<p>Depending on the requirements of the project, we will choose with you if it is convenient for the mobile application to be native or hybrid. Our day-to-day programming languages ​​are Swift and Swift UI, Objective-C, Kotlin, Jetpack Compose, " +
      "Java, React-Native, etc.</p><p>We manage your apps on the App Store and Google Play.</p>"
  },
  "SERVICES_SERVICE_2_TITLE": {
    "es": "Desarrollo web",
    "en": "Web development"
  },
  "SERVICES_SERVICE_2_DESCRIPTION": {
    "es": "<h3>Backend</h3><p>Diseñamos la estructura de tu BBDD y desarrollamos las APIs de comunicación necesarias para que el proyecto esté preparado para crecer de manera escalable y sólida. Creemos en proyectos sostenibles que se mantengan y " +
      "mejoren en el tiempo.</p><h3>Frontend</h3><p>Desarrollamos el frontend de webs responsive, PWA (Progressive Web Apps) y webapps. Programamos el panel de gestión para que puedas gestionar el contenido de tu app y consultar analíticas de tu " +
      "negocio de manera sencilla y rápida.</p>",
    "en": "<h3>Backend</h3><p>We design the structure of your database and develop the necessary communication APIs so that the project is prepared to grow in a scalable and solid way. We believe in sustainable projects that are maintained and " +
      "improved over time.</p><h3>Frontend</h3><p>We develop the frontend of responsive websites, PWA (Progressive Web Apps) and webapps. We program the management panel so that you can manage the content of your app and check your business " +
      "analytics easily and quickly."
  },
  "SERVICES_SERVICE_3_TITLE": {
    "es": "Diseño de producto",
    "en": "Product design"
  },
  "SERVICES_SERVICE_3_DESCRIPTION": {
    "es": "<h3>UX (User Experience) y UI (User Interface)</h3><p>Creamos soluciones digitales pensando en la interacción del usuario con el producto, en la accesibilidad y usabilidad. Analizamos las necesidades de cada proyecto para satisfacerlas de " +
      "forma sencilla y útil.  Diseños ideados para que los usuarios naveguen cómodamente en tu aplicación. Validamos este trabajo a través de wireframes, árboles de decisión y prototipos.</p><p>Los colores, la tipografía, las imágenes, la iconografía, " +
      "etc. son algunos de los elementos con los que trabajamos de forma exquisita para hacer que un producto sea atractivo, refleje el look & feel deseado y fidelice a los usuarios. Validamos la interface del producto por medio de presentaciones de " +
      "producto y prototipos interactivos.</p><h3>Sistemas de Diseño</h3><p>Confeccionamos la guía de estilos y componentes que se podrán reutilizar para todos los productos digitales de la empresa. Esta herramienta permite un ahorro de costes y " +
      "proporciona consistencia en la imagen de la compañía.</p>",
    "en": "<h3>UX (User Experience) and UI (User Interface)</h3><p>We make digital solutions thinking in the user interaction with the product, in the accessibility and usability. We analyze the project needs to satisfy in a simple and useful way." +
      "Devised designs for users to navigate comfortably in your application. We validate this job using wireframes, for users to navigate comfortably in your application trees and prototypes.</p><p>The colours, typography, images, the icons, etc " +
      "These are some of the elements with which we work exquisitely to make a product attractive, reflect the desired look & feel and build user loyalty. We validate the product interface through product presentations and interactive prototypes." +
      "<h3>Design Systems</h3><p>We prepare the style guide and components that can be reused for all the company's digital products. This tool allows cost savings and provides consistency in the image of the company.</p>"
  },
  "SERVICES_SERVICE_4_TITLE": {
    "es": "Consultoría digital",
    "en": "Digital consultancy"
  },
  "SERVICES_SERVICE_4_DESCRIPTION": {
    "es": "Te ayudamos con la conceptualización de la idea para definir el alcance de tu producto digital. Nuestro equipo experto en soluciones tecnológicas te podrá asesorar sobre la tecnología más adecuada para desarrollar tu proyecto, "+
      "fases de lanzamiento y requisitos a tener en cuenta.",
    "en": "We help you with the conceptualization of the idea to define the scope of your digital product. Our expert team in technological solutions will be able to advise you on the most appropriate technology to develop your project, "+
      "launch phases and requirements to take into account."
  },
  "SERVICES_SERVICE_5_TITLE": {
    "es": "Chatbots - in app",
    "en": "Chatbots - in app"
  },
  "SERVICES_SERVICE_5_DESCRIPTION": {
    "es": "Diseñamos, desarrollamos y entrenamos chatbots para integrarlos en tus aplicaciones móviles y mejorar tus procesos de onboarding, funnels de venta y atención al cliente.",
    "en": "We design, develop and train chatbots to integrate it in your mobile apps and improve your onboarding process, sale funnels and customer support."
  },
  "SERVICES_SERVICE_6_TITLE": {
    "es": "Blockchain & Dapps",
    "en": "Blockchain & Dapps"
  },
  "SERVICES_SERVICE_6_DESCRIPTION": {
    "es": "Nos encanta crear smart contracts en Blockchain. Desarrollamos en Solidity, lenguaje de programación diseñado para ejecutarse de forma óptima en la EVM (Ethereum Virtual Machine).",
    "en": "We love create smart contracts in Blockchain. We develop in Solidity, code language designed to execute optimally in the EVM (Ethereum Virtual Machine)."
  },
  "SERVICES_DAYWORK_SECTION_TITLE": {
    "es": "Nuestro día a día",
    "en": "Our day to day"
  },
  "SERVICES_METHODOLOGY_SECTION_TITLE": {
    "es": "Metodología",
    "en": "Methodology"
  },
  "SERVICES_METHODOLOGY_1_TITLE": {
    "es": "BE AGILE MY FRIEND",
    "en": "BE AGILE MY FRIEND"
  },
  "SERVICES_METHODOLOGY_1_DESCRIPTION": {
    "es": "Optimizamos el proceso de diseño, desarrollo, implementación, testing y feedback. Trabajamos en el proceso circular Agile para iterar y mejorar constantemente.",
    "en": "We optimize design, development, implementation, testing and feedback processes. We are always improveing our own methodology."
  },
  "SERVICES_METHODOLOGY_2_TITLE": {
    "es": "NO OFFICE IS THE NEW OFFICE",
    "en": "NO OFFICE IS THE NEW OFFICE"
  },
  "SERVICES_METHODOLOGY_2_DESCRIPTION": {
    "es": "Queremos contar con los mejores profesionales sin ponernos barreras. Somos 100% digitales. Apostamos por el trabajo en remoto.",
    "en": "We would like to work with the best professionals, without the barriers of space. We are 100% digital. We belive in remote work."
  },
  "WORK_DETAILS_CASE_LABEL": {
    "es": "Caso",
    "en": "Case"
  },
  "WORK_DETAILS_CLIENT_LABEL": {
    "es": "Cliente",
    "en": "Client"
  },
  "WORK_DETAILS_YEAR_LABEL": {
    "es": "Año",
    "en": "Year"
  },
  "WORK_DETAILS_SCOPE_LABEL": {
    "es": "Competencias",
    "en": "Skills"
  },
  "WORK_DETAILS_1_TITLE": {
    "es": "Recogida y gestión de información en más de 67 países",
    "en": "Collecting and managing data in more than 67 countries"
  },
  "WORK_DETAILS_1_CASE": {
    "es": "Aplicación móvil Android para el mayor sistema de información de salud presente en más de 67 países.",
    "en": "Android app for the most used health information system available in more than 67 countries."
  },
  "WORK_DETAILS_1_CLIENT": {
    "es": "DHIS2",
    "en": "DHIS2"
  },
  "WORK_DETAILS_1_YEAR": {
    "es": "2017-Actualmente",
    "en": "2017-Currently"
  },
  "WORK_DETAILS_1_SCOPE": {
    "es": "Android Development",
    "en": "Android Development"
  },
  "WORK_DETAIL_FIRST_1_TEXT": {
    "es": "DHIS2 es un software de código abierto que permite monitorear, gestionar y hacer una correcta evaluación de toda la información médica que se aplica en los distintos programas de salud.",
    "en": "DHIS2 is an open source software that allows to monitor, manage and make a correct evaluation of all the medical information that is applied in the different health programs."
  },
  "WORK_DETAIL_SECOND_1_TEXT": {
    "es": "Esta app permite a profesionales de la salud en más de 67 países almacenar y analizar datos en móviles y tablets Android de una manera más rápida, eficiente y segura.",
    "en": "This app allows health professionals in more than 67 countries to store and analyze data on Android mobiles and tablets in a faster, more efficient and secure way."
  },
  "WORK_DETAIL_THIRD_1_TEXT": {
    "es": "La mayoría de funcionalidades trabajan offline, para permitir el uso en condiciones de baja conectividad",
    "en": "Most solutions work-offline, enabling improved reach in locations with poor connectivity."
  },
  "WORK_DETAILS_2_TITLE": {
    "es": "Tecnología e innovación para la seguridad personal.",
    "en": "Innovation and techonology for the personal security."
  },
  "WORK_DETAILS_2_CASE": {
    "es": "La app de seguridad personal más completa. Graba y almacena en la nube vídeos y posición, incluso con la pantalla bloqueada. ",
    "en": "The most complete personal security app. Rercords and store in cloud videos and position, even in background or blocked. "
  },
  "WORK_DETAILS_2_CLIENT": {
    "es": "WBunker",
    "en": "WBunker"
  },
  "WORK_DETAILS_2_YEAR": {
    "es": "2014-Actualmente",
    "en": "2014-Currently"
  },
  "WORK_DETAILS_2_SCOPE": {
    "es": "UX/UI<br>Android Development<br>iOS Development<br>Backend Development<br>IT",
    "en": "UX/UI<br>Android Development<br>iOS Development<br>Backend Development<br>IT"
  },
  "WORK_DETAIL_FIRST_2_TEXT": {
    "es": "No es necesario desbloquear el teléfono, se puede activar una alerta de inmediato. El teléfono comenzará a grabar video y a geolocalizarte, todo con el dispositivo bloqueado y sin que nadie de alrededor lo sepa.",
    "en": "No need to unlock the phone, an alert can be triggered right away. The phone will start recording video and geolocating you, all with the device locked and without anyone around you knowing."
  },
  "WORK_DETAIL_SECOND_2_TEXT": {
    "es": "Rápida, eficaz y segura. Solo 5 clics en el botón de encendido y se activará la alerta.",
    "en": "Fast. Efective. Safe. Just 5 clicks on on/off button and the alert will be triggered."
  },
  "WORK_DETAIL_THIRD_2_TEXT": {
    "es": "Capaz de enviar vídeo, audio y posición GPS, incluso sin cobertura.",
    "en": "It records video, audio and GPS location, even offline."
  },
  "WORK_DETAILS_3_TITLE": {
    "es": "Aplicación para conductores y asegurados",
    "en": "App for drivers and insured customers"
  },
  "WORK_DETAILS_3_CASE": {
    "es": "Desarrollo y mantenimiento de las apps móviles",
    "en": "Mobile Apps Development and Maintenance"
  },
  "WORK_DETAILS_3_CLIENT": {
    "es": "Direct Seguros",
    "en": "Direct Seguros"
  },
  "WORK_DETAILS_3_YEAR": {
    "es": "2014-Actualmente",
    "en": "2014-Currently"
  },
  "WORK_DETAILS_3_SCOPE": {
    "es": "UX/UI<br>Android Development<br>iOS Development",
    "en": "UX/UI<br>Android Development<br>iOS Development"
  },
  "WORK_DETAIL_FIRST_3_TEXT": {
    "es": "Se desarrollan funcionalidades destinadas a mejorar el día a día del conductor. Desde recordarle dónde ha aparcado hasta controles y avisos de velocidad permitida en sus trayectos.",
    "en": "Features are developed to improve the driver's day-to-day life. From reminding you where you have parked to speed controls and warnings on your journeys."
  },
  "WORK_DETAIL_SECOND_3_TEXT": {
    "es": "Permite dar un parte a la compañía en el momento del accidente desde el móvil",
    "en": "Users can send an accident report in a few clicks from the smartphone."
  },
  "WORK_DETAIL_THIRD_3_TEXT": {
    "es": "Contiene funcionalidades exclusivas para clientes de la compañía. Podrán consultar documentación y tramitar gestiones específicas sobre su seguro.",
    "en": "It contains exclusive functionalities for company clients. They will be able to consult documentation and process specific procedures regarding their insurance."
  },
  "WORK_DETAILS_4_TITLE": {
    "es": "Trust You, gestión de las reseñas",
    "en": "Trust You, feedback management"
  },
  "WORK_DETAILS_4_CASE": {
    "es": "La app que hace que escuchar el feedback de los clientes sea más fácil y potente",
    "en": "The app that makes listening customers feedback easier and more powerful"
  },
  "WORK_DETAILS_4_CLIENT": {
    "es": "TrustYou",
    "en": "TrustYou"
  },
  "WORK_DETAILS_4_YEAR": {
    "es": "2019-Actualmente",
    "en": "2019-Currently"
  },
  "WORK_DETAILS_4_SCOPE": {
    "es": "Desarrollo React Native",
    "en": "React Native Development"
  },
  "WORK_DETAIL_FIRST_4_TEXT": {
    "es": "Con la app de TrustYou ayudamos a la industria hotelera a recopilar, entender y compartir información y feedback del cliente gracias al poder de escuchar. Cuando las empresas escuchan a sus clientes, generan confianza, toman mejores decisiones y, en definitiva, ¡ganan!",
    "en": "With the TrustYou app we help the hotel industry to collect, understand and share information and customer feedback thanks to the power of listening. When companies listen to their customers, they build trust, make better decisions, and ultimately, they win! "
  },
  "WORK_DETAIL_SECOND_4_TEXT": {
    "es": "Además, con TrustYou los hoteles pueden publicar encuestas en línea en cientos de sitios de reserva a la vez.",
    "en": "With TrustYou, when you publish survey reviews online, they are made visible to millions of travelers, on hundreds of global booking sites, impacting future booking decisions."
  },
  "WORK_DETAIL_THIRD_4_TEXT": {
    "es": "La mejor app de gestión de reputación on-line.",
    "en": "The best online reputation management app."
  },
  "WORK_DETAILS_5_TITLE": {
    "es": "Trive",
    "en": "Trive"
  },
  "WORK_DETAILS_5_CASE": {
    "es": "Trive es la app para descubrir, probar y comprar un coche nuevo sin salir de casa",
    "en": "Trive is the ecommerce app for discovering, testing and buying a new car without leaving home."
  },
  "WORK_DETAILS_5_CLIENT": {
    "es": "B4 Motion",
    "en": "B4 Motion"
  },
  "WORK_DETAILS_5_YEAR": {
    "es": "2016-2018",
    "en": "2016-2018"
  },
  "WORK_DETAILS_5_SCOPE": {
    "es": "UX/UI<br>Android Development<br>iOS Development",
    "en": "UX/UI<br>Android Development<br>iOS Development"
  },
  "WORK_DETAIL_FIRST_5_TEXT": {
    "es": "La primera app que permite comprar un coche desde el móvil",
    "en": "The first app that lets users to buy a brand new car from their smartphones."
  },
  "WORK_DETAIL_SECOND_5_TEXT": {
    "es": "Los usuarios pueden recorrer el catálogo y solicitar una prueba de conducción a domicilio",
    "en": "Users can browse the catalog and book a test drive, delivered to their homes."
  },
  "WORK_DETAIL_THIRD_5_TEXT": {
    "es": "Permite conectar con el equipo de Trive mediante videollamada para visitar el concesionario sin moverse de casa. Podrás ver las prestaciones, características y acabados de cada coche siempre con el soporte y guía de un profesional.",
    "en": "It allows you to connect with the Trive team by video call to visit the dealership without leaving your home. You will be able to see the benefits, characteristics and finishes of each car always with the support and guidance of a professional."
  },
  "TEAM_ABOUT_TITLE": {
    "es": "Nosotros",
    "en": "About us"
  },
  "TEAM_ABOUT_TEXT": {
    "es": "<p>Hace 10 años en Madrid, personas desarrolladoras, diseñadoras, analizadoras, soñadoras, creadoras, a veces DJ, a veces cocineras, nos juntamos para ayudar a las empresas a desarrollar las mejores experiencias digitales.</p><br>" +
      "<p>Quadram es la suma de las personas que la forman. Estamos convencidas de que partiendo del respeto y la calidad humana, todo es posible. Por eso, nos esforzamos cada día para seguir aprendiendo y evolucionando, manteniendo la calidad de vida de todas las personas del equipo.</p>",
    "en": "<p>10 years ago in Madrid, developers, designers, analysts, dreamers, creators, sometimes DJs, sometimes cooks, got together to help companies develop the best digital experiences.</p><br>" +
      "Quadram is the sum of the people that form it. We are convinced that starting from respect and human quality, everything is possible. For this reason, we strive every day to continue learning and evolving, maintaining the quality of life of all the people on the team."
  },
  "TEAM_VALUES": {
    "es": "NUESTROS VALORES",
    "en": "OUR VALUES"
  },
  "TEAM_VALUES_VALUE_1_TITLE": {
    "es": "CONCILIACIÓN POWER",
    "en": "CONCILIATION POWER"
  },
  "TEAM_VALUES_VALUE_1_DESCRIPTION": {
    "es": "Lo más importante es el equipo, así que hacemos lo posible para que la conciliación familiar sea lo mejor posible, creando políticas personalizadas para cada miembro del equipo. ",
    "en": "Team is the key, so we do our best for the conciliation of professional and familiar lives. We create comprehensive and personalized policies for our teammates.  "
  },
  "TEAM_VALUES_VALUE_2_TITLE": {
    "es": "NO OFFICE IS THE NEW OFFICE",
    "en": "NO OFFICE IS THE NEW OFFICE"
  },
  "TEAM_VALUES_VALUE_2_DESCRIPTION": {
    "es": "Creemos firmemente que no es necesario estar físicamente en un sitio determinado para hacer un buen trabajo. Por eso, apostamos por el trabajo en remoto. ",
    "en": "We strongly belive that we don´t need physical presence to perfeormance a great job. That´s why we embrace remote working. "
  },
  "TEAM_VALUES_VALUE_3_TITLE": {
    "es": "WE ARE THE WORLD",
    "en": "WE ARE THE WORLD"
  },
  "TEAM_VALUES_VALUE_3_DESCRIPTION": {
    "es": "Queremos contar con los mejores profesionales sin ponernos barreras. Somos 100% digitales. ",
    "en": "We want to have the best professionals and persons in our team, with no barriers. We are 100% digital."
  },
  "TEAM_VALUES_VALUE_4_TITLE": {
    "es": "I+D Y SUMA Y SIGUE",
    "en": "I+D AND CARRY FORWARD"
  },
  "TEAM_VALUES_VALUE_4_DESCRIPTION": {
    "es": "Creemos en el aprendizaje de por vida! Tenemos claro que dentro de  unos años el mundo y la tecnología serán muy diferentes a los de hoy, por eso seguimos investigando y evolucionando con el mercado. ",
    "en": "We embrace lifelong learning. We understand that the world and technology are constantly changing, and aim to get out and learn from others in the industry as much as possible."
  },
  "TEAM_VALUES_VALUE_5_TITLE": {
    "es": "HAPPY HOURS, OH YEAH",
    "en": "HAPPY HOURS, OH YEAH"
  },
  "TEAM_VALUES_VALUE_5_DESCRIPTION": {
    "es": "No podemos ocultar que se nos dan muy bien las fiestas. Nos sentimos afortunados de poder pasar muy buenos ratos juntos. Contamos con personas, no con recursos. ",
    "en": "We cannot hide that we are very good organising parties. We feel very proud of being able to spend great moments together. We work with persons, not with resources."
  },
  "TEAM_JOIN_US_SECTION_NAME": {
    "es": "POSICIONES ABIERTAS",
    "en": "OPEN POSITIONS"
  },
  "TEAM_JOIN_US_TITLE": {
    "es": "Queremos trabajar con gente como tú.<br>Posiciones abiertas",
    "en": "We want work with peope like you. <br/>Open positions"
  },
  "TEAM_JOIN_US_CAREER_1_TITLE": {
    "es": "ANDROID DEVELOPER",
    "en": "ANDROID DEVELOPER"
  },
  "TEAM_JOIN_US_CAREER_2_TITLE": {
    "es": "iOS DEVELOPER",
    "en": "iOS DEVELOPER"
  },
  "TEAM_JOIN_US_CAREER_3_TITLE": {
    "es": "FULL STACK DEVELOPER",
    "en": "FULL STACK DEVELOPER"
  },
  "TEAM_JOIN_US_CAREER_4_TITLE": {
    "es": "UX/UI DESIGNER",
    "en": "UX/UI DESIGNER"
  },
  "TEAM_JOIN_US_CAREER_COMMON_DESCRIPTION": {
    "es": "Si te apasiona desarrollar aplicaciones móviles, definir, construir, lanzar y evolucionar productos 100% mobile, has llegado en el momento oportuno.<br/><br/> QUADRAM es tu sitio:<br><br>+ de 10 años desarrollando apps 💪<br>+ de 800 apps desarrolladas in house 🚀 <br/><br/>Somos un equipo multidisciplinar en un entorno de trabajo joven, que hace todo lo posible por disfrutar trabajando. Cualquier proyecto al que nos enfrentamos siempre tiene algo nuevo que nos sorprende y fomenta nuestra curiosidad. ¿Quién dijo que aprender era aburrido?",
    "en": "If you're passionate about develop mobile apps, define, build, launch and evolve 100% mobile products, you've come at the right time.<br/><br/>QUADRAM is your place:<br/><br/>+ of 10 years developing apps 💪<br/>+ of 800 developed apps in house 🚀<br/><br/>We are a multidisciplinary team in a young environment, which does everything possible to enjoy working. Any project we face always have some new that surprises us and encourages our curiosity. Who said learning was boring?"
  },
  "TEAM_JOIN_US_WHAT_WE_OFFER_TITLE": {
    "es": "¿Qué ofrecemos?",
    "en": "What we offer?"
  },
  "TEAM_JOIN_US_WHAT_WE_OFFER_DESCRIPTION": {
    "es": "<ul><li>Contratación indefinida desde el minuto 1.</li><li>Teletrabajo.</li><li>Horario flexible y jornada intensiva todos los viernes del año. Y ¡vaya, aquí no hay playa! Pero tenemos jornada intensiva en verano ☀️</li><li>Creemos en la formación adaptada a tus necesidades y a las de los proyectos. Cualquier inquietud que tengas, no dudes en decírnoslo 🙂</li><li>La oficina. Utilizamos una oficina virtual web. Trabajo en remoto pero ¡no perdemos el buen ambiente!</li></ul>",
    "en": "<ul><li>Indefinite hiring from minute 1.</li><li>Teleworking.</li><li>Flexible schedule and intensive day every Friday of the year. And ¡wow, no beach here! But we've an intensive day in Summer ☀️</li><li>We believe in the tailored training for your needs and the needs of the projects. Any curiosity that you have, don't hesitate to tell us 🙂</li></ul>"
  },
  "TEAM_JOIN_US_LOOKING_FOR_TITLE": {
    "es": "¿Qué buscamos?",
    "en": "What are we looking for?"
  },
  "TEAM_JOIN_US_EXTRA_TITLE": {
    "es": "Como bonus...",
    "en": "As a bonus..."
  },
  "TEAM_JOIN_US_LOOKING_FOR_DESCRIPTION_1": {
    "es": "<ul><li>Que seas inquieta o inquieto.</li><li>Dominio Android Studio y de Kotlin.</li><li>Conocimientos de patrones de diseño (MVP, MVVM...)</li><li>Eres amante de las buenas prácticas y principios SOLID.</li><li>Conexión con servicios REST de manera nativa y conocimiento de Retrofit.</li><li>Conocimientos de inyeccion de dependencias, DataBinding, LiveData.</li><li>Conocimientos de Retrofit.</li><li>Has trabajado con Git.</li><li>Conocimiento y experiencia con librerías y frameworks nativos y de terceros (mapas, push, pagos inapp o medios de pago,...).</li><li>Familiarizado con el proceso de publicación de apps en Google Play.</li><li>Te gusta salir habitualmente de la zona de confort e investigar y probar nuevas soluciones.</li><li>Que te apetezca trabajar en proyectos variados para grandes y pequeñas empresas.</li><li>Que adquieras independencia en tu trabajo diario. Todo poder conlleva una responsabilidad.</li><li>Que tengas capacidad analítica y atención al detalle.</li><li>Que tengas sentido del humor y calidad humana. Creemos en la importancia de los valores y el encaje de las personas en el equipo, incluso por encima de los conocimientos técnicos previos.</li><li>Que valores el trabajo en equipo. Enseñar y que te enseñen, ayudar y que te ayuden.</li><li>Que tengas inquietud por salir de la zona de confort habitualmente, e investigar y probar nuevas soluciones. ¿Quién dijo miedo?</li></ul>",
    "en": "<ul><li>That you be inquisitive.</li><li>Knowledge of Android Studio and Kotlin.</li><li>Knowledges of design patterns (MVP, MVVM...)</li><li>You're a lover of good practices and SOLID principles.</li><li>Connection with REST services natively and knowledge of Retrofit.</li><li>Knowledges of dependencies injection, DataBinding, LiveData.</li><li>Knowledges of Retrofit.</li><li>You've worked with Git</li><li>Knowledge and experience with native and third parties libraries and frameworks (maps, push, inapps payments and others payment methods,...)</li><li>Familiar with the process of publishing apps on Google Play.</li><li>You like to regularly step out of your comfort zone and investigate and try new solutions.</li><li>That you feel like work in varied projects for big and little business.</li><li>That you gain independence in your daily work. All power comes with a responsibility</li><li>That you've analytical skills and attention to detail.</li><li>That you've a sense of humour and human quality. We believe in the importance of values and the fit of people in the team, even beyond of the previous technical knowledges.</li><li>That you value the teamwork. Teach and to teach you, help and that help you.</li><li>That you have inquisitive to step out of your comfort zone and investigate and try new solutions. Who said fear?</li></ul>"
  },
  "TEAM_JOIN_US_LOOKING_FOR_DESCRIPTION_2": {
    es: "<ul><li>Que seas inquieta o inquieto.</li>\n<li>Que lleves al menos 1 ó 2 años trabajando con Objective C y Swift. Dominio de Swift y conocimientos de Objective C sería el mínimo.</li>\n<li>Experiencia en arranque de proyectos desde cero, tanto con Storyboard como únicamente con XIB.</li>\n<li>Experiencia con librerías gráficas, de animación y patrones de diseño (Autolayout, Storyboard, CoreGraphics, CoreAnimation...).</li>\n<li>Conexión con servicios REST de manera nativa y conocimiento de librerías (AFNetworking/Alamofire).</li>\n<li>Conocimientos Arquitectura MVC. Conocimientos en MVVM y Viper es un plus.</li>\n<li>Conocimiento y experiencia con librerías y frameworks nativos y de terceros (mapas, gestor de conexiones, push, pagos inapp o medios de pago...).</li><li>Has trabajado con Git.</li><li>Familiarizado con el proceso de publicación de apps en App Store.</li>\n<li>Que te apetezca trabajar en proyectos variados para grandes y pequeñas empresas.</li>\n<li>Que adquieras independencia en tu trabajo diario. Todo poder conlleva una responsabilidad.</li>\n<li>Que tengas capacidad analítica y atención al detalle.</li>\n<li>Que tengas sentido del humor y calidad humana. Creemos en la importancia de los valores y el encaje de las personas en el equipo, incluso por encima de los conocimientos técnicos previos.</li>\n<li>Que valores el trabajo en equipo. Enseñar y que te enseñen, ayudar y que te ayuden.</li>\n<li>Que tengas inquietud por salir de la zona de confort habitualmente, e investigar y probar nuevas soluciones. ¿Quién dijo miedo?</li></ul>",
    en: "<ul><li>That you be inquisitive.</li><li>That you've working for 1 or 2 years with Objective C and Swift. Command of Swift and knowledge of Objective C are the minimum.</li><li>Experience starting projects since zero, with Storyboard as with only XIB.</li><li>Grapics and animations libraries and design patterns experience (Autolayout, Storyboard, CoreGraphics, CoreAnimation...).</li><li>Connection with REST services natively and knowledge of libraries (AFNetworking/Alamofire).</li><li>Knowledges of MVC Architecture. Knowledges in MVVM and Viper increments your posibilities.</li><li>Knowledges and experience with native and third parties libraries and frameworks (maps, push, inapps payments and others payment methods...).</li><li>You've worked with Git.</li><li>Familiar with the process of publishing apps on App Store.</li><li>That you feel like work in varied projects for big and little business</li><li>That you gain independence in your daily work. All power comes with a responsibility.</li><li>That you've analytical skills and attention to detail.</li><li>That you've a sense of humour and human quality. We believe in the importance of values and the fit of people in the team, even beyond of the previous technical knowledges.</li><li>That you value the teamwork. Teach and to teach you, help and that help you.</li><li>That you have inquisitive to step out of your comfort zone and investigate and try new solutions. Who said fear?</li></ul>"
  },
  "TEAM_JOIN_US_LOOKING_FOR_DESCRIPTION_3": {
    "es": "<ul><li>Que seas inquieta o inquieto.</li><li>Que tengas el botón de productividad modo ON.</li><li>Que lleves 2 ó 3 años trabajando.</li><li>Que controles PHP y Laravel.</li><li> Que conozcas bien React Native.</li><li>Que te manejes con REST, Git y TDD.</li><li>Que tengas conocimientos de patrones de diseño (MVP, MVVM...)</li><li>Que te apetezca trabajar en proyectos variados para grandes empresas y startups.</li><li>Que adquieras independencia en tu trabajo diario. Todo poder conlleva una responsabilidad.</li><li>Que tengas sentido del humor y calidad humana. Creemos en la importancia de los valores y el encaje de las personas en el equipo, incluso por encima de los conocimientos técnicos previos.</li><li>Que valores el trabajo en equipo. Enseñar y que te enseñen, ayudar y que te ayuden.</li><li>Que tengas inquietud por salir de la zona de confort habitualmente, e investigar y probar nuevas soluciones. ¿Quién dijo miedo?</ul>",
    "en": "<ul><li>That you be inquisitive.</li><li>That you have the productivity button ON.</li><li>That you've been working for 2 or 3 years.</li><li>That you know how to use PHP and Laravel.</li><li>That you know React Native.</li><li>That you know how to use REST, Git and TDD.</li><li>That you've knowledges about design patterns (MVP, MVVM...)</li><li>That you feel like work in varied projects for big and little business.</li><li>That you gain independence in your daily work. All power comes with a responsibility.</li><li>That you've a sense of humour and human quality. We believe in the importance of values and the fit of people in the team, even beyond of the previous technical knowledges.</li><li>That you value the teamwork. Teach and to teach you, help and that help you.</li><li>That you have inquisitive to step out of your comfort zone and investigate and try new solutions. Who said fear?</li></ul>"
  },
  "TEAM_JOIN_US_LOOKING_FOR_DESCRIPTION_4": {
    "es": "<ul><li>Que seas inquieta o inquieto.</li><li>Que tengas el botón de creatividad modo ON.</li><li>Que no seas Marie Kondo pero valores la importancia del orden trabajando en equipo.</li><li>Que lleves 1 o 2 años trabajando.</li><li>Que tengas experiencia en diseño y prototipado de aplicaciones iOS & Android y Web responsive.</li><li>Que Figma (o Sketch) sea tu gran aliado. Adiós Adobe (aunque a veces nos sigue salvando la vida)</li><li>Que te apetezca trabajar en proyectos variados para grandes y pequeñas empresas y que sepas adaptar el diseño acorde a cada circunstancia.</li><li>Que adquieras independencia en tu trabajo diario. Todo poder conlleva una responsabilidad.</li><li>Que tengas capacidad analítica y atención al detalle. Lo llaman el TOC del diseñador.</li><li>Que tengas sentido del humor y calidad humana. Creemos en la importancia de los valores y el encaje de las personas en el equipo, incluso por encima de los conocimientos técnicos previos.</li><li>Que valores el trabajo en equipo. Enseñar y que te enseñen, ayudar y que te ayuden.</li><li>Que tengas inquietud por salir de la zona de confort habitualmente, e investigar y probar nuevas soluciones. ¿Quién dijo miedo?</li></ul>",
    "en": "<ul><li>That you be inquisitive.</li><li>That you have the productivity button ON.</li><li>That you don't be Marie Kondo, but you values the importance of order working as a team.</li><li>That you have working for 1 or 2 years.</li><li>That you have design and prototyping experience in iOS & Android and Web responsive apps.</li><li>That Figma (or Sketch) be your main allied. Bye Adobe (although sometimes Adobe keep saving our lives)</li><li>That you want to work on various projects for big and little business and that you know adapt the design agree to each circumstance.</li><li>That you gain independence in your daily work. All power comes with a responsibility.</li><li>That you have  analityc capacity and attention to detail. They is called the OCD of the designer.</li><li>That you've a sense of humour and human quality. We believe in the importance of values and the fit of people in the team, even beyond of the previous technical knowledges.</li><li>That you value the teamwork. Teach and to teach you, help and that help you.</li><li>That you have inquisitive to step out of your comfort zone and investigate and try new solutions. Who said fear?</li></ul>"
  },
  "TEAM_JOIN_US_EXTRA_DESCRIPTION_1": {
    "es": "<ul><li>Has trabajado con Trello.</li><li>Has trabajado con Firebase.</li><li>Tienes conocimientos de coroutines, Koin, Dagger 2.</li><li>Tienes conocimientos de análisis de requisitos, integración continua, testing, etc.</li><li>De vez en cuando tengas que borrar apps por no tener espacio en el móvil. Friki te dirán, ¡¡pero es que son súper útiles!!.</ul>",
    "en": "<ul><li>You have worked with Trello.</li><li>You have worked with Firebase.</li><li>You have knowledges of coroutines, Koin, Dagger 2.</li><li>You have knowledges of requirements analysis, continuous integration, testing, etc.</li><li>From time to time, you've to delete apps because you don't have enough storage in your smartphone. Geek will tell you, ¡¡but the apps are very useful!!</li></ul>"
  },
  "TEAM_JOIN_US_EXTRA_DESCRIPTION_2": {
    "es": "<ul><li>Has trabajado con la librería PromiseKit.</li><li>Has trabajado con Trello.</li><li>Tienes conocimientos en SwiftUI.</li><li>Tienes conocimientos de análisis de requisitos, integración continua, testing, etc.</li><li>De vez en cuando tengas que borrar apps por no tener espacio en el móvil. Friki te dirán, ¡¡pero es que son súper útiles!!</li></ul>",
    "en": "<ul><li>You have worked with PromiseKit library.</li><li>You have worked with Trello.</li><li>You have knowledges of SwiftUI.</li><li>You have knowledges of requirements analysis, continuous integration, testing, etc.</li><li>From time to time, you've to delete apps because you don't have enough storage in your smartphone. Geek will tell you, ¡¡but the apps are very useful!!</li></ul>"
  },
  "TEAM_JOIN_US_EXTRA_DESCRIPTION_3": {
    "es": "<ul><li>Tienes conocimientos de análisis de requisitos, integración continua, testing, etc.</li><li>Has trabajado con Trello.</li><li>Has trabajado desarrollando el backend de apps. Y conoces su ciclo de vida.</li><li>Te suena VUE. Que no te suene muy a chino.</li></ul>",
    "en": "<ul><li>You have knowledge of requirements analysis, continuous integration, testing, etc.</li><li>You have worked with Trello.</li><li>You have worked developing the backend of apps. And you know it´s life cycle.</li><li>You've heard of Vue. Don't let it sound too strange.</li></ul>"
  },
  "TEAM_JOIN_US_EXTRA_DESCRIPTION_4": {
    "es": "<ul><li>Te suena Overflow o Figma.</li><li>Has trabajado con Trello.</li><li>Tienes conocimientos básicos de HTML5 y CSS3. Que no te suene muy a chino.</li><li>De vez en cuando tengas que borrar apps por no tener espacio en el móvil. Friki te dirán, ¡¡pero es que son súper útiles!!</li></ul>",
    "en": "<ul><li>Overflow or Figma are familiar to you.</li><li>You have worked with Trello.</li><li>You have basics knowledges about HTML5 and CSS3. Don't let it sound too strange.</li><li>From time to time, you've to delete apps because you don't have sufficient storage in your smartphone. Geek will tell you, ¡¡but the apps are very useful!!</li></ul>"
  },
  "TEAM_JOIN_US_BANNER_TITLE": {
    "es": "¿Te apuntas?",
    "en": "You sign up?"
  },
  "VENTURES_FAQ_TITLE": {
    "es": "Preguntas frecuentes",
    "en": "Frequently Asked Questions"
  },
  "VENTURES_FAQ_1_TITLE": {
    "es": "¿Qué es Quadram Ventures?",
    "en": "What is Quadram Ventures?"
  },
  "VENTURES_FAQ_1_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_2_TITLE": {
    "es": "¿Por qué Quadram Ventures<br>puede ser una alternativa para<br>mi startup? ",
    "en": ""
  },
  "VENTURES_FAQ_2_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_3_TITLE": {
    "es": "¿Quadram Ventures desarrolla la<br>aplicación a coste = 0 €?",
    "en": ""
  },
  "VENTURES_FAQ_3_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_4_TITLE": {
    "es": "¿Cómo puedo inscribirme en el<br>programa?",
    "en": ""
  },
  "VENTURES_FAQ_4_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_5_TITLE": {
    "es": "¿Tengo que tener constituida la<br>empresa? ",
    "en": ""
  },
  "VENTURES_FAQ_5_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_6_TITLE": {
    "es": "¿Es Quadram Ventures una<br>aceleradora?",
    "en": ""
  },
  "VENTURES_FAQ_6_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_7_TITLE": {
    "es": "¿Cuáles son nuestros criterios de<br>inversión?",
    "en": ""
  },
  "VENTURES_FAQ_7_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_8_TITLE": {
    "es": "¿Quadram Ventures invierte<br>dinero en mi startup?",
    "en": ""
  },
  "VENTURES_FAQ_8_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_9_TITLE": {
    "es": "¿Y si no quiero ceder equity de mi<br>empresa desde el principio?",
    "en": ""
  },
  "VENTURES_FAQ_9_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "VENTURES_FAQ_10_TITLE": {
    "es": "¿Con cuánto equity entra<br>Quadram Ventures en las<br>participadas?",
    "en": ""
  },
  "VENTURES_FAQ_10_DESCRIPTION": {
    "es": "Quadram Ventures es una iniciativa de Quadram para apoyar el<br>empendimiento. Es una nueva forma de inversión basada en el<br>modelo americano en el que unimos nuestra experiencia como<br>desarrolladores con el equipo fundador para lanzar startups de éxito<br>al mercado.",
    "en": ""
  },
  "CONTACT_SECTION_NAME": {
    "es": "¿Hablamos?",
    "en": "Do we talk?"
  },
  "CONTACT_TITLE": {
    "es": "Rellena el formulario.<br>Es fácil.",
    "en": "Let's fill the form.<br>It's easy."
  },
  "CONTACT_TEXT": {
    "es": "Cuéntanos brevemente sobre tu proyecto, tus objetivos actuales, si ya tienes una empresa, etc. ¿Cómo podemos ayudarte?",
    "en": "Give us a few details about your idea and we’ll have someone contact you shortly."
  },
  "CONTACT_NAME_PLACEHOLDER": {
    "es": "Nombre",
    "en": "Name"
  },
  "CONTACT_LASTNAME_PLACEHOLDER": {
    "es": "Apellidos",
    "en": "Last name"
  },
  "CONTACT_MAIL_PLACEHOLDER": {
    "es": "Email",
    "en": "Email"
  },
  "CONTACT_PHONE_PLACEHOLDER": {
    "es": "Teléfono",
    "en": "Phone"
  },
  "CONTACT_CONTENT_PLACEHOLDER": {
    "es": "Cuéntanos sobre tú proyecto",
    "en": "Tell us about your project"
  },
  "CONTACT_BUDGET_TEXT": {
    "es": "¿Cuánto presupuesto se cuenta para ponernos mano a la obra?",
    "en": "How much budget is counted to put ourselves to work?"
  },
  "OPTION_1_BUDGET": {
    "es": "El proyecto cuenta con un presupuesto de ...",
    "en": "The project has a budget of ..."
  },
  "OPTION_2_BUDGET": {
    "es": "Menos de 6.000 € al año",
    "en": "Less than € 6000 a year"
  },
  "OPTION_3_BUDGET": {
    "es": "Entre 6.000 € y 20.000 € al año",
    "en": "Between € 6000 and € 20000 a year"
  },
  "OPTION_4_BUDGET": {
    "es": "Entre 20.000 € y 50.000 € al año",
    "en": "Between € 20000 and € 50000 a year",
  },
  "OPTION_5_BUDGET": {
    "es": "Más de 50.000 € al año",
    "en": "More than € 50000 a year"
  },
  "OPTION_6_BUDGET": {
    "es": "Otro",
    "en": "Other"
  },
  "CONTACT_HOW_MEET_PLACEHOLDER": {
    "es": "¿Cómo nos has conocido?",
    "en": "How did you meet us?"
  },
  "CONTACT_PRE_TERMS_LINK": {
    "es": "He leído y acepto la ",
    "en": "I have read and accept the "
  },
  "CONTACT_TERMS_LINK": {
    "es": "Política de Privacidad",
    "en": "Privacy Policy",
  },
  "CONTACT_BUTTON": {
    "es": "Enviar mensaje",
    "en": "Send message"
  },
  'CONTACT_MESSAGE_SENT': {
    "es": "¡Gracias por la confianza!<br/>Qué ganas tenermos de leer tu correo.",
    "en": "Thank you for your trust!<br/>We look forward to reading your mail."
  },
  'CONTACT_MESSAGE_ERRORS': {
    "es": "Vaya! Necesitamos que revises todos<br/>los datos, por favor 🙏🏻",
    "en": "Ups! We need you to check all<br/> the data, please 🙏🏻"
  },
  "PRIVACY_TITLE": {
    "es": "POLÍTICA DE PRIVACIDAD<br>Condiciones de acceso y utilización de la WEB",
    "en": "POLÍTICA DE PRIVACIDAD<br>Condiciones de acceso y utilización de la WEB",
  },
  "COOKIES_TITLE": {
    "es": "POLÍTICA DE COOKIES ANALÍTICAS",
    "en": "POLÍTICA DE COOKIES ANALÍTICAS"
  },
  "PRIVACY_CONTENT": {
    "es": "<div class='title-text'>1. INFORMACIÓN AL USUARIO</div><br/><b>¿Quién es el responsable del tratamiento de tus datos personales?<br/>" +
      "QUADRAM QUALITY SERVICES, S.L. </b> es el RESPONSABLE del tratamiento de los datos personales del USUARIO y " +
      "le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, " +
      "de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).<br/><br/>" +
      "<b>¿Para qué tratamos tus datos personales?</b><br/>Para mantener una relación comercial con el usuario. Las operaciones " +
      "previstas para realizar el tratamiento son:" +
      "<ul><li>Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier " +
      "otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas " +
      "comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o de sus " +
      "colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este caso, los terceros " +
      "nunca tendrán acceso a los datos personales.</li>" +
      "<li>Realizar estudios de mercado y análisis estadísticos.</li><li>Tramitar encargos, solicitudes, dar respuesta a las " +
      "consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto " +
      "que se ponen a su disposición en la página web del RESPONSABLE.</li><li>Remitir el boletín informativo online, sobre novedades, " +
      "ofertas y promociones en nuestra actividad.</li></ul><br/><br/><b>¿Por qué motivo podemos tratar tus datos personales?</b><br/>" +
      "Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:<br/><ul><li>Con el consentimiento del " +
      "USUARIO: remisión de comunicaciones comerciales y del boletín informativo.</li><li>Por interés legítimo del RESPONSABLE: " +
      "realizar estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del USUARIO.</li></ul>" +
      "<br/><br/><b>¿Durante cuánto tiempo guardaremos tus datos personales?</b><br/>Se conservarán durante no más tiempo del necesario para " +
      "mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, " +
      "se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos." +
      "<br/><br/><b>¿A quién facilitamos tus datos personales?</b><br/>No está prevista ninguna comunicación de datos personales a terceros " +
      "salvo, si fuese necesario para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados " +
      "con comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos " +
      "por la normativa vigente de privacidad.<br/><br/><b>¿Cuáles son tus derechos?</b><br/>Los derechos que asisten al USUARIO son:<br/><ul>" +
      "<li>Derecho a retirar el consentimiento en cualquier momento.</li><li>Derecho de acceso, rectificación, portabilidad y supresión de sus " +
      "datos, y de limitación u oposición a su tratamiento.</li><li>Derecho a presentar una reclamación ante la autoridad de control " +
      "(<a href='https://www.aepd.es/es' target='https://www.aepd.es/es'>www.aepd.es</a>) si considera que el tratamiento no se ajusta a la normativa " +
      "vigente.</li></ul><br/><b>Datos de contacto para ejercer sus derechos:</b><br/>QUADRAM QUALITY SERVICES, S. L.. C/ Claudio Coello, 77 4 Planta - " +
      "28001 Madrid (Madrid). E-mail: info@quadram.mobi<br/><br/><br/>" +
      "<div class='title-text'>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO</div><br/>" +
      "Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en los campos, marcados con un asterisco (*) " +
      " en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos " +
      "son necesarios para atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos restantes. " +
      "El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y se hace responsable de comunicar cualquier " +
      "modificación de los mismos.<br/><br/>El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya " +
      "que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten todos los datos, no se garantiza " +
      "que la información y servicios facilitados sean completamente ajustados a sus necesidades.<br/><br/><br/>" +
      "<div class='title-text'>3. MEDIDAS DE SEGURIDAD</div><br/>" +
      "Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el RESPONSABLE está cumpliendo con todas " +
      "las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente " +
      "con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con " +
      "el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.<br/><br/>" +
      "El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que " +
      "establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS y les ha comunicado la información adecuada " +
      "para que puedan ejercerlos.<br/><br/>Para más información sobre las garantías de privacidad, puedes dirigirte <b>al</b> RESPONSABLE a través de " +
      "QUADRAM QUALITY SERVICES, S.L.. C/ Claudio Coello, 77 4 Planta - 28001 Madrid (Madrid). E-mail: info@quadram.mobi",
    "en": "<div class='title-text'>1. INFORMACIÓN AL USUARIO</div><br/><b>¿Quién es el responsable del tratamiento de tus datos personales?<br/>" +
      "QUADRAM QUALITY SERVICES, S.L. </b> es el RESPONSABLE del tratamiento de los datos personales del USUARIO y " +
      "le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, " +
      "de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).<br/><br/>" +
      "<b>¿Para qué tratamos tus datos personales?</b><br/>Para mantener una relación comercial con el usuario. Las operaciones " +
      "previstas para realizar el tratamiento son:" +
      "<ul><li>Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier " +
      "otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas " +
      "comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o de sus " +
      "colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este caso, los terceros " +
      "nunca tendrán acceso a los datos personales.</li>" +
      "<li>Realizar estudios de mercado y análisis estadísticos.</li><li>Tramitar encargos, solicitudes, dar respuesta a las " +
      "consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto " +
      "que se ponen a su disposición en la página web del RESPONSABLE.</li><li>Remitir el boletín informativo online, sobre novedades, " +
      "ofertas y promociones en nuestra actividad.</li></ul><br/><br/><b>¿Por qué motivo podemos tratar tus datos personales?</b><br/>" +
      "Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:<br/><ul><li>Con el consentimiento del " +
      "USUARIO: remisión de comunicaciones comerciales y del boletín informativo.</li><li>Por interés legítimo del RESPONSABLE: " +
      "realizar estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del USUARIO.</li></ul>" +
      "<br/><br/><b>¿Durante cuánto tiempo guardaremos tus datos personales?</b><br/>Se conservarán durante no más tiempo del necesario para " +
      "mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, " +
      "se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos." +
      "<br/><br/><b>¿A quién facilitamos tus datos personales?</b><br/>No está prevista ninguna comunicación de datos personales a terceros " +
      "salvo, si fuese necesario para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados " +
      "con comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos " +
      "por la normativa vigente de privacidad.<br/><br/><b>¿Cuáles son tus derechos?</b><br/>Los derechos que asisten al USUARIO son:<br/><ul>" +
      "<li>Derecho a retirar el consentimiento en cualquier momento.</li><li>Derecho de acceso, rectificación, portabilidad y supresión de sus " +
      "datos, y de limitación u oposición a su tratamiento.</li><li>Derecho a presentar una reclamación ante la autoridad de control " +
      "(<a href='https://www.aepd.es/es' target='https://www.aepd.es/es'>www.aepd.es</a>) si considera que el tratamiento no se ajusta a la normativa " +
      "vigente.</li></ul><br/><b>Datos de contacto para ejercer sus derechos:</b><br/>QUADRAM QUALITY SERVICES, S. L.. C/ Claudio Coello, 77 4 Planta - " +
      "28001 Madrid (Madrid). E-mail: info@quadram.mobi<br/><br/><br/>" +
      "<div class='title-text'>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO</div><br/>" +
      "Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en los campos, marcados con un asterisco (*) " +
      " en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos " +
      "son necesarios para atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos restantes. " +
      "El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y se hace responsable de comunicar cualquier " +
      "modificación de los mismos.<br/><br/>El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya " +
      "que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten todos los datos, no se garantiza " +
      "que la información y servicios facilitados sean completamente ajustados a sus necesidades.<br/><br/><br/>" +
      "<div class='title-text'>3. MEDIDAS DE SEGURIDAD</div><br/>" +
      "Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el RESPONSABLE está cumpliendo con todas " +
      "las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente " +
      "con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con " +
      "el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.<br/><br/>" +
      "El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que " +
      "establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS y les ha comunicado la información adecuada " +
      "para que puedan ejercerlos.<br/><br/>Para más información sobre las garantías de privacidad, puedes dirigirte <b>al</b> RESPONSABLE a través de " +
      "QUADRAM QUALITY SERVICES, S.L.. C/ Claudio Coello, 77 4 Planta - 28001 Madrid (Madrid). E-mail: info@quadram.mobi",
  },
  "COOKIES_CONTENT": {
    "es": "<div class='title-text'>INFORMACIÓN SOBRE COOKIES</div><br/>Las cookies y otras tecnologías similares tales como local shared objects, " +
      "flash <i>cookies</i> o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus " +
      "visitantes, así como para ofrecer un correcto funcionamiento del sitio.<br/><br/>Mediante el uso de estos dispositivos se permite al servidor " +
      "Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre " +
      "y contraseña, productos que más le interesan, etc.<br/><br/><br/>" +
      "<div class='title-text'><i>COOKIES</i> AFECTADAS POR LA NORMATIVA Y <i>COOKIES</i> EXCEPTUADAS</div><br/>Conforme a la << Ley de " +
      "Servicios de la Sociedad de la Información >> (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento " +
      "expreso del usuario de todas las páginas web que usan <i>cookies</i> prescindibles, antes de que este navegue por ellas.<br/><br/>" +
      "Según la directiva de la UE, las <i>cookies</i> que requieren el consentimiento informado por parte del usuario son las cookies de " +
      "analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del " +
      "sitio web o la prestación de servicios expresamente solicitados por el usuario.<br/><br/><br/>" +
      "<div class='title-text'>TIPOS DE <i>COOKIES</i></div><br/><b>SEGÚN LA FINALIDAD</b><ul><li><b><i>Cookies</i> técnicas y funcionales:</b> " +
      "son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes " +
      "opciones o servicios que en ella existan.</li><li><b><i>Cookies</i> analíticas:</b> son aquellas que permiten al responsable de las mismas " +
      "el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante " +
      "este tipo de <i>cookies</i> se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de " +
      "perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introduccir mejoras en función de análisis " +
      "de los datos de uso que hacen los usuarios del servicio.</li></ul><br/><br/><b>SEGÚN LA PROPIEDAD</b><br><ul><li><b><i>Cookies</i> propias:</b> " +
      "son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el " +
      "servicio solicitado por el usuario.</li><li><b><i>Cookies</i> de terceros:</b> son aquellas que se envían al equipo terminal del usuario desde " +
      "un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li></ul><br/><br/>" +
      "<b>SEGÚN EL PLAZO DE CONSERVACIÓN</b><br/><ul><li><b><i>Cookies</i> de sesión:</b> son un tipo de cookies diseñadas para recabar y " +
      "almacenar datos mientras el usuario accede a una página web.</li><li><b><i>Cookies</i> persistentes:</b> son un tipo de cookies en el que los " +
      "datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un período definido por el responsable de la <i>cookie</i>, " +
      "y que puede ir de unos minutos a varios años.</li></ul><br/><br/><br/>" +
      "<div class='title-text'>TRATAMIENTO DE DATOS PERSONALES</div><br/>QUADRAM QUALITY SERVICES, S.L. es el <b>Responsable del tratamiento</b> " +
      "de los datos personales del <b>Interesado</b> y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento " +
      "(UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la siguiente información del tratamiento: <br/><br/>" +
      "<b>Fines del tratamiento:</b> según se especifica en el apartado de cookies que se utilizan en este sitio web.<br/><br/>" +
      "<b>Legitimación del tratamiento:</b> por consentimiento del interesado (art. 6.1 GDPR).<br/><br/>" +
      "<b>Criterios de conservación de los datos:</b> según se especifica en el apartado de cookies utilizadas en la web.<br/><br/>" +
      "<b>Comunicación de los datos:</b> no se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por obligación legal.<br/><br/>" +
      "<b>Derechos que asisten al Interesado:</b><br/><ul><li>Derecho a retirar el consentimiento en cualquier momento.</li>" +
      "<li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición a su tratamiento.</li>" +
      "<li>Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.</li></ul>" +
      "<br/><br/><b>Datos de contacto para ejercer sus derechos:</b><br/>QUADRAM QUALITY SERVICES, S.L.. C/ Claudio Coello, 77 4 Planta - 28001 Madrid (Madrid). E-mail:" +
      "info@quadram.mobi<br/><br/><br/><div class='title-text'><i>COOKIES</i> UTILIZADAS EN ESTE SITIO WEB</div><br/><b>PANEL DE CONFIGURACIÓN</b><br/><br/>" +
      "<table class='table-cookies'><tr><th colspan='6'>COOKIES ANALÍTICAS</th></tr>" +
      "<tr><td>NOMBRE</td><td>TIPO</td><td>PROPIEDAD</td><td>FINALIDAD</td><td>PLAZO</td><td>OBSERVACIONES</td></tr>" +
      "<tr><td><b>_ga</b></td><td>Analítica</td><td>Google Analytics</td><td>Habilita la función de control de vistas únicas. La primera vez que un " +
      "usuario entre en el sitio web a través de un navegador se instalará esta <i>cookie</i>. Cuando este usuario vuelva a entrar en la web con el " +
      "mismo navegador, la <i>cookie</i> considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de navegador, se considerará otro usuario</td>" +
      "<td>2 años</td><td>Persistente</td></tr>" +
      "<tr><td><b>_gat</b></td><td>Analítica</td><td>Google Analytics</td><td>Se utiliza para limitar la velocidad de petición - la limitación de la " +
      "recogida de datos en los sitios de alto tráfico</td><td>10 minutos</td><td>Sesión</td></tr>" +
      "<tr><td><b>_gid</b></td><td>Analítica</td><td>Google Analytics</td><td>Se utiliza para distinguir a los usuarios</td><td>24 horas</td>" +
      "<td>Sesión</td></tr></table>" +
      "<div class='lista-cookies'>COOKIES ANALÍTICAS<ol><li>Nombre: <b>_ga</b><br/>Tipo: Analítica<br/>Propiedad: Google Analytics<br/>Finalidad: " +
      "Habilita la función de control de vistas únicas. La primera vez que un usuario entre en el sitio web a través de un navegador se instalará esta <i>cookie.</i> " +
      "Cuando este usuario vuelva a entrar en la web con el mismo navegador, la cookie considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de " +
      "navegador, se considerará otro usuario<br/>Plazo: 2 años<br/>Observaciones: Persistente</li><li>Nombre: <b>_gat</b><br/>Tipo: Analítica<br/>Propiedad: " +
      "Google Analytics<br/>Finalidad: Se utiliza para limitar la velocidad de petición - la limitación de la recogida de datos en los sitios de alto tráfico<br/>" +
      "Plazo: 10 minutos<br/>Observaciones: Sesión</li><li>Nombre: <b>_gid</b><br/>Tipo: Analítica<br/>Propiedad: Google Analytics<br/>Finalidad: " +
      "Se utiliza para distinguir a los usuarios<br/>Plazo: 24 horas<br/>Observaciones: Sesión</li></ol></div>" +
      "<br/><br/>Obtener más información para <b>deshabilitar las <i>cookies</i></b> de Google Analytics:<br/><br/><ul>" +
      "<li><a href='https://policies.google.com/technologies/cookies#managing-cookies' target='https://policies.google.com/technologies/cookies#managing-cookies'>" +
      "Política de privacidad de Google</a></li><li><a href='https://support.google.com/analytics/answer/181881?hl=es' target='https://support.google.com/analytics/answer/181881?hl=es'>" +
      "Exclusión de Google Analytics</a></li></ul><br/><b>COMO ELIMINAR LAS COOKIES DE LOS NAVEGADORES MÁS COMUNES</b><br/><br/>" +
      "<b>Chrome</b>: <a href='https://support.google.com/chrome/answer/95647?hl=es' target='https://support.google.com/chrome/answer/95647?hl=es'>Borrar, habilitar y administrar cookies en Chrome</a>" +
      "<br/><b>Internet Explorer. Versión 11: </b><a href='https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc' " +
      "target='https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc'>Cómo eliminar archivos de cookies en Internet Explorer</a>" +
      "<br/><b>Firefox. Versióm 65.0.1: </b><a href='https://www.mozilla.org/es-ES/privacy/websites/#cookies' target='https://www.mozilla.org/es-ES/privacy/websites/#cookies'>" +
      "Aviso de privacidad sobre sitios web, mensajes y cookies</a><br/><b>Safari Versión 5.1: </b><a href='https://support.apple.com/es-es/guide/safari/sfri11471/mac' target=" +
      "'https://support.apple.com/es-es/guide/safari/sfri11471/mac'>Gestionar cookies y datos de sitios web en Safari en el Mac</a>",

    "en": "<div class='title-text'>INFORMACIÓN SOBRE COOKIES</div><br/>Las cookies y otras tecnologías similares tales como local shared objects, " +
      "flash <i>cookies</i> o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus " +
      "visitantes, así como para ofrecer un correcto funcionamiento del sitio.<br/><br/>Mediante el uso de estos dispositivos se permite al servidor " +
      "Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre " +
      "y contraseña, productos que más le interesan, etc.<br/><br/><br/>" +
      "<div class='title-text'><i>COOKIES</i> AFECTADAS POR LA NORMATIVA Y <i>COOKIES</i> EXCEPTUADAS</div><br/>Conforme a la << Ley de " +
      "Servicios de la Sociedad de la Información >> (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento " +
      "expreso del usuario de todas las páginas web que usan <i>cookies</i> prescindibles, antes de que este navegue por ellas.<br/><br/>" +
      "Según la directiva de la UE, las <i>cookies</i> que requieren el consentimiento informado por parte del usuario son las cookies de " +
      "analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del " +
      "sitio web o la prestación de servicios expresamente solicitados por el usuario.<br/><br/><br/>" +
      "<div class='title-text'>TIPOS DE <i>COOKIES</i></div><br/><b>SEGÚN LA FINALIDAD</b><ul><li><b><i>Cookies</i> técnicas y funcionales:</b> " +
      "son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes " +
      "opciones o servicios que en ella existan.</li><li><b><i>Cookies</i> analíticas:</b> son aquellas que permiten al responsable de las mismas " +
      "el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante " +
      "este tipo de <i>cookies</i> se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de " +
      "perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introduccir mejoras en función de análisis " +
      "de los datos de uso que hacen los usuarios del servicio.</li></ul><br/><br/><b>SEGÚN LA PROPIEDAD</b><br><ul><li><b><i>Cookies</i> propias:</b> " +
      "son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el " +
      "servicio solicitado por el usuario.</li><li><b><i>Cookies</i> de terceros:</b> son aquellas que se envían al equipo terminal del usuario desde " +
      "un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li></ul><br/><br/>" +
      "<b>SEGÚN EL PLAZO DE CONSERVACIÓN</b><br/><ul><li><b><i>Cookies</i> de sesión:</b> son un tipo de cookies diseñadas para recabar y " +
      "almacenar datos mientras el usuario accede a una página web.</li><li><b><i>Cookies</i> persistentes:</b> son un tipo de cookies en el que los " +
      "datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un período definido por el responsable de la <i>cookie</i>, " +
      "y que puede ir de unos minutos a varios años.</li></ul><br/><br/><br/>" +
      "<div class='title-text'>TRATAMIENTO DE DATOS PERSONALES</div><br/>QUADRAM QUALITY SERVICES, S.L. es el <b>Responsable del tratamiento</b> " +
      "de los datos personales del <b>Interesado</b> y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento " +
      "(UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la siguiente información del tratamiento: <br/><br/>" +
      "<b>Fines del tratamiento:</b> según se especifica en el apartado de cookies que se utilizan en este sitio web.<br/><br/>" +
      "<b>Legitimación del tratamiento:</b> por consentimiento del interesado (art. 6.1 GDPR).<br/><br/>" +
      "<b>Criterios de conservación de los datos:</b> según se especifica en el apartado de cookies utilizadas en la web.<br/><br/>" +
      "<b>Comunicación de los datos:</b> no se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por obligación legal.<br/><br/>" +
      "<b>Derechos que asisten al Interesado:</b><br/><ul><li>Derecho a retirar el consentimiento en cualquier momento.</li>" +
      "<li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición a su tratamiento.</li>" +
      "<li>Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.</li></ul>" +
      "<br/><br/><b>Datos de contacto para ejercer sus derechos:</b><br/>QUADRAM QUALITY SERVICES, S.L.. C/ Claudio Coello, 77 4 Planta - 28001 Madrid (Madrid). E-mail:" +
      "info@quadram.mobi<br/><br/><br/><div class='title-text'><i>COOKIES</i> UTILIZADAS EN ESTE SITIO WEB</div><br/><b>PANEL DE CONFIGURACIÓN</b><br/><br/>" +
      "<table><tr><th colspan='6'>COOKIES ANALÍTICAS</th></tr>" +
      "<tr><td>NOMBRE</td><td>TIPO</td><td>PROPIEDAD</td><td>FINALIDAD</td><td>PLAZO</td><td>OBSERVACIONES</td></tr>" +
      "<tr><td><b>_ga</b></td><td>Analítica</td><td>Google Analytics</td><td>Habilita la función de control de vistas únicas. La primera vez que un " +
      "usuario entre en el sitio web a través de un navegador se instalará esta <i>cookie</i>. Cuando este usuario vuelva a entrar en la web con el " +
      "mismo navegador, la <i>cookie</i> considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de navegador, se considerará otro usuario</td>" +
      "<td>2 años</td><td>Persistente</td></tr>" +
      "<tr><td><b>_gat</b></td><td>Analítica</td><td>Google Analytics</td><td>Se utiliza para limitar la velocidad de petición - la limitación de la " +
      "recogida de datos en los sitios de alto tráfico</td><td>10 minutos</td><td>Sesión</td></tr>" +
      "<tr><td><b>_gid</b></td><td>Analítica</td><td>Google Analytics</td><td>Se utiliza para distinguir a los usuarios</td><td>24 horas</td>" +
      "<td>Sesión</td></tr></table><br/><br/>Obtener más información para <b>deshabilitar las <i>cookies</i></b> de Google Analytics:<br/><br/><ul>" +
      "<li><a href='https://policies.google.com/technologies/cookies#managing-cookies' target='https://policies.google.com/technologies/cookies#managing-cookies'>" +
      "Política de privacidad de Google</a></li><li><a href='https://support.google.com/analytics/answer/181881?hl=es' target='https://support.google.com/analytics/answer/181881?hl=es'>" +
      "Exclusión de Google Analytics</a></li></ul><br/><b>COMO ELIMINAR LAS COOKIES DE LOS NAVEGADORES MÁS COMUNES</b><br/><br/>" +
      "<b>Chrome</b>: <a href='https://support.google.com/chrome/answer/95647?hl=es' target='https://support.google.com/chrome/answer/95647?hl=es'>Borrar, habilitar y administrar cookies en Chrome</a>" +
      "<br/><b>Internet Explorer. Versión 11: </b><a href='https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc' " +
      "target='https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc'>Cómo eliminar archivos de cookies en Internet Explorer</a>" +
      "<br/><b>Firefox. Versióm 65.0.1: </b><a href='https://www.mozilla.org/es-ES/privacy/websites/#cookies' target='https://www.mozilla.org/es-ES/privacy/websites/#cookies'>" +
      "Aviso de privacidad sobre sitios web, mensajes y cookies</a><br/><b>Safari Versión 5.1: </b><a href='https://support.apple.com/es-es/guide/safari/sfri11471/mac' target=" +
      "'https://support.apple.com/es-es/guide/safari/sfri11471/mac'>Gestionar cookies y datos de sitios web en Safari en el Mac</a>"
  },
  "COOKIE_CONSENT_TITLE": {
    "es": "Aviso sobre cookies",
    "en": "Cookies used on the website!"
  },
  "COOKIE_CONSENT_TEXT": {
    "es": "Esta web utiliza cookies para asegurar una mejor experiencia de usuario",
    "en": "This website uses cookies to ensure you get the best experience on our website."
  },
  "COOKIE_CONSENT_DISMISS": {
    "es": "Acepto",
    "en": "Got it!"
  },
  "COOKIE_CONSENT_LEARN_MORE": {
    "es": "Saber más",
    "en": "Learn more"
  },
  'KIT_DIGITAL_TITLE': {
    "es": "Kit Digital",
    "en": "Kit Digital"
  },
  'KIT_DIGITAL_TITLE_CONTENT': {
    "es": "Quadram, Agente Digitalizador<br>Consigue ayudas para la digitalización de tu empresa.",
    "en": "Quadram, Agente Digitalizador<br>Consigue ayudas para la digitalización de tu empresa."
  },
  "KIT_DIGITAL_TITLE_WORK": {
    "es": "¿Qué es el Kit Digital?",
    "en": "¿Qué es el Kit Digital?"
  },
  "KIT_DIGITAL_CONTENT_WORK": {
    "es": "El Kit Digital es un programa dirigido a pymes y autónomos, del Ministerio de Asuntos Económicos y Transformación Digital, " +
      "que les acompaña en su proceso de transformación digital. Para que gracias a la tecnología puedan evolucionar y mejorar su negocio.",
    "en": "El Kit Digital es un programa dirigido a pymes y autónomos, del Ministerio de Asuntos Económicos y Transformación Digital, " +
      "que les acompaña en su proceso de transformación digital. Para que gracias a la tecnología puedan evolucionar y mejorar su negocio."
  },
  "KIT_DIGITAL_TITLE_DESTINATION": {
    "es": "¿A quién va dirigido?",
    "en": "¿A quién va dirigido?"
  },
  "KIT_DIGITAL_CONTENT_DESTINATION": {
    "es": "<p>Las soluciones digitales que proporciona el Kit Digital están orientadas a las necesidades de las pequeñas empresas, microempresas y trabajadores autónomos, que pertenezcan a cualquier sector o tipología de negocio.</p>" +
      "<p>La orden de bases establece tres segmentos de beneficiarios por número de empleados:</p><ol><li>Segmento I Pequeñas empresas de entre 10 y menos de 50 empleados.</li><li>Segmento II Pequeñas empresas o Microempresas de entre " +
      "3 y menos de 10 empleados.</li><li>Segmento III Pequeñas empresas o Microempresas de entre 0 y menos de 3 empleados.</li></ol><p>El importe de las ayudas que se concedan mediante las correspondientes resoluciones debidamente " +
      "notificadas a los beneficiarios, cuyo derecho al cobro se denomina «bono digital», será de hasta:</p><ul><li>12.000 euros para el Segmento I</li><li>6.000 euros para el Segmento II</li><li>2.000 euros para el Segmento III</li></ul>",
    "en": "<p>Las soluciones digitales que proporciona el Kit Digital están orientadas a las necesidades de las pequeñas empresas, microempresas y trabajadores autónomos, que pertenezcan a cualquier sector o tipología de negocio.</p>" +
      "<p>La orden de bases establece tres segmentos de beneficiarios por número de empleados:</p><ol><li>Segmento I Pequeñas empresas de entre 10 y menos de 50 empleados.</li><li>Segmento II Pequeñas empresas o Microempresas de entre " +
      "3 y menos de 10 empleados.</li><li>Segmento III Pequeñas empresas o Microempresas de entre 0 y menos de 3 empleados.</li></ol><p>El importe de las ayudas que se concedan mediante las correspondientes resoluciones debidamente " +
      "notificadas a los beneficiarios, cuyo derecho al cobro se denomina «bono digital», será de hasta:</p><ul><li>12.000 euros para el Segmento I</li><li>6.000 euros para el Segmento II</li><li>2.000 euros para el Segmento III</li></ul>"
  },
  "KIT_DIGITAL_TITLE_SOLUTIONS": {
    "es": "¿En qué soluciones podemos ayudarte desde Quadram?",
    "en": "¿En qué soluciones podemos ayudarte desde Quadram?"
  },
  "KIT_DIGITAL_SOLUTION_WEBSITE_TITLE": {
    "es": "Sitio Web y Presencia en Internet.",
    "en": "Sitio Web y Presencia en Internet."
  },
  "KIT_DIGITAL_SOLUTION_WEBSITE_LIST": {
    "es": "<ul><li>Gestión de la contratación de dominio y hosting.</li><li>Diseño de la página web.</li><li>Estructura de la web hasta con 3 apartados.</li>" +
      "<li>Web responsive.</li><li>Cumplimiento criterios de Accesibilidad.</li><li>Posicionamiento básico en internet.</li><li>Autogestionable.</li>" +
      "<li>Optimización de la presencia en buscadores (SEO básico).</li></ul>",
    "en": "<ul><li>Gestión de la contratación de dominio y hosting.</li><li>Diseño de la página web.</li><li>Estructura de la web hasta con 3 apartados.</li>" +
      "<li>Web responsive.</li><li>Cumplimiento criterios de Accesibilidad.</li><li>Posicionamiento básico en internet.</li><li>Autogestionable.</li>" +
      "<li>Optimización de la presencia en buscadores (SEO básico).</li></ul>"
  },
  "KIT_DIGITAL_SOLUTION_AMOUNT": {
    "es": "Importe de la ayuda:<br>Ayuda máxima 2.000€",
    "en": "Importe de la ayuda:<br>Ayuda máxima 2.000€"
  },
  "KIT_DIGITAL_SEGMENTS_TABLE": {
    "es": "<table><tr><th>Segmento III 0 < 3 empleados</th><th>Segmento II 3 < 9 empleados</th><th>Segmento I 10 < 50 empleados</th></tr>" +
      "<tr><td>2.000 €</td><td>2.000 €</td><td>2.000 €</td></tr></table>",
    "en": "<table><tr><th>Segmento III 0 < 3 empleados</th><th>Segmento II 3 < 9 empleados</th><th>Segmento I 10 < 50 empleados</th></tr>" +
      "<tr><td>2.000 €</td><td>2.000 €</td><td>2.000 €</td></tr></table>"
  },
  "KIT_DIGITAL_SEGMENTS_LIST": {
    "es": "<ul><li>Segmento III 0 < 3 empleados: 2.000 €</li><li>Segmento III 0 < 3 empleados: 2.000 €</li><li>Segmento III 0 < 3 empleados: 2.000 €</li></ul>"
  },
  "KIT_DIGITAL_SOLUTION_WEBSITE_INFO": {
    "es": "Más información <a href='https://www.acelerapyme.gob.es/kit-digital/sitio-web' target='_blank'>aquí</a>",
    "en": "Más información <a href='https://www.acelerapyme.gob.es/kit-digital/sitio-web' target='_blank'>aquí</a>"
  },
  "KIT_DIGITAL_SOLUTION_ECOMMERCE_TITLE": {
    "es": "Comercio Electrónico.",
    "en": "Comercio Electrónico."
  },
  "KIT_DIGITAL_SOLUTION_ECOMMERCE_LIST": {
    "es": "<ul><li>Creación de la tienda online o E-Commerce y alta del catálogo de productos.</li><li>Métodos de pago.</li><li>Diseño responsive.</li>" +
      "<li>Cumplimiento criterios de Accesibilidad.</li><li>Posicionamiento básico en internet.</li><li>Autogestionable.</li><li>Formas de envío</li></ul>",
    "en": "<ul><li>Creación de la tienda online o E-Commerce y alta del catálogo de productos.</li><li>Métodos de pago.</li><li>Diseño responsive.</li>" +
      "<li>Cumplimiento criterios de Accesibilidad.</li><li>Posicionamiento básico en internet.</li><li>Autogestionable.</li><li>Formas de envío</li></ul>"
  },
  "KIT_DIGITAL_SOLUTION_ECOMMERCE_INFO": {
    "es": "Más información <a href='https://www.acelerapyme.gob.es/kit-digital/comercio-electronico' target='_blank'>aquí</a>",
    "en": "Más información <a href='https://www.acelerapyme.gob.es/kit-digital/comercio-electronico' target='_blank'>aquí</a>"
  },
  "KIT_DIGITAL_ANALYTICS_TITLE": {
    "es": "Business Intelligence y Analítica.",
    "en": "Business Intelligence y Analítica."
  },
  "KIT_DIGITAL_ANALYTICS_LIST": {
    "es": "<ul><li>Asesoría y configuración de las herramientas de analíticas de tu web o app (Firebase, Crashlytics, Analytics, etc.).</li></ul>",
    "en": "<ul><li>Asesoría y configuración de las herramientas de analíticas de tu web o app (Firebase, Crashlytics, Analytics, etc.).</li></ul>"
  },
  "KIT_DIGITAL_ANALYTICS_AMOUNT": {
    "es": "Importe de la ayuda: Ayuda máxima 4.000€",
    "en": "Importe de la ayuda: Ayuda máxima 4.000€"
  },
  "KIT_DIGITAL_JOIN_US": {
    "es": "Únete a la Transformación Digital",
    "en": "Únete a la Transformación Digital"
  },
};


export default {
  copies
};