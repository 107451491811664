<template>
	<div id="work-main">
		<div id="work-title-component" :class="customTheme">
			<!-- This HTML is showed if the width of the device is plus than 700px -->
			<div :class="customClass">
				<div class="work-details-main-title">
					<p>{{translateCopy(`HOME_WORKS_${id}_TEXT`)}}</p>
				</div>
				<div class="work-details-grid">
					<div class="work-details-row">
						<div class="work-details-column">
							<p class="work-details-label">{{translateCopy(`${detailsPrefix}_CASE_LABEL`)}}</p>
							<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_CASE`)}}</p>
						</div>
						<div class="work-details-column"/>
					</div>
					<div class="work-details-row">
						<div class="work-details-column">
							<p class="work-details-label">{{translateCopy(`${detailsPrefix}_CLIENT_LABEL`)}}</p>
							<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_CLIENT`)}}</p>
						</div>
						<div class="work-details-column">
							<p class="work-details-label">{{translateCopy(`${detailsPrefix}_YEAR_LABEL`)}}</p>
							<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_YEAR`)}}</p>
						</div>
					</div>
					<div class="work-details-row">
						<div class="work-details-column">
							<p class="work-details-label">{{translateCopy(`${detailsPrefix}_SCOPE_LABEL`)}}</p>
							<p class="work-details-text" v-html="translateCopy(`${detailsPrefix}_${id}_SCOPE`)"/>
						</div>
						<div class="work-details-column"/>
					</div>
				</div>
			</div>	
		</div>
		<scroll-down class="work-scroll-white" v-if="id!=6" destiny="team-about"/>
		<scroll-down v-else destiny="team-about"/>
		
		<!-- This HTML is shown if the width of the device is minor than 700 -->
		<div id="work-title-component-responsive" :class="customTheme">
			<div class="work-details-main-title">
				<p>{{translateCopy(`HOME_WORKS_${id}_TEXT`)}}</p>
			</div>
			<div class="work-details-grid">
				<div class="work-details-row">
					<div class="work-details-column">
						<p class="work-details-label">{{translateCopy(`${detailsPrefix}_CASE_LABEL`)}}</p>
						<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_CASE`)}}</p>
					</div>
					<div class="work-details-column"/>
				</div>
				<div class="work-details-row">
					<div class="work-details-column">
						<p class="work-details-label">{{translateCopy(`${detailsPrefix}_CLIENT_LABEL`)}}</p>
						<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_CLIENT`)}}</p>
					</div>
					<div class="work-details-column">
						<p class="work-details-label">{{translateCopy(`${detailsPrefix}_YEAR_LABEL`)}}</p>
						<p class="work-details-text">{{translateCopy(`${detailsPrefix}_${id}_YEAR`)}}</p>
					</div>
				</div>
				<div class="work-details-row">
					<div class="work-details-column">
						<p class="work-details-label">{{translateCopy(`${detailsPrefix}_SCOPE_LABEL`)}}</p>
						<p class="work-details-text" v-html="translateCopy(`${detailsPrefix}_${id}_SCOPE`)"/>
					</div>
					<div class="work-details-column"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  // @ is an alias to /src

  import constants from "@/constants";
  import ScrollDown from "../../components/navigation/ScrollDown";

  export default {
    components: { ScrollDown },
    name: "WorkTitle",
    props: {
      id: Number,
      customColor: String
    },
    data() {
      return {
        detailsPrefix: 'WORK_DETAILS',
      }
    },
    computed: {
      customTheme() {
        return `work-${this.id}`
      },
	  customClass(){
		  if(this.id != 6  && this.id != 1){
			  return "work-details-content";
		  }else {
			  return "work-details-content-opacity-background";
		  }
	  }
    },
    methods: {
      goToPage(page) {
        this.$router.push(page)
      },
      translateCopy(key) {
        return constants.getCopy(key);
      }
    }
  };
</script>
