<template>
    <div>
        <home/>
        <introduction/>
        <clients :homePage="true"/>
        <works/>
        <services/>
        <!-- <careers/> -->
        <contact/>
        <quadram-footer/>
    </div>
</template>

<script>
    import constants from "../constants";
    import Home from "./Home/Home"
    import Services from "./Home/Services"
    import Works from "./Home/Works"
    import Careers from "./Home/Careers"
    import Contact from "./Home/Contact"
    import Clients from "./Home/Clients";
    import QuadramFooter from "./Home/Footer";
    import Introduction from "./Home/Introduction";

    export default {
        components: {Clients, Home, Services, Works, Careers, Contact, QuadramFooter, Introduction},
        name: "QuadramWeb",
        metaInfo() {
            return {
                link: [
                { rel: 'canonical', href: 'https://quadram.mobi' }
                ]
            }
        },
        data() {
            return {}
        },
        mounted() {},
        methods: {},
    }
</script>
