<template>
  <div id="clients-page">
    <title-component/>
    <clients :clients-page="true"/>
    <contact/>
    <quadram-footer/>
  </div>
</template>

<script>
  import TitleComponent from "../components/Title";
  import QuadramFooter from "./Home/Footer";
  import Clients from "./Home/Clients";
  import Contact from "./Home/Contact";
  export default {
      components: {TitleComponent, QuadramFooter, Clients, Contact},
      name: "mainClients",
      metaInfo() {
        return {
          link: [
            { rel: 'canonical', href: 'https://quadram.mobi/clients' }
          ]
        }
      },
      data(){
        return{
          
        }
      }
  }
</script>