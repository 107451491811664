<template>
    <div id="team">
        <title-component/>
        <about :mainPrefix="aboutPrefix" />
        <images-grid :images="imagesRow"/>
        <images-grid :images="imageTeam"/>
        <images-grid :images="imageOffice"/>
        <detailed-list
            :is-white="false"
            :list-count="values"
            :list-prefix="valuesCopyPrefix"
            :section-name="valuesSectionName"
        />
        <join-us/>
        <quadram-footer/>
    </div>
</template>
<script>
  import TitleComponent from "../components/Title";
  import About from "./Team/About";
  import ImagesGrid from "../components/ImagesGrid";
  import DetailedList from "../components/DetailedList";
  import joinUs from "./Team/joinUs";
  import Contact from "./Home/Contact";
  import QuadramFooter from "./Home/Footer";

  export default {
    components: {TitleComponent, About, ImagesGrid, DetailedList, joinUs, Contact, QuadramFooter},
      name: "Team",
      metaInfo() {
        return {
          link: [
            { rel: 'canonical', href: 'https://quadram.mobi/team' }
          ]
        }
      },
      data() {
        return {
          aboutPrefix: 'TEAM_ABOUT',
          values: 5,
          valuesSectionName: 'TEAM_VALUES',
          valuesCopyPrefix: 'TEAM_VALUES_VALUE',
          imagesRow: [
            {
                id: 0,
                name: 'Trabajando en la oficina',
                src: require('@/assets/images/team/trabajando-en-oficina.webp'),
            },
            {
                id: 1,
                name: 'Concentrados trabajando en la oficina',
                src: require('@/assets/images/team/trabajando-en-oficina-2.webp'),
            },
            {
              id: 2,
              name: 'Trabajando disfrazados en la oficina',
              src: require('@/assets/images/team/trabajando-en-oficina-disfrazado.webp'),
            }
          ],
          imageTeam: [
            {
              id: 3,
              name: 'Equipo Quadram',
              src: require('@/assets/images/team/equipo-quadram.webp'),
            }
          ],
          imageOffice: [
            {
                id: 4,
                name: 'Oficina de Quadram',
                src: require('@/assets/images/team/oficina-quadram.svg'),
              }
          ]
        }
      },
  }
</script>
