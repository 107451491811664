<template>
  <div id="main-clients">
    <div id="clients">
      <!-- <SectionNamer :currentSection=hook /> -->
      <p v-if="homePage" class="title">
        {{ CONSTANTS.getCopy("HOME_CLIENTS_TITLE") }}
      </p>
      <div class="clients-container">
        <div v-if="!clientsPage" v-for="i in 8" class="client eight-clients">
          <img
            :src="getImgSrc(mainClientsIds[i - 1])"
            :alt="clients[mainClientsIds[i - 1]].alt"
            :title="clients[mainClientsIds[i - 1]].name"
            class="client-image"
            :class="`first-clients-view-${i}`"
          />
          <img
            :src="getImgSrc(mainClientsIds[i + 7])"
            :alt="clients[mainClientsIds[i + 7]].alt"
            :title="clients[mainClientsIds[i + 7]].name"
            class="client-image"
            :class="`second-clients-view-${i}`"
          />
        </div>
        <div v-if="!clientsPage" v-for="i in 3" class="client three-clients">
          <img
            :src="getImgSrc(mainClientsIds[i - 1])"
            :alt="clients[mainClientsIds[i - 1]].alt"
            :title="clients[mainClientsIds[i - 1]].name"
            class="client-image"
            :class="`first-clients-view-${i}`"
          />
          <img
            :src="getImgSrc(mainClientsIds[i + 2])"
            :alt="clients[mainClientsIds[i + 2]].alt"
            :title="clients[mainClientsIds[i + 2]].name"
            class="client-image"
            :class="`first-clients-view-${i + 3}`"
          />
          <img
            :src="getImgSrc(mainClientsIds[i + 5])"
            :alt="clients[mainClientsIds[i + 5]].alt"
            :title="clients[mainClientsIds[i + 5]].name"
            class="client-image"
            :class="`second-clients-view-${i}`"
          />
          <img
            :src="getImgSrc(mainClientsIds[i + 8])"
            :alt="clients[mainClientsIds[i + 8]].alt"
            :title="clients[mainClientsIds[i + 8]].name"
            class="client-image"
            :class="`second-clients-view-${i + 3}`"
          />
        </div>

        <div
          v-if="clientsPage"
          v-for="i in randomsNumsArray"
          class="client all-clients"
        >
          <img
            :src="getImgSrc(i)"
            :alt="clients[i].alt"
            :title="clients[i].name"
          />
        </div>
      </div>
      <router-link
        v-if="!clientsPage"
        tag="a"
        to="/clients"
        class="quadram-button-container"
      >
        <button type="button" class="quadram-button">
          <span>{{ buttonContentText }}</span>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";
import SectionNamer from "../../components/navigation/SectionNamer";
import clients from "../../clients";

export default {
  components: { SectionNamer },
  name: "clients",
  props: {
    clientsPage: Boolean,
    homePage: Boolean,
  },
  data() {
    return {
      mainClientsIds: [
        5, 18, 22, 41, 46, 57, 60, 71, 7, 9, 10, 17, 19, 25, 27, 35,
      ],
      randomsNumsArray: Array(),
      hook: 4,
      buttonTellUs: "HOME_TELL_US_CLIENTS_BUTTON",
      title: "HOME_CLIENTS",
      homeDescription: "HOME_CLIENTS_DESCRIPTION_TEXT",
      homeDescription2: "HOME_CLIENTS_DESCRIPTION_2_TEXT",
      slides: 4,
      buttonText: "HOME_CLIENTS_BUTTON",
      clientSet: 0,
      clients: clients.allClients,
    };
  },
  mounted() {
    //Condition to dont spend resources if web arent in the main clients view
    if (this.clientsPage) {
      for (let i = 0; i < this.clients.length; i++) {
        this.randomsNumsArray.push(i);
      }

      let i, j, k;
      for (i = this.randomsNumsArray.length; i; i--) {
        j = Math.floor(Math.random() * i);
        k = this.randomsNumsArray[i - 1];
        this.randomsNumsArray[i - 1] = this.randomsNumsArray[j];
        this.randomsNumsArray[j] = k;
      }
    }
  },
  computed: {
    buttonContentText() {
      return constants.getCopy(this.buttonText);
    },
    buttonTellUsText() {
      return constants.getCopy(this.buttonTellUs);
    },
    homeDescriptionText() {
      return constants.getCopy(this.homeDescription);
    },
    homeDescription2Text() {
      return constants.getCopy(this.homeDescription2);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
    getImgSrc(index) {
      return require(`../../assets/images/partners/${this.clients[index].logo}`);
    },
  },
};
</script>
