<template>
  <div>
    <div id="contact-form">
      <div class="wrapper">
        <SectionNamer :customText="sectionNameText" />
        <div class="contact-container">
          <div class="contact-content">
            <div class="contact-title" v-html="formTitle"></div>
            <img class="img-hand-vertical" src="@/assets/images/contact-hand-vertical-rotate.webp" />
            <form method="post">
              <div class="contact-form">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field">
                      <!--                                    <transition name="label">-->
                      <!--                                        <div v-if="form.name && form.name !== ''" class="field-label">-->
                      <!--                                            {{CONSTANTS.getCopy(namePlaceholder)}}-->
                      <!--                                        </div>-->
                      <!--                                    </transition>-->
                      <div class="field-label">
                        <label for="name">{{
                            CONSTANTS.getCopy(namePlaceholder)
                        }}</label>
                      </div>
                      <div class="field-body">
                        <input type="text" id="name" :class="{ 'input-error': !form.name && showError }"
                          v-model="form.name" />
                      </div>
                    </div>
                    <div class="field">
                      <!--                                    <transition name="label">-->
                      <!--                                        <div v-if="form.name && form.lastname !== ''" class="field-label">-->
                      <!--                                            {{CONSTANTS.getCopy(lastnamePlaceholder)}}-->
                      <!--                                        </div>-->
                      <!--                                    </transition>-->
                      <div class="field-label">
                        <label for="lastname">{{
                            CONSTANTS.getCopy(lastnamePlaceholder)
                        }}</label>
                      </div>
                      <div class="field-body">
                        <input type="text" id="lastname" :class="{
                          'input-error': !form.lastname && showError,
                        }" v-model="form.lastname" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field">
                      <!--                                    <transition name="label">-->
                      <!--                                        <div v-if="form.email && form.email !== ''" class="field-label">-->
                      <!--                                            {{CONSTANTS.getCopy(emailPlaceHolder)}}-->
                      <!--                                        </div>-->
                      <!--                                    </transition>-->
                      <div class="field-label">
                        <label for="email">{{
                            CONSTANTS.getCopy(emailPlaceHolder)
                        }}</label>
                      </div>
                      <div class="field-body">
                        <input type="email" id="email" :class="{
                          'input-error':
                            showError &&
                            (!form.email || !expEmail.test(form.email)),
                        }" v-model="form.email" />
                      </div>
                    </div>
                    <div class="field">
                      <!--                                    <transition name="label">-->
                      <!--                                        <div v-if="form.phone && form.phone !== ''" class="field-label">-->
                      <!--                                            {{CONSTANTS.getCopy(phonePlaceHolder)}}-->
                      <!--                                        </div>-->
                      <!--                                    </transition>-->
                      <div class="field-label">
                        <label for="phone">{{
                            CONSTANTS.getCopy(phonePlaceHolder)
                        }}</label>
                      </div>
                      <div class="field-body">
                        <input type="text" id="phone" :class="{
                          'input-error':
                            showError &&
                            (!form.phone || !expPhone.test(form.phone)),
                        }" v-model="form.phone" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field contact-content-text">
                  <div class="field-label">
                    <label for="text">{{ CONSTANTS.getCopy(text) }}</label>
                  </div>
                  <div class="field-body">
                    <textarea id="text" :class="{ 'input-error': !form.text && showError }"
                      v-model="form.text"></textarea>
                  </div>
                </div>
                <div class="field">
                  <div class="field-label">
                    <label for="budget" @focus="changeSelect">{{
                        CONSTANTS.getCopy(textBudget)
                    }}</label>
                  </div>
                  <div class="field-body">
                    <select class="select-budget" id="budget" :class="{
                      up: isDown,
                      'input-error': !form.budget && showError,
                    }" v-model="form.budget" @focus="changeSelect" @blur="changeSelect">
                      <option selected="selected" value="null" disabled>
                        {{ this.CONSTANTS.getCopy("OPTION_1_BUDGET") }}
                      </option>
                      <option v-for="(option, index) in optionsBudget" :value="index + 1" :key="index">
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="field contact-content-text">
                  <div class="field-label">
                    <label for="howMeet">{{
                        CONSTANTS.getCopy(howMeetPlaceholder)
                    }}</label>
                  </div>
                  <textarea id="howMeet" :class="{ 'input-error': !form.howMeet && showError }"
                    v-model="form.howMeet"></textarea>
                </div>
                <div class="concat-terms">
                  <input type="checkbox" id="check-terms" class="check-terms"
                    :class="{ 'input-error': showError && !privacyAccepted }" v-model="privacyAccepted" />
                  <label class="privacy-alignment" for="check-terms">{{ CONSTANTS.getCopy(textTermsCheckbox)
                  }}<span class="link-terms" @click="terms">{{
    CONSTANTS.getCopy(textTermsLink)
}}</span></label>
                </div>

                <a class="quadram-button-container">
                  <button type="button" class="quadram-button" @click="sendMail">
                    <span>{{ CONSTANTS.getCopy(buttonText) }}</span>
                  </button>
                </a>
                <div v-if="!isMessageSent">
                  <div v-if="showError" class="message-sent" v-html="CONSTANTS.getCopy(messageErrors)"></div>
                </div>
                <div class="message-sent" v-else>
                  <span v-html="CONSTANTS.getCopy(messageSentText)"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <quadram-footer />
  </div>
</template>
<script>
import axios from "axios";
import constants from "../constants";
import SectionNamer from "../components/navigation/SectionNamer";
import QuadramFooter from "./Home/Footer";
const querystring = require("querystring");

export default {
  components: { SectionNamer, QuadramFooter },
  name: "Contact",
  metaInfo() {
    return this.$route.meta.isOnlyContact && {
      link: [
        { rel: 'canonical', href: 'https://quadram.mobi/contact' }
      ]
    }
  },
  props: {
    time: Number,
  },
  data() {
    return {
      isDown: false,
      expEmail: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
      expPhone: /^\d{8,12}$/,
      expBudget: /^\d+$/,
      isMessageSent: false,
      showError: false,
      privacyAccepted: false,
      form: {
        name: null,
        lastname: null,
        email: null,
        phone: null,
        text: null,
        budget: null,
        howMeet: null,
      },
      sectionName: "CONTACT_SECTION_NAME",
      title: "CONTACT_TITLE",
      text: "CONTACT_TEXT",
      buttonText: "CONTACT_BUTTON",
      namePlaceholder: "CONTACT_NAME_PLACEHOLDER",
      lastnamePlaceholder: "CONTACT_LASTNAME_PLACEHOLDER",
      emailPlaceHolder: "CONTACT_MAIL_PLACEHOLDER",
      phonePlaceHolder: "CONTACT_PHONE_PLACEHOLDER",
      textPlaceHolder: "CONTACT_CONTENT_PLACEHOLDER",
      textBudget: "CONTACT_BUDGET_TEXT",
      howMeetPlaceholder: "CONTACT_HOW_MEET_PLACEHOLDER",
      textTermsCheckbox: "CONTACT_PRE_TERMS_LINK",
      textTermsLink: "CONTACT_TERMS_LINK",
      messageSentText: "CONTACT_MESSAGE_SENT",
      messageErrors: "CONTACT_MESSAGE_ERRORS",
    };
  },
  computed: {
    sectionNameText() {
      return constants.getCopy(this.sectionName);
    },
    formTitle() {
      return constants.getCopy(this.title);
    },
    formPrivacy() {
      return !!this.privacyAccepted;
    },
    formName() {
      return !!this.form.name;
    },
    formLastName() {
      return !!this.form.lastname;
    },
    formEmail() {
      return this.expEmail.test(this.form.email);
    },
    formPhone() {
      return this.expPhone.test(this.form.phone);
    },
    formText() {
      return !!this.form.text;
    },
    formBudget() {
      return !!this.form.budget;
    },
    formHowMeet() {
      return !!this.form.howMeet;
    },
    hasErrors() {
      return !(
        this.formPrivacy &&
        this.formName &&
        this.formLastName &&
        this.formEmail &&
        this.formPhone &&
        this.formText &&
        this.formBudget &&
        this.formHowMeet
      );
    },
    optionsBudget() {
      return [
        this.CONSTANTS.getCopy("OPTION_2_BUDGET"),
        this.CONSTANTS.getCopy("OPTION_3_BUDGET"),
        this.CONSTANTS.getCopy("OPTION_4_BUDGET"),
        this.CONSTANTS.getCopy("OPTION_5_BUDGET"),
        this.CONSTANTS.getCopy("OPTION_6_BUDGET"),
      ];
    },
  },
  methods: {
    changeSelect() {
      this.isDown = !this.isDown;
    },
    selectDrink(drink) {
      if (!drink.selected) {
        this.drinks.map((item) => {
          item.selected = false;
        });
      }
      drink.selected = !drink.selected;
    },
    terms() {
      this.$router.push("privacy");
    },
    sendMail() {
      if (this.hasErrors) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.form.budget = this.optionsBudget[this.form.budget];
      axios
        .post("../php/post.php", querystring.stringify(this.form))
        .then((response) => {
          if (!response.data) {
            alert(
              "Ha ocurrido un error al procesar su petición. Intentelo más tarde"
            );
            return;
          }
          this.isMessageSent = true;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>
