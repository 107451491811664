<template>
    <div id="scroll-down">
        <div class="scroll-down-button" @click="goToNextHook">
            <div class="scroll-down-text">
                <span>{{CONSTANTS.getCopy(text)}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import constants from '../../constants';

    export default {
        name: "ScrollDown",

        props: {
            currentSection: Number,
            destiny: String,
        },

        data() {
            return {
                text: 'SCROLL_DOWN',
                sections: [],
            }
        },

        mounted() {
            this.sections = constants.hooks;
        },

        methods: {
            goToNextHook() {
                document.getElementById(this.destiny).scrollIntoView({ behavior: 'smooth', block: 'start' });
            },
        }
    };
</script>
