<template>
    <div>
        <div v-if="footerService !== 5" id="footer-service-detail">
            <h2 class="title-service-detail">{{translateCopy(`FOOTER_SERVICE_${footerService}_HEADING`)}}</h2>
            <div class="content-service-detail" v-html="translateCopy(`FOOTER_SERVICE_${footerService}_CONTENT`)"></div>
            <img
                v-if="footerService !== 6" 
                :src="require(`@/assets/images/footerServices/Frame_${footerService}.webp`)"
                alt="Imágen logotipos de herramientas"
            />
        </div>
        <div v-else>
            <TeamAugmentation :footerService="footerService"/>
        </div>
        <div id="footer-service-question">
            <p>{{translateCopy(`FOOTER_SERVICE_${footerService}_QUESTION`)}}</p>
        </div>
    </div>
</template>

<script>

    import constants from "@/constants";
    import TeamAugmentation from "./TeamAugmentation.vue";

    export default {
        name: "FooterServiceDetail",
        metaInfo() {
            return {
                link: [
                { rel: 'canonical', href: `https://quadram.mobi/${this.footerSeriviceUrl[this.footerService - 1]}` }
                ]
            }
        },
        components: { TeamAugmentation },
        props: {
            footerService: Number
        },
        data() {
            return {
                footerSeriviceUrl: [
                    'usabilidad-diseno-aplicaciones-moviles',
                    'apps-ios',
                    'apps-android',
                    'apps-react-native',
                    'equipo-expertos-team-augmentation',
                    'web-responsive-pwa',
                    'sistema-diseno'
                ]
            }
        },
        methods: {
            translateCopy(key) {
                return constants.getCopy(key);
            }
        }
    }

</script>