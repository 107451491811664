<template>
	<div id="work-detail">
		<work-title :id="workId" />
		<about :main-prefix="`${aboutPrefix1}_${workId}`" :empty-section-name="true" />
		<images-grid :images="firstImages" :not-overlapped="true"/>
		<about :main-prefix="`${aboutPrefix2}_${workId}`" :empty-section-name="true" :bold-text="true" />
		<images-grid :images="secondImages" :not-overlapped="true"/>
		<about :class="'about-white background-team-about-'+workId"  :main-prefix="`${aboutPrefix3}_${workId}`" :empty-section-name="true" />
		<works :works-detail-page="true" :work="workId"/>
		<contact/>
		<quadram-footer/>
	</div>
</template>
<script>
  import WorkTitle from "./WorkTitle";
  import About from "../Team/About";
  import ImagesGrid from "../../components/ImagesGrid";
  import QuadramFooter from "../Home/Footer";
  import Works from "../Home/Works";
  import Contact from "../Home/Contact";
  import works from "../../works";

  export default {
    components: { WorkTitle, About, ImagesGrid, Works, Contact, QuadramFooter},
    name: "WorkDetail",
    metaInfo() {
    return {
        link: [
          { rel: 'canonical', href: `https://quadram.mobi/portfolio/${this.workFolderName[this.workId - 1]}` }
        ]
      }
    },
    data() {
      return {
        workId: null,
        aboutPrefix1: 'WORK_DETAIL_FIRST',
        aboutPrefix2: 'WORK_DETAIL_SECOND',
        aboutPrefix3: 'WORK_DETAIL_THIRD',
        firstImages: [],
        secondImages: [],
        workFolderName: [
          'dhis2',
          'safy',
          'direct-seguros',
          'trustyou',
          'trive'
        ],
        works: works.allWorks
      }
    },
    mounted() {
      this.init();
      let workName = this.workFolderName[this.workId - 1];
      let workImages = this.works[workName];
      let workImagesLength = (Object.keys(workImages).length) -1;
      for (let i = 0; i < workImagesLength; i++){
        this.firstImages.push({
          id: i,
          name: workImages[i].alt,
          title: workImages[i].title,
          src: require(`@/assets/images/works/${workName}/${workImages[i].name}`)
        })
      }
      this.secondImages = [{
        id: workImagesLength,
        name: workImages[workImagesLength].alt,
        title: workImages[workImagesLength].title,
        src: require(`@/assets/images/works/${workName}/${workImages[workImagesLength].name}`)
      }]
    },
    methods: {
      init() {
        this.workId = localStorage.getObject('qWork')
      },
      goToPage(page) {
        this.$router.push(page)
      },
    }
  }
</script>
