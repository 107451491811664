<template>
    <div id="footer-service">
        <title-component/>

        <footer-service-detail :footerService="footerService" />

        <contact/>
    </div>
</template>

<script>
    import Contact from './Contact.vue';
    import TitleComponent from "../components/Title";
    import FooterServiceDetail from "./FooterServices/FooterServiceDetail.vue";

    export default {
        components: { FooterServiceDetail, TitleComponent, Contact},
        name: "FooterService",
        data() {
            return {
                footerService: this.$router.currentRoute.meta.footerService
            }
        },
        mounted() {
        },
        methods: {},
    }
</script>