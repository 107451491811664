<template>
  <div id="detailed-list"
       :class="isWhite && 'white-list'">
    <SectionNamer :custom-text="customSectionName" :is-white="!isWhite"/>
    <div class="listed-object" v-for="i in listCount">
      <h2 class="listed-object-title">{{translateCopy(`${listPrefix}_${i}_TITLE`)}}</h2>
      <p class="listed-object-description" v-html="translateCopy(`${listPrefix}_${i}_DESCRIPTION`)"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';
import SectionNamer from "./navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "DetailedList",
  props: {
      listCount: Number,
      listPrefix: String,
      isWhite: Boolean,
      sectionName: String,
  },
  data() {
    return {

    }
  },
  computed: {
    customSectionName() {
      return this.translateCopy(this.sectionName);
    }
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
    translateCopy(key) {
      return constants.getCopy(key);
    }
  }
};
</script>
