<template>
  <div id="footer">
    <div class="footer-container">
      <div class="footer-row">
        <div class="footer-column">
          <h4 class="title">{{ CONSTANTS.getCopy('HOME_FOOTER_TITLE_1') }}</h4>
          <router-link tag="a" to="/services" class="footer-simple-link">{{translation(texts.services)}}</router-link>
          <router-link tag="a" to="/portfolio" class="footer-simple-link">{{translation(texts.portfolio)}}</router-link>
          <router-link tag="a" to="/team" class="footer-simple-link">{{translation(texts.team)}}</router-link>
          <router-link tag="a" to="/team#join-us" class="footer-simple-link">{{translation(texts.careers)}}</router-link>
          <a href="http://quadram.mobi/blog/" class="footer-simple-link">{{translation(texts.blog)}}</a>
        </div>
        <div class="footer-column" @click="updateContent()">
          <h4 class="title">{{ CONSTANTS.getCopy('HOME_FOOTER_TITLE_2') }}</h4>
          <router-link tag="a" to="/usabilidad-diseno-aplicaciones-moviles" class="footer-simple-link">{{translation(texts.uxui)}}</router-link>
          <router-link tag="a" to="/apps-ios" class="footer-simple-link">{{translation(texts.ios)}}</router-link>
          <router-link tag="a" to="/apps-android" class="footer-simple-link">{{translation(texts.android)}}</router-link>
          <router-link tag="a" to="/apps-react-native" class="footer-simple-link">{{translation(texts.reactnative)}}</router-link>
          <router-link tag="a" to="/equipo-expertos-team-augmentation" class="footer-simple-link">{{translation(texts.teamaugmentation)}}</router-link>
          <router-link tag="a" to="/web-responsive-pwa" class="footer-simple-link">{{translation(texts.backend)}}</router-link>
          <router-link tag="a" to="/sistema-diseno" class="footer-simple-link">{{translation(texts.designsystems)}}</router-link>
        </div>
        <div class="footer-column">
          <h4 class="title">{{ CONSTANTS.getCopy('HOME_FOOTER_TITLE_3') }}</h4>
          <a :href="`mailto:${translation(texts.mail)}`" class="footer-simple-link is-contact">{{translation(texts.mail)}}</a>
          <a href="tel:911280011" class="footer-simple-link is-contact">{{translation(texts.phone)}}</a>
          <span class="footer-address" v-html="translation('HOME_FOOTER_CITIES_1_ADDRESS')"></span>
          <div class="social-media-icons">
            <a href="https://www.instagram.com/quadramqs/" aria-label="instagram" target="_blank" class="footer-simple-link is-contact icon is-medium">
              <i class="fab fa-2x fa-instagram"/>
            </a>
            <a href="https://es.linkedin.com/company/quadram" aria-label="linkedin" target="_blank" class="footer-simple-link is-contact icon is-medium">
              <i class="fab fa-2x fa-linkedin"/>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="footer-row">
        <div v-for="city in translatedCities" class="footer-column">
          <p class="footer-city-name">{{city.name}}</p>
          <p class="footer-address" v-html="city.address" />
          <p class="footer-city-time">{{city.hour}}<span>:</span>{{minutes}}</p>
          <p class="footer-city-address" v-html="city.address"/>
        </div>
      </div> -->
    </div>
    <div class="footer-end">
      <p>{{copyrightText}}</p>
      <div><router-link tag="a" to="/privacy">{{termsText}}</router-link> | <router-link tag="a" to="/cookies">{{cookiesTermsText}}</router-link></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";

export default {
  name: "quadramFooter",
  data() {
    return {
      title: 'HOME_CONTACT_TITLE',
      text: 'HOME_FOOTER_TEXT',
      texts: {
        services: 'HOME_FOOTER_SERVICES',
        portfolio: 'HOME_FOOTER_WORKS',
        team: 'HOME_FOOTER_TEAM',
        careers: 'HOME_FOOTER_CAREERS',
        blog: 'HOME_FOOTER_BLOG',
        mail: 'HOME_FOOTER_MAIL',
        phone: 'HOME_FOOTER_PHONE',
        uxui: 'HOME_FOOTER_UXUI',
        ios: 'HOME_FOOTER_IOS',
        android: 'HOME_FOOTER_ANDROID',
        reactnative: 'HOME_FOOTER_REACT_NATIVE',
        teamaugmentation: 'HOME_FOOTER_TEAM_AUGMENTATION',
        backend: 'HOME_FOOTER_BACKEND',
        designsystems: 'HOME_FOOTER_DESIGN_SYSTEMS'
      },
      cities: [
        {
          name: 'HOME_FOOTER_CITIES_1_NAME',
          address: 'HOME_FOOTER_CITIES_1_ADDRESS',
          UTCDifference: 1,
          timezone: 'Europe/Madrid'
        },
      ],
      hour: 0,
      minutes: '00',
      copyright: 'HOME_FOOTER_COPYRIGHT',
      terms: 'HOME_FOOTER_TERMS',
      cookiesTerms: 'HOME_FOOTER_COOKIES',
      termsLink: 'HOME_FOOTER_TERMS_LINK',
    }
  },
  mounted() {
    this.setMinutes();
    this.startClock();
  },
  computed: {
    translatedCities() {
      return this.cities.map((city) => {
        return {
          name: this.translation(city.name),
          address: this.translation(city.address),
          hour: this.getCityHour(city),
        }
      })
    },
    // minutes() {
    //   return new Date().getMinutes();
    // },
    copyrightText() {
      return `${this.translation(this.copyright)} ${new Date().getFullYear()}`;
    },
    termsText() {
      return this.translation(this.terms);
    },
    cookiesTermsText(){
      return this.translation(this.cookiesTerms);
    },
    termsLinkUrl() {
      return this.translation(this.termsLink);
    },
  },
  methods: {
    updateContent(){
      this.$parent.$parent.$parent.updateContent();//Call the updateContent funcion of the Main component
    },
    goToPage(page) {
      this.$router.push(page);
    },
    goToNewTab(page) {
      let routeData = this.$router.resolve({path: page});
      window.open(routeData.href, '_blank');
    },
    startClock() {
      if(!this.timer) {
        this.timer = setInterval(() => {
          this.setMinutes();
        }, 1000);
      }
    },
    getCityHour(city) {
      let time = new Date().toLocaleTimeString('es-ES', {timeZone: city.timezone});
      return time.split(':')[0];
    },
    setMinutes() {
      let date = new Date();
      let minutes = date.getUTCMinutes();
      this.hour = date.getUTCHours();
      this.minutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    },
    translation(key) {
      return constants.getCopy(key);
    },
  }
};
</script>
