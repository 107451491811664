<template>
  <div id="app">
    <NavBar @menuClicked="openMenu"/>
    <Menu :show=showMenu @menuClicked="closeMenu"/>
    <router-view :key="update"/>
  </div>
</template>
<script>
  import constants from './constants';

  export default {
    name: "Main",
    metaInfo() {
      return {
        htmlAttrs: {
          lang: constants.getCopy("LANGUAGE_HTML"),
        },
        meta:[
          { name: 'language', content: constants.getCopy("META_LANGUAGE") },
          { name: "description", content: constants.getCopy(this.metaDescription) },
          { property: "og:title", content: constants.getCopy(this.ogTitle) },
        ]
      }
    },
    data() {
      return {
        update: 0,
        currentPage: '',
        hooks: null,
        currentHook: null,
        scrollPosition: 0,
        isScrolling: false,
        showMenu: false,
        handleWheelVar: null,
        blockScrollVar: null,
        blockWheelVar: null,
        metaDescription: this.$route.meta.customDescription,
        ogTitle: this.$route.meta.customOgTitle
      }
    },
    mounted() {
    },
    created () {
      this.setCopies();
      this.setCookieConsent();
    },
    destroyed () {
    },
    watch: {
      showMenu: function() {
        document.documentElement.style.overflow = this.showMenu ? 'hidden' : 'auto';
      }
    },
    computed: {

    },
    methods: {
      setCopies() {
        let language = localStorage.getObject('language') || constants.languages[0];
        constants.setCopies(language);
      },
      setCookieConsent() {
        window.addEventListener("load", function(){
          window.cookieconsent.initialise({
            "position": "bottom-right",
            "content": {
              "title": constants.getCopy('COOKIE_CONSENT_TITLE'),
              "message": constants.getCopy('COOKIE_CONSENT_TEXT'),
              "link": constants.getCopy('COOKIE_CONSENT_LEARN_MORE'),
              "dismiss": constants.getCopy('COOKIE_CONSENT_DISMISS'),
              "href": "/privacy"
            }
          })});
      },
      openMenu() {
        this.showMenu = true;
      },
      closeMenu() {
        this.showMenu = false;
      },
      updateContent(){
        //Change the value to update the content of the component
        this.update++;
      },
    },
  }
</script>
<style lang="scss">
  #app {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
  }
</style>
