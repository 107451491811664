<template>
    <div id="section-namer">
        <div :class="`${workPage ? 'current-page-name main-works' : 'current-page-name'}`">
            <span :class="isWhite && 'is-white'">{{sectionNameDisplayed}}</span>
        </div>
    </div>
</template>
<script>
    import constants from '../../constants';

    export default {
        name: "SectionNamer",
        props: {
          currentSection: Number,
          isWhite: Boolean,
          customText: String,
          workPage: Boolean
        },
        data() {
          return {
            sections: [],
            sectionName: '',
          }
        },
        mounted() {
          this.sections = constants.hooks;
          this.currentSection && this.getSectionName(this.currentSection);
        },
        computed: {
          sectionNameDisplayed() {
            return this.customText || (this.currentSection ? constants.getCopy(this.sectionName) : '')
          }
        },
        methods: {
          getSectionName(id) {
            this.sectionName = id ? this.sections[id].name : ''
          }
        }
    };
</script>
