<template>
    <div class="clock">
        <div class="hour" :style="{transform: 'rotate( ' + hours.toString() + 'deg)'}">
            <div class="hand"></div>
        </div>
        <div class="minutes" :style="{transform: 'rotate(' + minutes.toString() + 'deg)'}">
            <div class="hand"></div>
        </div>
        <div class="seconds" :style="{transform: 'rotate(' + seconds.toString() + 'deg)'}">
            <div class="hand">
                <div class="circle-top"></div>
                <div class="circle-bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Clock",
        props: {
            time: Number,
            utc: Number,
        },
        data() {
            return {
                isRunning: false,
                timer: null,
                currentSec: 0,
                seconds: 0,
                minutes: 0,
                hours: 0,
            }
        },
        watch: {
            time: function(){
                this.setTime();
            }
        },
        methods: {
            setTime() {
                this.currentSec = this.time || 0;
                this.seconds = (this.currentSec % 60) * 6;
                this.minutes = (Math.floor((this.currentSec / 60) % 60) * 6) + (this.seconds / 60);
                this.hours = ((Math.floor((this.currentSec / 3600) % 24) + this.utc) * 30) + (this.minutes / 60);
            },
        },
    }
</script>