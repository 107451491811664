<template>
    <div id="kit-digital">
        <title-component/>
        <div class="kit-digital-info">
            <p class="title-content" v-html="translateCopy('KIT_DIGITAL_TITLE_CONTENT')"></p>
            <img :src="require(`@/assets/images/kitDigital/logos.webp`)" class="logos"/>
        </div>
        <div class="kit-digital-work">
            <p class="title" v-html="translateCopy('KIT_DIGITAL_TITLE_WORK')"></p>
            <p class="content" v-html="translateCopy('KIT_DIGITAL_CONTENT_WORK')"></p>
        </div>
        <div class="kit-digital-video">
            <iframe src="https://www.youtube.com/embed/fI_Y5W25hPs" title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="destination-public">
            <p class="title">{{ translateCopy('KIT_DIGITAL_TITLE_DESTINATION') }}</p>
            <div v-html="translateCopy('KIT_DIGITAL_CONTENT_DESTINATION')"></div>
        </div>
        <div class="solutions">
            <p class="title">{{ translateCopy('KIT_DIGITAL_TITLE_SOLUTIONS') }}</p>
            <div class="solutions-container">
                <div class="solution-box">
                    <p class="title">{{ translateCopy('KIT_DIGITAL_SOLUTION_WEBSITE_TITLE') }}</p>
                    <p v-html="translateCopy('KIT_DIGITAL_SOLUTION_WEBSITE_LIST')"></p>
                    <p class="amount" v-html="translateCopy('KIT_DIGITAL_SOLUTION_AMOUNT')"></p>
                    <p class="segments-table" v-html="translateCopy('KIT_DIGITAL_SEGMENTS_TABLE')"></p>
                    <p class="segments-list" v-html="translateCopy('KIT_DIGITAL_SEGMENTS_LIST')"></p>
                    <p class="info-link" v-html="translateCopy('KIT_DIGITAL_SOLUTION_WEBSITE_INFO')"></p>
                </div>
                <!-- <div class="solution-box">
                    <p class="title">{{ translateCopy('KIT_DIGITAL_SOLUTION_ECOMMERCE_TITLE') }}</p>
                    <p v-html="translateCopy('KIT_DIGITAL_SOLUTION_ECOMMERCE_LIST')"></p>
                    <p class="amount" v-html="translateCopy('KIT_DIGITAL_SOLUTION_AMOUNT')"></p>
                    <p class="segments-table" v-html="translateCopy('KIT_DIGITAL_SEGMENTS_TABLE')"></p>
                    <p class="segments-list" v-html="translateCopy('KIT_DIGITAL_SEGMENTS_LIST')"></p>
                    <p class="info-link" v-html="translateCopy('KIT_DIGITAL_SOLUTION_ECOMMERCE_INFO')"></p>
                </div> -->
                <div class="solution-box">
                    <p class="title">{{ translateCopy('KIT_DIGITAL_ANALYTICS_TITLE') }}</p>
                    <p v-html="translateCopy('KIT_DIGITAL_ANALYTICS_LIST')"></p>
                    <p class="amount" v-html="translateCopy('KIT_DIGITAL_ANALYTICS_AMOUNT')"></p>
                </div>
            </div>
        </div>
        <div class="black-box">
            <p>{{ translateCopy('KIT_DIGITAL_JOIN_US') }}</p>
        </div>
        <contact/>
    </div>
</template>

<script>
import constants from "@/constants";
import TitleComponent from "../components/Title";
import Contact from "./Contact";

export default {
    components: {TitleComponent, Contact},
    name: "KitDigital",
    metaInfo() {
      return {
        link: [
          { rel: 'canonical', href: 'https://quadram.mobi/kit-digital/' }
        ]
      }
    },
    data () {
        return {
            
        }
    },
    methods: {
        translateCopy(key) {
            return constants.getCopy(key);
        }
    }
}
</script>