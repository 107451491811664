<template>
  <div id="careers">
    <!-- <SectionNamer :currentSection=hook :isWhite="true"/> -->
    <p class="careers-description">
      {{venturesContentText}}
    </p>
    <router-link tag="a" to="/contact" class="quadram-button-container">
      <button type="button" class="quadram-button"><span>{{buttonContentText}}</span></button>
    </router-link>
    <p class="careers-banner careers-subtitle">
      {{venturesSubtitle}}
    </p>
    <p class="careers-banner careers-title">
      {{venturesTitle}}
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

import constants from "../../constants";
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "careers",
  data() {
    return {
      hook: 5,
      title: 'HOME_VENTURES_TITLE',
      subtitle: 'HOME_VENTURES_SUBTITLE',
      text: 'HOME_VENTURES_TEXT',
      url: '#',
      buttonText: 'HOME_VENTURES_BUTTON',
    }
  },
  computed: {
    venturesContentText() {
      return constants.getCopy(this.text);
    },
    buttonContentText() {
      return constants.getCopy(this.buttonText);
    },
    venturesTitle() {
      return constants.getCopy(this.title);
    },
    venturesSubtitle() {
      return constants.getCopy(this.subtitle);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
