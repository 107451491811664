<template>
  <div id="services-page">
    <title-component />
    <offers :offers-count="offers" />
    <detailed-list
      :is-white="false"
      :list-count="services"
      :list-prefix="servicesCopyPrefix"
      :section-name="servicesSectionName"
    />
    <cycle />
    <detailed-list
      :is-white="false"
      :list-count="methodology"
      :list-prefix="methodologyCopyPrefix"
      :section-name="methodologySectionName"
    />
    <contact />
    <quadram-footer />
  </div>
</template>
<script>
import TitleComponent from "../components/Title";
import Offers from "./Services/Offers";
import DetailedList from "../components/DetailedList";
import Cycle from "./Services/Cycle";
import Contact from "./Home/Contact";
import QuadramFooter from "./Home/Footer";

export default {
  components: {
    DetailedList,
    Offers,
    Cycle,
    TitleComponent,
    Contact,
    QuadramFooter,
  },
  name: "Services",
  metaInfo() {
    return {
      link: [
        { rel: 'canonical', href: 'https://quadram.mobi/services' }
      ]
    }
  },
  data() {
    return {
      offers: 3,
      servicesSectionName: "SERVICES_SERVICES_SECTION_TITLE",
      services: 6,
      servicesCopyPrefix: "SERVICES_SERVICE",
      dayWork: 7,
      methodologySectionName: "SERVICES_METHODOLOGY_SECTION_TITLE",
      methodology: 2,
      methodologyCopyPrefix: "SERVICES_METHODOLOGY",
    };
  },
};
</script>
