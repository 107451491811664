<template>
  <div id="team-about" :class="customTheme">
    <SectionNamer :custom-text="sectionName"/>
    <p class="team-about-text" :class="boldText && 'bold-about-text'" v-html="aboutText" />
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';
import SectionNamer from "../../components/navigation/SectionNamer";

export default {
  components: {SectionNamer},
  name: "About",
  props: {
    mainPrefix: String,
    emptySectionName: Boolean,
    customColor: String,
    boldText: Boolean,
  },
  data() {
    return {
      aboutPrefixCopy: 'TEAM_ABOUT_TEXT',
    }
  },
  computed: {
      sectionName() {
        return this.emptySectionName
          ? ''
          : this.translateCopy(`${this.mainPrefix}_TITLE`);
      },
      aboutText() {
        return this.translateCopy(`${this.mainPrefix}_TEXT`);
      },
      customTheme() {
        let customClass = '';
        // if (this.customColor) {
        switch (this.customColor) {
          case 'red':
            customClass = 'about-section-red';
            break;
          case 'blue':
            customClass = 'about-section-blue';
            break;
        }
        // }
        return customClass;
      },
  },
  methods: {

    goToPage(page) {
      this.$router.push(page)
    },
    translateCopy(key) {
      return constants.getCopy(key);
    }
  }
};
</script>
