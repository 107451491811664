<template>
    <div class="section-footer">
        <div class="social-media">
            <p><a href="https://www.linkedin.com/company/quadram">Linkedin</a>
                | <a href="https://twitter.com/quadramqs">Twitter</a>
                | <a href="https://www.instagram.com/quadramqs/">Instagram</a></p>
        </div>
        <div v-if="phone" class="phone">
            {{phone}}
        </div>
        <a v-if="mail" class="mail" :href="'mailto:' + mail">
            {{mail}}
        </a>
        <div class="copyright">
            <p v-if="privacy">
                <router-link tag="a" to="/privacy">Términos y privacidad</router-link>
                |
            </p>
            <p>QUADRAM© 2019</p>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            phone: String,
            mail: String,
          privacy: Boolean,
        },
    };
</script>

