let allWorks = {
    'dhis2': {
        0: {
            name: 'DHIS2-software-informacion-medica.webp',
            alt: 'DHIS2 un software monitorea gestiona y evalúa información médica',
            title: 'DHIS2 software información medica'
        },
        1: {
            name: 'DHIS2-funcionalidades-offline.webp',
            alt: 'DHIS2 uso funcionalidades baja conectividad',
            title: 'DHIS2 funcionalidades offline'
        },
        2: {
            name: 'app-profesionales-salud.webp',
            alt: 'App almacena información profesionales salud - DHIS2',
            title: 'App profesionales salud'
        },
        3: {
            name: 'dhis2-medicos.webp',
            alt: 'Médicos App Android - DHIS2',
            title: 'Médicos DHIS2'
        }
    },
    'safy': {
        0: {
            name: 'app-protegete-movil.webp',
            alt: 'App Safy protégete con tu movil seguridad personal',
            title: 'App Safy protégete con tu movil'
        },
        1: {
            name: 'app-alertas-seguridad-movil.webp',
            alt: 'App alertas seguridad en tu movil - SAFY',
            title: 'App alertas seguridad movil'
        },
        2: {
            name: 'app-funcionalidades-seguridad-movil.webp',
            alt: 'App alertas seguridad personal movil',
            title: 'App alertas seguridad personal movil'
        }
    },
    'direct-seguros': {
        0: {
            name: 'app-direct-seguros-coche.webp',
            alt: 'Aplicación Android iOS - DIRECT SEGUROS',
            title: 'App seguro coche Direct Seguros'
        },
        1: {
            name: 'app-direct-seguros-grua.webp',
            alt: 'Aplicación asistencia grua - DIRECT SEGUROS',
            title: 'App asistencia grua Direct Seguros'
        },
        2: {
            name: 'aplicacion-direct-seguros-coche.webp',
            alt: 'Aplicación calcula seguro coche moto - DIRECT SEGUROS',
            title: 'App calcula seguro Direct Seguros'
        },
        3: {
            name: 'app-direct-seguros-logos.webp',
            alt: 'Aplicacion protege tu coche - DIRECT SEGUROS',
            title: 'App protege tu coche Direct Srguros'
        }
    },
    'trustyou': {
        0: {
            name: 'aplicacion-TrustYou.webp',
            alt: 'App opinión clientes industria hotelera - TRUSTYOU',
            title: 'Aplicación TrustYou',
        },
        1: {
            name: 'hotel-spa.webp',
            alt: 'Empleado Spa',
            title: 'Trabajador Spa'
        },
        2: {
            name: 'encuestas-hoteles-trustyou.webp',
            alt: 'Reseñas hoteles - TRUSTYOU',
            title: 'Buscador hoteles TrustYou'
        },
        3: {
            name: 'resenas-hoteles-trustyou.webp',
            alt: 'Opiniones clientes de hoteles - TRUSTYOU',
            title: 'App opiniones hoteles TrustYou'
        }
    },
    'trive': {
        0: {
            name: 'app-venta-coches.webp',
            alt: 'App venta coches online España - TRIVE',
            title: 'App venta coches'
        },
        1: {
            name: 'app-trive.webp',
            alt: 'App TRIVE venta coches online España - TRIVE',
            title: 'App TRIVE'
        },
        2: {
            name: 'app-trive-venta-coches.webp',
            alt: 'App TRIVE venta vehículos online España - TRIVE',
            title: 'App TRIVE e-commerce vehículos'
        },
        3: {
            name: 'app-trive-compra-coches.webp',
            alt: 'App venta vehículos online España - TRIVE',
            title: 'App TRIVE compra vehículos'
        }
    }
};

export default {
    allWorks
};