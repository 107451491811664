import Vue from "vue";
import VueMeta from "vue-meta";
import Router from "vue-router";
import Main from "./views/Main";
import Services from "./views/Services";
import Works from "./views/Works";
import WorkDetail from "./views/Works/WorkDetail";
import Team from "./views/Team";
import Contact from "./views/Contact";
import Privacy from "./views/Privacy";
import Cookies from "./views/Cookies";
import Clients from "./views/Clients";
import FooterService from "./views/FooterService";
import KitDigital from "./views/KitDigital";

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Quadram",
      component: Main,
      meta: {
        inMainMenu: true,
        menuOptionName: 'MENU_OPTION_HOME',
        customTitle: 'Empresa de desarrollo de aplicaciones móviles | Quadram',
        customDescription: 'META_DESCRIPTION_HOME',
        customOgTitle: 'OG_TITLE_HOME'
      }
    },
    {
      path: "/services",
      name: "Services",
      component: Services,
      meta: {
        inMainMenu: true,
        menuOptionName: 'MENU_OPTION_SERVICES',
        title: 'HOME_SERVICES',
        navColor: 'black',
        customTitle: 'Servicios de desarrollo de apps | Quadram',
        customDescription: 'META_DESCRIPTION_SERVICES',
        customOgTitle: 'OG_TITLE_SERVICES'
      }
    },
    {
      path: "/portfolio",
      name: "Works",
      component: Works,
      meta: {
        inMainMenu: false,
        menuOptionName: 'MENU_OPTION_WORKS',
        title: 'HOME_WORKS',
        navColor: 'black',
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO'
      }
    },
    {
      path: "/portfolio/dhis2",
      name: "DHIS2 Work Details",
      component: WorkDetail,
      meta: {
        title: 'HOME_WORK_DETAIL',
        navColor: 'black',
        navTransparent: true,
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO',
        isPortfolioDetail: true,
        qWork: 1
      }
    },
    {
      path: "/portfolio/safy",
      name: "DHIS2 Work Details",
      component: WorkDetail,
      meta: {
        title: 'HOME_WORK_DETAIL',
        navColor: 'black',
        navTransparent: true,
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO',
        isPortfolioDetail: true,
        qWork: 2
      }
    },
    {
      path: "/portfolio/direct-seguros",
      name: "DHIS2 Work Details",
      component: WorkDetail,
      meta: {
        title: 'HOME_WORK_DETAIL',
        navColor: 'black',
        navTransparent: true,
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO',
        isPortfolioDetail: true,
        qWork: 3
      }
    },
    {
      path: "/portfolio/trustyou",
      name: "DHIS2 Work Details",
      component: WorkDetail,
      meta: {
        title: 'HOME_WORK_DETAIL',
        navColor: 'black',
        navTransparent: true,
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO',
        isPortfolioDetail: true,
        qWork: 4
      }
    },
    {
      path: "/portfolio/trive",
      name: "DHIS2 Work Details",
      component: WorkDetail,
      meta: {
        title: 'HOME_WORK_DETAIL',
        navColor: 'black',
        navTransparent: true,
        customTitle: 'Desarrollo de apps móviles para empresas | Portfolio Quadram',
        customDescription: 'META_DESCRIPTION_PORTFOLIO',
        customOgTitle: 'OG_TITLE_PORTFOLIO',
        isPortfolioDetail: true,
        qWork: 5
      }
    },
    {
      path: "/clients",
      name: "mainClients",
      component: Clients,
      meta: {
        inMainMenu: true,
        menuOptionName: 'MENU_OPTION_CLIENTS',
        navColor: 'black',
        title: 'HOME_CLIENTS',
        customTitle: 'Clientes - Desarrollo de aplicaciones móviles | Quadram',
        customDescription: 'META_DESCRIPTION_CLIENTS',
        customOgTitle: 'OG_TITLE_CLIENTS'
      }
    },
    {
      path: "/team",
      name: "Team",
      component: Team,
      meta: {
        inMainMenu: true,
        menuOptionName: 'MENU_OPTION_TEAM',
        navColor: 'black',
        title: 'HOME_TEAM',
        customTitle: 'Equipo experto en Diseño y Desarrollo de aplicaciones móviles',
        customDescription: 'META_DESCRIPTION_TEAM',
        customOgTitle: 'OG_TITLE_TEAM'
      }
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
      meta: {
        inMainMenu: true,
        menuOptionName: 'MENU_OPTION_CONTACT',
        title: 'HOME_CONTACT',
        customTitle: 'Contacto | Pide presupuesto para tu app | Quadram',
        isOnlyContact: true,
        customDescription: 'META_DESCRIPTION_CONTACT',
        customOgTitle: 'OG_TITLE_CONTACT'
      }
    },
    {
      path: "/usabilidad-diseno-aplicaciones-moviles",
      name: "UxUi",
      component: FooterService,
      meta: {
        footerService: 1,
        title: "FOOTER_SERVICE_UXUI_TITLE",
        navColor: 'black',
        customTitle: 'Diseño para tus aplicaciones móviles - UX y UI | Quadram',
        customDescription: 'META_DESCRIPTION_USABILIDAD',
        customOgTitle: 'OG_TITLE_USABILIDAD'
      }
    },
    {
      path: "/apps-ios",
      name: "Apps iOS",
      component: FooterService,
      meta: {
        footerService: 2,
        title: "FOOTER_SERVICE_IOS_TITLE",
        navColor: 'black',
        customTitle: 'Desarrollo de apps nativas iOS - iPhone | Quadram',
        customDescription: 'META_DESCRIPTION_IOS',
        customOgTitle: 'OG_TITLE_IOS'
      }
    },
    {
      path: "/apps-android",
      name: "Apps Android",
      component: FooterService,
      meta: {
        footerService: 3,
        title: "FOOTER_SERVICE_ANDROID_TITLE",
        navColor: 'black',
        customTitle: 'Desarrollo de apps nativas Android | Quadram',
        customDescription: 'META_DESCRIPTION_ANDROID',
        customOgTitle: 'OG_TITLE_ANDROID'
      }
    },
    {
      path: "/apps-react-native",
      name: "Apps React Native",
      component: FooterService,
      meta: {
        footerService: 4,
        title: "FOOTER_SERVICE_REACT_NATIVE_TITLE",
        navColor: 'black',
        customTitle: 'Desarrollo de aplicaciones y web React Native | Quadram',
        customDescription: 'META_DESCRIPTION_REACT_NATIVE',
        customOgTitle: 'OG_TITLE_REACT_NATIVE'
      }
    },
    {
      path: "/equipo-expertos-team-augmentation",
      name: "Team Augmentation",
      component: FooterService,
      meta: {
        footerService: 5,
        title: "FOOTER_SERVICE_TEAM_AUGMENTATION_TITLE",
        navColor: 'black',
        customTitle: 'Desarrolladores en tu equipo de proyecto - Team Augmentation',
        customDescription: 'META_DESCRIPTION_TEAM_AUGMENTATION',
        customOgTitle: 'OG_TITLE_TEAM_AUGMENTATION'
      },
    },
    {
      path: "/web-responsive-pwa",
      name: "Backend",
      component: FooterService,
      meta: {
        footerService: 6,
        title: "FOOTER_SERVICE_BACKEND_TITLE",
        navColor: 'black',
        customTitle: 'Desarrollo frontend y backend | Quadram',
        customDescription: 'META_DESCRIPTION_WEB_RESPONSIVE',
        customOgTitle: 'OG_TITLE_WEB_RESPONSIVE'
      }
    },
    {
      path: "/sistema-diseno",
      name: "Design System",
      component: FooterService,
      meta: {
        footerService: 7,
        title: "FOOTER_SERVICE_DESIGN_SYSTEM_TITLE",
        navColor: 'black',
        customTitle: 'Creamos Sistemas de Diseño - UX, UI | Quadram',
        customDescription: 'META_DESCRIPTION_SISTEMA_DISENO',
        customOgTitle: 'OG_TITLE_SISTEMAS_DISENO'
      },
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy,
      meta: {
        title: 'HOME_PRIVACY',
        customTitle: 'Política de Privacidad | Quadram',
        customDescription: 'META_DESCRIPTION_PRIVACY',
        customOgTitle: 'OG_TITLE_PRIVACY'
      }
    },
    {
      path: "/cookies",
      name: "Cookies",
      component: Cookies,
      meta: {
        title: 'HOME_COOKIES',
        customTitle: 'Política de Cookies | Quadram',
        customDescription: 'META_DESCRIPTION_COOKIES',
        customOgTitle: 'OG_TITLE_COOKIES'
      }
    },
    {
      path: "/kit-digital",
      name: "Kit Digital",
      component: KitDigital,
      meta: {
        title: "KIT_DIGITAL_TITLE",
        navColor: 'black',
        customDescription: 'META_DESCRIPTION_HOME',
        customOgTitle: 'OG_TITLE_HOME'
      }
    },
    {
      path: "/index.html",
      redirect: "/",
    },
    {
      path: "/services/index.html",
      redirect: "/services"
    },
    {
      path: "/portfolio/index.html",
      redirect: "/portfolio"
    },
    {
      path: "/portfolio/detail",
      redirect: "/portfolio"
    },
    {
      path: "/portfolio/detail/index.html",
      redirect: "/portfolio"
    },
    {
      path: "/clients/index.html",
      redirect: "/clients"
    },
    {
      path: "/team/index.html",
      redirect: "/team"
    },
    {
      path: "/contact/index.html",
      redirect: "/contact"
    },
    {
      path: "/privacy/index.html",
      redirect: "/privacy"
    },
    {
      path: "/cookies/index.html",
      redirect: "/cookies"
    },
    {
      path: "/usabilidad-diseno-aplicaciones-moviles/index.html",
      redirect: "/usabilidad-diseno-aplicaciones-moviles"
    },
    {
      path: "/apps-ios/index.html",
      redirect: "/apps-ios"
    },
    {
      path: "/apps-android/index.html",
      redirect: "/apps-android"
    },
    {
      path: "/apps-react-native/index.html",
      redirect: "/apps-react-native"
    },
    {
      path: "/equipo-expertos-team-augmentation/index.html",
      redirect: "/equipo-expertos-team-augmentation"
    },
    {
      path: "/web-responsive-pwa/index.html",
      redirect: "/web-responsive-pwa"
    },
    {
      path: "/sistema-diseno/index.html",
      redirect: "/sistema-diseno"
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.customTitle;
  if (to.meta.isPortfolioDetail) {
    localStorage.setItem('qWork', to.meta.qWork)
  }
  next();
});

export default router;
