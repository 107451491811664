<template>
  <div>
    <div id="privacy">
      <div class="wrapper">
        <div
          class="privacy-title"
          v-html="CONSTANTS.getCopy(privacyTitle)"
        ></div>
        <div class="privacy-text" v-html="CONSTANTS.getCopy(privacyText)"></div>
      </div>
    </div>
    <quadram-footer />
  </div>
</template>
<script>
import QuadramFooter from "./Home/Footer";

export default {
  components: { QuadramFooter },
  name: "Privacy",
  metaInfo() {
    return {
      link: [
        { rel: 'canonical', href: 'https://quadram.mobi/privacy' }
      ]
    }
  },
  data() {
    return {
      privacyTitle: "PRIVACY_TITLE",
      privacyText: "PRIVACY_CONTENT",
    };
  },
};
</script>
