let allClients = [
    {
      id: 0,
      name: '20th Century Fox',
      alt: '20th Century Fox',
      logo: '20th-Century-Fox.webp',
      url:'#',
    },
    {
      id: 1,
      name: 'Acciona',
      alt: 'Acciona',
      logo: 'acciona.webp',
      url:'#',
    },
    {
      id: 2,
      name: 'Aemps',
      alt: 'Aemps',
      logo: 'aemps.webp',
      url:'#',
    },
    {
      id: 3,
      name: 'Ahumados Dominguez',
      alt: 'Ahumados Dominguez App',
      logo: 'ahumados_dominguez.webp',
      url:'#',
    },
    {
      id: 4,
      name: 'Amv',
      alt: 'Amv',
      logo: 'Amv.webp',
      url:'#',
    },
    {
      id: 5,
      name: 'AS',
      alt: 'AS App',
      logo: 'as.webp',
      url:'#',
    },
    {
      id: 6,
      name: 'Ayuntamiento Madrid',
      alt: 'Ayuntamiento de Madrid',
      logo: 'ayuntamientomadrid.webp',
      url:'#',
    },
    {
      id: 7,
      name: 'b4 Motion',
      alt: 'b4 Motion',
      logo: 'b4motion.webp',
      url:'#',
    },
    {
      id: 8,
      name: 'Bankia',
      alt: 'Bankia',
      logo: 'bankia.webp',
      url:'#',
    },
    {
      id: 9,
      name: 'BBVA',
      alt: 'Banco BBVA App',
      logo: 'BBVA.webp',
      url:'#',
    },
    {
      id: 10,
      name: 'Berge Auto',
      alt: 'Berge Auto App',
      logo: 'berge-auto.webp',
      url:'#',
    },
    {
      id: 11,
      name: 'Cheil',
      alt: 'Cheil',
      logo: 'cheil.webp',
      url:'#',
    },
    {
      id: 12,
      name: 'Cinco Días',
      alt: 'Cinco Días',
      logo: 'cinco-dias.webp',
      url:'#',
    },
    {
      id: 13,
      name: 'CNMV',
      alt: 'CNMV App',
      logo: 'cnmv.webp',
      url:'#',
    },
    {
      id: 14,
      name: 'Comillas',
      alt: 'Comillas',
      logo: 'comillas.webp',
      url:'#',
    },
    {
      id: 15,
      name: 'Credit Suisse',
      alt: 'Credit Suisse App',
      logo: 'creditsuisse.webp',
      url:'#',
    },
    {
      id: 16,
      name: 'Cruzcampo',
      alt: 'Cruzcampo',
      logo: 'cruzcampo.webp',
      url:'#',
    },
    {
      id: 17,
      name: 'DGT',
      alt: 'DGt App',
      logo: 'dgt.webp',
      url:'#',
    },
    {
      id: 18,
      name: 'DHIS',
      alt: 'DHIS 2 App',
      logo: 'dhis.webp',
      url:'#',
    },
    {
      id: 19,
      name: 'Direct Seguros',
      alt: 'Direct Seguros App',
      logo: 'direct-seguros.webp',
      url:'#',
    },
    {
      id: 20,
      name: 'Dragados',
      alt: 'Dragados',
      logo: 'dragados.webp',
      url:'#',
    },
    {
      id: 21,
      name: 'eBroker',
      alt: 'eBroker',
      logo: 'ebroker.webp',
      url:'#',
    },
    {
      id: 22,
      name: 'El Economista',
      alt: 'El Economista App',
      logo: 'el-economista.webp',
      url:'#',
    },
    {
      id: 23,
      name: 'El Independiente',
      alt: 'El Independiente App',
      logo: 'elindependiente.webp',
      url:'#',
    },
    {
      id: 24,
      name: 'El Mundo',
      alt: 'El Mundo',
      logo: 'elmundo.webp',
      url:'#',
    },
    {
      id: 25,
      name: 'El País',
      alt: 'El País App',
      logo: 'el-pais.webp',
      url:'#',
    },
    {
      id: 26,
      name: 'El Periodico',
      alt: 'El Periodico',
      logo: 'elperiodico.webp',
      url:'#',
    },
    {
      id: 27,
      name: 'Encantado de Comerte',
      alt: 'Encantado de Comerte App',
      logo: 'encantadodecomerte.webp',
      url:'#',
    },
    {
      id: 28,
      name: 'ePark',
      alt: 'E-park App',
      logo: 'epark.webp',
      url:'#',
    },
    {
      id: 29,
      name: 'ESIC',
      alt: 'ESIC',
      logo: 'ESIC.webp',
      url:'#',
    },
    {
      id: 30,
      name: 'eurest',
      alt: 'eurest',
      logo: 'eurest.webp',
      url:'#',
    },
    {
      id: 31,
      name: 'euro6000',
      alt: 'euro6000',
      logo: 'euro6000.webp',
      url:'#',
    },
    {
      id: 32,
      name: 'Genetracer Biotech',
      alt: 'Genetracer Biotech',
      logo: 'genetracer-biotech.webp',
      url:'#',
    },
    {
      id: 33,
      name: 'HP',
      alt: 'HP',
      logo: 'HP.webp',
      url:'#',
    },
    {
      id: 34,
      name: 'Icemd',
      alt: 'ICEMD App',
      logo: 'icemd.webp',
      url:'#',
    },
    {
      id: 35,
      name: 'Janssen',
      alt: 'Janssen',
      logo: 'Janssen.webp',
      url:'#',
    },
    {
      id: 36,
      name: 'John Deere',
      alt: 'John Deere',
      logo: 'john_deere.webp',
      url:'#',
    },
    {
      id: 37,
      name: 'KIA',
      alt: 'KIA',
      logo: 'kia.webp',
      url:'#',
    },
    {
      id: 38,
      name: 'La Despensa',
      alt: 'La Despensa',
      logo: 'La-despensa.webp',
      url:'#',
    },
    {
      id: 39,
      name: 'LG',
      alt: 'LG',
      logo: 'LG.webp',
      url:'#',
    },
    {
      id: 40,
      name: 'Linea Directa',
      alt: 'Linea Directa',
      logo: 'linea-directa.webp',
      url:'#',
    },
    {
      id: 41,
      name: 'Mahou San Miguel',
      alt: 'Mahou San Miguel App',
      logo: 'mahou-san-miguel.webp',
      url:'#',
    },
    {
      id: 42,
      name: 'McDonald\'s',
      alt: 'McDonalds',
      logo: 'McDonalds.webp',
      url:'#',
    },
    {
      id: 43,
      name: 'Mediaset',
      alt: 'Mediaset',
      logo: 'Mediaset.webp',
      url:'#',
    },
    {
      id: 44,
      name: 'Mensajeros de la Paz',
      alt: 'Mensajeros de la Paz',
      logo: 'mensajerosdelapaz.webp',
      url:'#',
    },
    {
      id: 45,
      name: 'Mini',
      alt: 'Mini',
      logo: 'mini.webp',
      url:'#',
    },
    {
      id: 46,
      name: 'Mutua Madrid Open',
      alt: 'Mutua Madrid Open App',
      logo: 'mutua-madrid-open.webp',
      url:'#',
    },
    {
      id: 47,
      name: 'Mutua',
      alt: 'Mutua',
      logo: 'mutua.webp',
      url:'#',
    },
    {
      id: 48,
      name: 'NCS',
      alt: 'NCS',
      logo: 'ncs.webp',
      url:'#',
    },
    {
      id: 49,
      name: 'Neoris',
      alt: 'Neoris',
      logo: 'neoris.webp',
      url:'#',
    },
    {
      id: 50,
      name: 'Ogilivy',
      alt: 'Ogilivy',
      logo: 'ogilivy.webp',
      url:'#',
    },
    {
      id: 51,
      name: 'Ono',
      alt: 'Ono',
      logo: 'ono.webp',
      url:'#',
    },
    {
      id: 52,
      name: 'Proximity',
      alt: 'Proximity',
      logo: 'proximity.webp',
      url:'#',
    },
    {
      id: 53,
      name: 'PSI',
      alt: 'PSI',
      logo: 'psi.webp',
      url:'#',
    },
    {
      id: 54,
      name: 'SAFY',
      alt: 'SAFY App',
      logo: 'safy.webp',
      url:'#',
    },
    {
      id: 55,
      name: 'Sanitas',
      alt: 'Sanitas',
      logo: 'sanitas.webp',
      url:'#',
    },
    {
      id: 56,
      name: 'Santalucia',
      alt: 'Santalucia',
      logo: 'santalucia.webp',
      url:'#',
    },
    {
      id: 57,
      name: 'Santander',
      alt: 'Banco Santander App',
      logo: 'santander.webp',
      url:'#',
    },
    {
      id: 58,
      name: 'Shell',
      alt: 'Shell',
      logo: 'Shell.webp',
      url:'#',
    },
    {
      id: 59,
      name: 'Signus',
      alt: 'Signus',
      logo: 'Signus.webp',
      url:'#',
    },
    {
      id: 60,
      name: 'Social Drive',
      alt: 'Social Drive App',
      logo: 'social-drive.webp',
      url:'#',
    },
    {
      id: 61,
      name: 'SONY',
      alt: 'SONY',
      logo: 'sony.webp',
      url:'#',
    },
    {
      id: 62,
      name: 'Sport 360',
      alt: 'Sport 360 App',
      logo: 'sport-360.webp',
      url:'#',
    },
    {
      id: 63,
      name: 'Sport',
      alt: 'Sport',
      logo: 'sport.webp',
      url:'#',
    },
    {
      id: 64,
      name: 'Telecoming',
      alt: 'Telecoming',
      logo: 'Telecoming.webp',
      url:'#',
    },
    {
      id: 65,
      name: 'Trust You',
      alt: 'Trust You',
      logo: 'trustyou.webp',
      url:'#',
    },
    {
      id: 66,
      name: 'UEM',
      alt: 'UEM',
      logo: 'UEM.webp',
      url:'#',
    },
    {
      id: 67,
      name: 'Unidad Editorial',
      alt: 'Unidad Editorial',
      logo: 'unidadeditorial.webp',
      url:'#',
    },
    {
      id: 68,
      name: 'Unir',
      alt: 'Unir',
      logo: 'unir.webp',
      url:'#',
    },
    {
      id: 69,
      name: 'University Of Oslo',
      alt: 'University of Oslo',
      logo: 'universityofoslo.webp',
      url:'#',
    },
    {
      id: 70,
      name: 'Viiv',
      alt: 'Viiv',
      logo: 'viiv.webp',
      url:'#',
    },
    {
      id: 71,
      name: 'Web Financial Group',
      alt: 'Web Financial Group App',
      logo: 'web-financial-group.webp',
      url:'#',
    },
    {
      id: 72,
      name: 'Wilkinson',
      alt: 'Wilkinson',
      logo: 'wilkinson.webp',
      url:'#',
    }
  ]

  export default{
      allClients
  }