<template>
    <div id="menu" :key="show" :class="show && 'is-active'">
        <div class="page-list">
            <div v-for="(option, index) in routes"
                 :key="index"
                 class="page"
                 :class="index === 1 && 'is-first'"
                 @click="closeMenu()"
            >
                <router-link
                        tag="a"
                        :to="option.path"
                        :class="isCurrentRoute(option) && 'is-active'"
                >
                    {{CONSTANTS.getCopy(option.meta.menuOptionName)}}
                </router-link>
            </div>
        </div>
        <div class="close-icon" @click="closeMenu">
        <span class="icon is-medium">
            <img src="@/assets/images/menu/is_close.png" alt="Cerrar menu" width="30px"/>
        </span>
        </div>
    </div>
</template>
<script>
    import constants from '../../constants';

    export default {
        name: "Menu",
        props: {
          show: Boolean,
        },

        data() {
            return {
              sections: [],
              routes: null,
            }
        },

        created() {
            this.sections = constants.hooks;
            this.fillMenu();
        },

        methods: {
            closeMenu() {
              this.$emit('menuClicked');
            },
            fillMenu() {
              this.routes = this.$router.options.routes.filter((route) => route.meta && route.meta.inMainMenu);
            },
            isCurrentRoute(route) {
                return this.$router.currentRoute.path === route.path;
            },
            goToPage(page) {
              this.$router.push(page);
              this.$emit('menuClicked');
            },
        }
    };
</script>
