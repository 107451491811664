<template>
    <div class="team-augmentation">
        <div class="white-box team-augmentation-info">
            <h2 class="title">{{ translateCopy(`FOOTER_SERVICE_${footerService}_TITLE_CONTENT`) }}</h2>
            <SectionNamer :custom-text="translateCopy('FOOTER_SERVICE_5_INFO_SECTION')"/>
            <span v-html="translateCopy(`FOOTER_SERVICE_${footerService}_CONTENT`)"></span>
        </div>
        <div class="team-augmentation-clients">
            <SectionNamer :custom-text="translateCopy('FOOTER_SERVICE_5_CLIENTS_SECTION')" :isWhite="true"/>
            <div class="main-box is-first">
                <div class="text-box">
                    <div class="opinion">
                        <label class="open-quotes">“</label>
                        <div class="content">
                            <label>{{ translateCopy('FOOTER_SERVICE_5_OPINION_1') }}</label>
                            <label class="close-quotes">”</label>
                        </div>
                    </div>
                    <div class="logo-with-user">
                        <img :src="require(`@/assets/images/footerServices/logo_ty.webp`)" alt="TrustYou Logo"/>
                        <div class="user">
                            <span>{{ translateCopy('FOOTER_SERVICE_5_CLIENT_NAME_1') }}</span>
                            <span>{{ translateCopy('FOOTER_SERVICE_5_CLIENT_COMPANY_1') }}</span>
                        </div>
                    </div>
                </div>
                <div class="shadow-box"></div>
            </div>
            <div class="main-box is-second">
                <div class="text-box">
                    <div class="opinion">
                        <label class="open-quotes">“</label>
                         <div class="content">
                            <label>{{ translateCopy('FOOTER_SERVICE_5_OPINION_2') }}</label>
                            <label class="close-quotes">”</label>
                        </div>
                    </div>
                    <div class="logo-with-user">
                        <div class="user">
                            <span>{{ translateCopy('FOOTER_SERVICE_5_CLIENT_NAME_2') }}</span>
                            <span>{{ translateCopy('FOOTER_SERVICE_5_CLIENT_COMPANY_2') }}</span>
                        </div>
                        <img :src="require(`@/assets/images/footerServices/logo_safy.webp`)" alt="Safy Logo"/>
                    </div>
                </div>
                <div class="shadow-box"></div>
            </div>
        </div>
        <div class="white-box team-augmentation-advantages">
            <SectionNamer :custom-text="translateCopy('FOOTER_SERVICE_5_ADVANTAGES_SECTION')"/>
            <h2 class="title">{{ translateCopy('FOOTER_SERVICE_5_ADVANTAGES_TITLE') }}</h2>
            <div class="advantages-section">
                <div class="advantage-container" v-for="i in advantagesCount">
                    <h3 class="title">{{ translateCopy(`FOOTER_SERVICE_5_ADVANTAGE_TITLE_${i}`) }}</h3>
                    <p class="content">{{ translateCopy(`FOOTER_SERVICE_5_ADVANTAGE_CONTENT_${i}`) }}</p>
                </div>
            </div>
        </div>
        <div class="team-augmentation-work">
            <SectionNamer :custom-text="translateCopy('FOOTER_SERVICE_5_WORK_SECTION')" :isWhite="true"/>
            <div v-for="i in workCount">
                <h3 class="title">{{ translateCopy(`FOOTER_SERVICE_5_WORK_TITLE_${i}`) }}</h3>
                <p class="content" v-html="translateCopy(`FOOTER_SERVICE_5_WORK_CONTENT_${i}`)"></p>
            </div>
        </div>
        <div class="white-box team-augmentation-faqs">
            <SectionNamer :custom-text="translateCopy('FOOTER_SERVICE_5_FAQS_SECTION')"/>
            <h2 class="title">{{ translateCopy('FOOTER_SERVICE_5_FAQS_TITLE') }}</h2>
            <faq
                :is-white=true
                :list-count="faqCount"
                :list-prefix="faqSectionName"
            />
        </div>
    </div>
</template>

<script>

import constants from "@/constants";
import SectionNamer from "../../components/navigation/SectionNamer";
import Faq from "../../components/Faq";

export default {
    components: { SectionNamer, Faq },
    props: {
        footerService: Number
    },
    data() {
        return {
            advantagesCount: 4,
            workCount: 3,
            faqCount: 5,
            faqSectionName: 'TEAM_AUGMENTATION_FAQ',
        }
    },
    methods: {
        translateCopy(key) {
            return constants.getCopy(key);
        }
    }
}

</script>