<template>
  <div id="images-grid">
    <img v-for="(image, index) in images"
         :src="image.src"
         :alt="image.name"
         :title="image.title"
         :class="`image-grid-${image.id} ${(index === images.length - 1) && notOverlapped && 'not-overlapped'}`"
    >
  </div>
</template>

<script>
// @ is an alias to /src

import constants from '@/constants';

export default {
  components: {},
  name: "ImagesGrid",
  props: {
    images: Array,
    notOverlapped: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    },
  }
};
</script>
