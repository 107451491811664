<template>
    <div id="desktop-menu" :key="show">
        <div v-for="(option, index) in routes" :key="index" class="page-link">
            <router-link tag="a" :to="option">
                {{ translateCopy(index) }}
            </router-link>
        </div>
        <div class="budget">
            <router-link tag="a" to="/contact">
                {{ translateCopy("MENU_OPTION_BUDGET") }}
            </router-link>
        </div>
    </div>
</template>

<script>
import constants from "@/constants";
export default {
    name: "Desktop Menu",
    props: {
        show: Boolean,
    },
    data() {
        return {
            routes: {
                "MENU_OPTION_SERVICES": "/services",
                "HOME_FOOTER_TEAM_AUGMENTATION": "/equipo-expertos-team-augmentation",
                "MENU_OPTION_CLIENTS": '/clients',
                "MENU_OPTION_TEAM": "/team",
            },
        }
    },
    methods: {
        translateCopy(key) {
            return constants.getCopy(key);
        }
    }
}
</script>