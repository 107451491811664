import Vue from "vue";
import App from "./App.vue";
import VueCarousel from 'vue-carousel';

// CSS
// import './../node_modules/bulma/css/bulma.css';
// import './../node_modules/@fortawesome/fontawesome-free/css/all.css';


// Vue Router
import Router from "vue-router";
import router from "./router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(() => {
    const locationParts = location.split('#');
    document.getElementById(locationParts.length > 1 ? locationParts[1] : 'app').scrollIntoView();
  });
};

// Global Vue and events
window.Vue = require('vue');
window.Event = new Vue();
Vue.config.productionTip = false;

//Mixins/utilities
import constants from './constants';
import copies from './copies';

// Use mixins and components
Vue.use(Router);
Vue.use(VueCarousel);

// Vue components
Vue.component('NavBar', require('./components/navigation/NavBar').default);
Vue.component('Menu', require('./components/navigation/Menu').default);
Vue.component('SectionCounter', require('./components/navigation/SectionCounter').default);
Vue.component('SectionFooter', require('./components/navigation/SectionFooter').default);
Vue.component('clock', require('./components/elements/Clock').default);

// Vue Prototype override
Vue.prototype.CONSTANTS = constants;
Vue.prototype.COPIES = copies;

//Local storage
Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  let value = this.getItem(key);
  return value && JSON.parse(value);
};

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
